
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../app-context';
import './my-story.styles.scss';
function MyStory(props) {

  const storyId = props.match.params.storyId;
  const kidName = props.match.params.kidName;
  const friendName = props.match.params.friendName;
  const [currentPage, setCurrentPage] = useState(0);
  const [pageData, setPageData] = useState(null);
  const [showNext, setShowNext] = useState(true);
  const [showPrevious, setShowPrevious] = useState(false);
  const history = useHistory();

  const appContext = useContext(AppContext);
  const storyData = {
    id: '1',
    title: 'Best Friends',
    pages: [
      {
        story: "kidnamehere and friendnamehere were the best friends in school. They were also known as the karate buddies as they were the champions of the school karate team. Not only they were brave but very intelligent also. One day they decided to go for camping in a jungle.",
        topImage: null,
        bottomImage: '/assets/images/homepage/newhome/newdesign/page1_top.png',
        backgroundImage: 'https://img.freepik.com/premium-vector/living-room-with-red-sofa-bookcase-torchere-vase-plant-paintings-window-flat-illustration_175690-3.jpg'
      },
      {
        story: "Next day early morning they both got ready for the camping and headed towards the jungle. kidnamehere packed her magnetic compass and swiss knife also along with other food items. They entered the jungle just before the sunrise. The could hear the chirping of the birds which made them very excited.",
        topImage: null,
        bottomImage: '/assets/images/homepage/newhome/newdesign/page2_bottom.png',
        backgroundImage: 'https://img.freepik.com/free-vector/forest-scene-with-dirt-road-middle_1308-36446.jpg'
      }
    ]
  }

  useEffect(() => {
    appContext.setShowFooter(false);
    setCurrentPage(0);
    return () => {
      appContext.setShowFooter(true);
    }
  }, []);

  useEffect(() => {
    let tempData = storyData['pages'][currentPage];
    tempData.story = tempData.story.replace("kidnamehere", kidName);
    tempData.story = tempData.story.replace("friendnamehere", friendName);
    setPageData(storyData['pages'][currentPage]);
  }, [currentPage]);

  const nextPage = () => {
    let newPage = currentPage + 1;
    if (newPage !== storyData.pages.length - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage + 1);
      setShowNext(false)
      setShowPrevious(true);
    }
  }

  const previousPage = () => {
    let newPage = currentPage - 1
    if (newPage !== 0) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(currentPage - 1);
      setShowNext(true)
      setShowPrevious(false);
    }
  }

  const myStoryPage = () => {
    return <div className="my-story-page">
      {pageData.backgroundImage !== null ?
        <div className="my-story-background">
          <img src={pageData.backgroundImage} alt="" />
        </div> : null}
      {pageData.topImage !== null ?
        <div className="my-story-top-image">
          <img src={pageData.topImage} alt="" />
        </div> : null}
      <div className="my-story-content">
        {pageData.story}
      </div>
      {pageData.bottomImage !== null ?
        <div className="my-story-bottom-image">
          <img src={pageData.bottomImage} alt="" />
        </div> : null}
      <div className="my-story-controls">
        <div className="my-story-exit" onClick={() => { history.push('/mystorylisting'); }}>
          <img src="/assets/images/homepage/newhome/newdesign/exit.png" alt="" />
        </div>
        <div className="my-story-page-controls">
          {showPrevious ?
            <div className="my-story-page-button" onClick={previousPage}>
              <img src="/assets/images/homepage/newhome/newdesign/previous.png" alt="" />
            </div> : null}
          {showNext ? <div className="my-story-page-button" onClick={nextPage}>
            <img src="/assets/images/homepage/newhome/newdesign/next.png" alt="" />
          </div> : null}
        </div>
      </div>
    </div>
  }

  return (
    <>
      {pageData != null && pageData.story != null && pageData.story != '' ?  
      myStoryPage() 
      :
        <div>Loading...</div>}
    </>
  );
}

export default MyStory;