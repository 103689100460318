import { PAGE_SIZE } from "../components/constants/constants";
import { get, post } from "./http-services";

export function getLoggerTransactions(pageNumber) {
    return get(`/logger/transactions?page=${pageNumber}&size=${PAGE_SIZE}&sort=date,desc`);
}

export function getLoggerExceptions(pageNumber) {
    return get(`/logger/exceptiondetails?page=${pageNumber}&size=${PAGE_SIZE}&sort=date,desc`);
}

export function getLoggerTrend(pageNumber) {
    return get(`/logger/trend?page=${pageNumber}&size=${PAGE_SIZE}&sort=date,desc`);
}

export function getStats(pageNumber) {
    return get(`/logger/stats?page=${pageNumber}&size=${PAGE_SIZE}`);
}

export function logService(service, parameters) {
    return post(`/logger/logservice?service=${service}&parameters=${parameters}`, null);
}

export function logExceptionService(service, parameters) {
    return post(`/logger/logexception?service=${service}&parameters=${parameters}`, null);
}

export function getLoggerTransactionsById(userId, pageNumber) {
    return get(`/logger/transactions/id?userId=${userId}&page=${pageNumber}&size=${PAGE_SIZE}&sort=date,desc`);
}
export function getLoggerExceptionsById(userId, pageNumber) {
    return get(`/logger/exceptiondetails/id?userId=${userId}&page=${pageNumber}&size=${PAGE_SIZE}&sort=date,desc`);
}