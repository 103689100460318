import React, { useContext, useEffect, useState } from 'react';
import { TopicCard } from '../../components/topic-card/topic-card.component';
import { Loader } from '../../components/loader/loader.component';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { getTopics } from '../../services/category-service';
import { AppContext } from '../../app-context';
import './topic-listing.styles.scss';

export const TopicListing = (props) => {

  const grade = props.match.params.grade;
  const subject = props.match.params.subject;
  const [topicList, setTopicList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (topicList.length === 0) {
      loadTopics();
    }
  }, []);

  const loadTopics = () => {
    getTopics(grade, subject, 0).subscribe({
      next: (response) => {
        setTopicList(response.data);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const onTopicSelect = (topic) => {
    if (appContext.userData) {
      if(topic.approvedCount !== 0){
        props.history.push(`/levels/${grade}/${subject}/${topic.name}`);
      }
    } else {
      appContext.setDialogMessage('Please login to proceed.');
      props.history.push('/signin');
    }
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        <div className="quiz-topic-list">
          <div className="quizListHeader">
            <div className="gradeName">Class {grade}</div>
            <div className="spacing"></div>
            <div className="subjectName">{subject}</div>
          </div>
          <div className="quizList">

            <Grid container spacing={5}>
              {
                topicList.map((topic, idx) => (
                  <Grid key={idx} item xs={6} sm={4} md={4} lg={2} xl={2}>
                    <div onClick={() => { onTopicSelect(topic) }}>
                      <TopicCard topic={topic} />
                    </div>
                  </Grid>
                ))
              }
            </Grid>
          </div>
        </div>
      }

    </div>
  );
}

export default withRouter(TopicListing);