import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSubjects } from '../../../../services/category-service';
import { SubjectCard } from '../../../subject-card/subject-card.component';
import './admin-subject.styles.scss';


export const AdminSubjects = (props) => {

  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    if (subjects.length === 0) {
      loadSubjects();
    }
  }, []);

  const loadSubjects = () => {
    getSubjects(props.grade, 1).subscribe({
      next: (response) => {
        setSubjects(response.data);
      }});
  }

  return <div className="admin-card-list">
    <Grid justify="center" container spacing={3}>
      {
        subjects.map(subject => (
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <Link to={"/admin/questionBank/" + props.grade + "/" + subject.name} style={{ textDecoration: 'none' }}>
              <SubjectCard key={subject.id} subject={subject} isAdmin={1} />
            </Link>
          </Grid>
        ))
      }
    </Grid>
  </div>

}

