import { get, post, whitelistRemove } from './http-services';


export function findAllSupport() {
    return get('/support/all');
}

export function saveSupport(support) {
    return post('/support', support);
}

export function deleteSupport(id) {
    return whitelistRemove('/support?id=' + id);
}