import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import avatarList from './avatar-list';

import './avatar-selection.styles.scss';

const avatarImages = avatarList;

const handleClose = (onClose) => {
  onClose();
}
export const AvatarSelection = props => (
  <Dialog {...props}>
    <div className="avatarDialogContainer">
      <div className="closeButton">
        <CloseOutlinedIcon onClick={() => { handleClose(props.onClose) }} />
      </div>
      <div className="gridList">
        {avatarImages.map((tile) => (
          <div className="gridImage" onClick={() => { props.changeAvatar(tile.img); handleClose(props.onClose); }}>
            <img src={tile.img} alt={tile.title} />
          </div>
        ))}
      </div>
      <div className="avatar-cancel-button">
        <Button variant="contained" onClick={() => { handleClose(props.onClose) }} color="secondary">Cancel</Button>
      </div>
    </div>
  </Dialog>
);

export default AvatarSelection;