import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../app-context';
import { findAllGameForAdmin, saveGame } from '../../../services/game-service';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../services/grid-style';
import { ALL, EMPTY_STRING, MANDATORY_FIELD_MSG, STATUS_TYPES, SUBSCRIPTION_TYPES } from '../../constants/constants';
import { CLASS_TYPE, CLASS_TYPES, GAME_TYPE, GRADE_TYPE } from '../../games/game-constants';
import Loader from '../../loader/loader.component';
import './admin-game.styles.scss';

const AdminGame = () => {

  const initData = {
    name: EMPTY_STRING, tagLine: EMPTY_STRING, story: EMPTY_STRING, classType: EMPTY_STRING,  gameFolderName: EMPTY_STRING, type: EMPTY_STRING, gradeType: EMPTY_STRING, 
  };
  const [gameList, setGameList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [formData, updateFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const appContext = useContext(AppContext);

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    findAllGameForAdmin(ALL, selectedPage - 1).subscribe({
      next: (response) => {
        setGameList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }});
  }

  const savedata = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setShowLoader(true);
      saveGame(formData).subscribe({
        next: (response) => {
          search(page);
          updateFormData(initData);
          appContext.setSuccessMessage(response.message);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }});
    }
  }

  const editContest = (game) => {
    updateFormData(game);
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["name"]) {
      formIsValid = false;
      errors["name"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["tagLine"]) {
      formIsValid = false;
      errors["tagLine"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["story"]) {
      formIsValid = false;
      errors["story"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["classType"]) {
      formIsValid = false;
      errors["classType"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["type"]) {
      formIsValid = false;
      errors["type"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["gradeType"]) {
      formIsValid = false;
      errors["gradeType"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["gameFolderName"]) {
      formIsValid = false;
      errors["gameFolderName"] = MANDATORY_FIELD_MSG;
    }
    
    setFormError(errors);
    return formIsValid;
  }

  const renderGame = () => {
    return (
      <div className="contestFormContainer">
        <div className="contestTitle">Contest Details</div>
        <div className="contestForm">
          <form noValidate autoComplete="off" onSubmit={savedata}>
            <div className="formField">
              <TextField name="name" value={formData.name || EMPTY_STRING} onChange={handleChange} required fullWidth label="Game Name" variant="outlined" size="small" />
              <span className="error-text">{formError["name"]}</span>
            </div>
            <div className="formField">
              <TextField name="tagLine" value={formData.tagLine || EMPTY_STRING} label="Tag line" onChange={handleChange} fullWidth variant="outlined" size="small"/>
            </div>
            <div className="formField">
              <TextField name="story" value={formData.story || EMPTY_STRING} onChange={handleChange} multiline rows={3} required fullWidth label="Game Story" variant="outlined" size="small" />
              <span className="error-text">{formError["story"]}</span>
            </div>
            <div className="formField">
              <TextField name="gameFolderName" value={formData.gameFolderName || EMPTY_STRING} onChange={handleChange} required fullWidth label="Game Folder Name" variant="outlined" size="small" />
              <span className="error-text">{formError["gameFolderName"]}</span>
            </div>
            <div className="formField">
              <TextField name="classType" value={formData.classType || EMPTY_STRING} select onChange={handleChange} required fullWidth label="Class Type" variant="outlined" size="small">
              {CLASS_TYPES.map((classType) => (
                  <MenuItem key={classType} value={classType}>
                    {classType}
                  </MenuItem>
                ))}
                </TextField>
              <span className="error-text">{formError["classType"]}</span>
            </div>
            <div className="formField">
              <TextField name="type" value={formData.type || EMPTY_STRING} select onChange={handleChange} required fullWidth label="Game Type" variant="outlined" size="small" > 
              {GAME_TYPE.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
                </TextField>
              <span className="error-text">{formError["type"]}</span>
            </div>
            <div className="formField">
              <TextField name="gradeType" value={formData.gradeType || EMPTY_STRING} select onChange={handleChange} required fullWidth label="Grade Type" variant="outlined" size="small" > 
              {GRADE_TYPE.map((gradeType) => (
                  <MenuItem key={gradeType} value={gradeType}>
                    {gradeType}
                  </MenuItem>
                ))}
                </TextField>
              <span className="error-text">{formError["type"]}</span>
            </div>
            <div className="formField">
              <TextField name="isFree" value={formData.isFree || 0} select label="Subscription Type" onChange={handleChange} fullWidth variant="outlined" size="small">
                {SUBSCRIPTION_TYPES.map((subscription) => (
                <MenuItem key={subscription.value} value={subscription.value}>
                  {subscription.name}
                </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="formField">
              <TextField name="isActive" value={formData.isActive || 0} select label="Status" onChange={handleChange} fullWidth variant="outlined" size="small">
              {STATUS_TYPES.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.name}
                </MenuItem>
              ))}
              </TextField>
            </div>
            <div className="formField sendButton">
              {showLoader ? <div className="showLoading"><Loader /></div> : <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>}
            </div>
          </form>
        </div>
      </div>
    );
  }

  const getContestsGrid = () => {
    return (
      <div className="contestFormContainer">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Game Name</StyledTableCell>
                <StyledTableCell>Tag line</StyledTableCell>
                <StyledTableCell>Game Type</StyledTableCell>
                <StyledTableCell>Class Type</StyledTableCell>
                <StyledTableCell>Grade Type</StyledTableCell>
                <StyledTableCell>Subccription Type</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gameList.map((game) => (
                <StyledTableRow key={game.name}>
                  <StyledTableCell>{game.name}</StyledTableCell>
                  <StyledTableCell>{game.tagLine}</StyledTableCell>
                  <StyledTableCell>{game.type}</StyledTableCell>
                  <StyledTableCell>{game.classType}</StyledTableCell>
                  <StyledTableCell>{game.gradeType}</StyledTableCell>
                  <StyledTableCell>{game.isFree === 1 ? 'FREE' : 'PREMIUM'}</StyledTableCell>
                  <StyledTableCell>{game.isActive === 1 ? 'ACTIVE' : 'INACTIVE'}</StyledTableCell>
                  <StyledTableCell>
                    <Button onClick={() => { editContest(game) }} variant="contained" color="secondary">
                      Edit
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }

  return (
    <div className="admin-contest-details">
      {showLoader ? <div className="showLoading"><Loader /></div>
        : <>
          {renderGame()}
          {getContestsGrid()}
        </>
      }
    </div>
  );
}


export default AdminGame;


