import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import { default as React, useEffect, useState } from 'react';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../services/grid-style';
import { deleteRequestContest, findAllRequestContest } from '../../../services/request-contest-service';
import Loader from '../../loader/loader.component';
import './admin-request-contest.styles.scss';

const AdminRequestContest = () => {

  const [requestList, updateRequestList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const classes = useStyles();

  useEffect(() => {
    loadRequestContest();
  }, [page]);


  const loadRequestContest = () => {
    setShowLoader(true);
    findAllRequestContest(page - 1).subscribe({
      next: (response) => {
        updateRequestList(response.data.content);
        setPageCount(response.data.totalPages);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const deleteContest = (request) => {
    setShowLoader(true);
    deleteRequestContest(request.rowId).subscribe({
      next: (response) => {
        console.log(response.message);
        loadRequestContest();
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }


  const renderSupport = () => {
    return (
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>School Name</StyledTableCell>
                <StyledTableCell>Contact</StyledTableCell>
                <StyledTableCell>Email ID</StyledTableCell>
                <StyledTableCell>Phone</StyledTableCell>
                <StyledTableCell>City</StyledTableCell>
                <StyledTableCell>State</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList.map((request) => (
                <StyledTableRow key={request.schoolName}>
                  <StyledTableCell>{request.schoolName}</StyledTableCell>
                  <StyledTableCell>{request.requestorName}</StyledTableCell>
                  <StyledTableCell>{request.email}</StyledTableCell>
                  <StyledTableCell>{request.phone}</StyledTableCell>
                  <StyledTableCell>{request.city}</StyledTableCell>
                  <StyledTableCell>{request.state}</StyledTableCell>
                  <StyledTableCell>{moment(request.creationDate).format('DD-MM-YYYY hh:mm:ss A')}</StyledTableCell>
                  <StyledTableCell>
                    <Button onClick={() => { deleteContest(request) }} variant="contained" color="secondary">
                      Delete
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePageChange} />
      </div>
    );
  }

  const renderView = () => {
    return (
      <div className='admin-request-contest'>
        <div className="contest-request-title">Contest Request</div>
        <div className="requestContest">
          {renderSupport()}
        </div>
      </div>
    );
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
    </div>
  );
}

export default AdminRequestContest;