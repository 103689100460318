import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import AdComponent from '../../components/google-ads/google-ads.component';
import { getRedeemItems } from '../../services/redeem-service';
import './redemption-zone.styles.scss';
const RedemptionZonePage = () => {


  const [giftList, setGiftList] = useState(null);
  const [showAds, setShowAds] = useState(false);

  useEffect(() => {
    fetchGiftList();
  }, []);

  const fetchGiftList = () => {
    getRedeemItems(0).subscribe({
      next: (response) => {
        setGiftList(response.data);
      }});
  }

  return (
    <div className="redemption-zone-section">
      <div className="redemption-zone-title">PLAY - LEARN - REDEEM - REPEAT</div>
      {giftList !== null ?
        <div className="showcase-section">
          <Grid justify="center" container spacing={3} xs={12}>
            {giftList.map((giftItem, index) => {
              return (
                <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                  <div className="redeem-item">
                    <img src={giftItem.imagePath} alt="" />
                    <div>{giftItem.name}</div>
                    <div className="redeem-item-value">{giftItem.value} points</div>
                  </div>
                </Grid>
              )
            })}

          </Grid>
        </div>
        : null}


      {showAds ? <><AdComponent></AdComponent></> : null}
    </div>
  )

};

export default RedemptionZonePage;