import React from 'react';
import './topic-card.styles.scss';

export const TopicCard = (props) => {
  return (
    <div className='quiz-card-container'>
      {props.topic.isFree === 1 && props.topic.approvedCount > 0 ? null :
        <div className="premium-icon"><img src="/assets/images/homepage/newhome/icons/crown.png" alt="" /></div>
      }
      {props.topic.approvedCount === 0 ?
        <div className="quiz-card-coming-soon">Coming Soon</div>
        : null}
      <img src={props.topic.imagePath} alt="Topic" />
      <div className="quizTitle">{props.topic.name}</div>
      {1 === props.isAdmin && <p>{props.topic.approvedCount} Approved</p>}
      {1 === props.isAdmin && <p>{props.topic.pendingCount} Pending</p>}
    </div>
  );
}
