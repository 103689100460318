import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { getAllQuestions } from '../../../services/question-service';
import { Rif } from '../../../services/util-service';
import Loader from '../../loader/loader.component';
import './admin-question-bank.styles.scss';
import NewQuestionForm from './new-question-form.component';
import QuestionsList from './questions-list.component';

const AdminQuestionBank = (props) => {
  const grade = props.match.params.grade;
  const subject = props.match.params.subject;
  const topic = props.match.params.topic;
  const level = props.match.params.level;

  const params = { grade: grade, subject: subject, topic: topic, level: level }

  const [selectedQuestionBank, setSelectedQuestionBank] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questionCount, setQuestionCount] = useState(0);

  useEffect(() => {
    if (selectedQuestionBank === null) {
      loadQuestionBank();
    }
  }, []);


  const loadQuestionBank = () => {
    setShowLoader(true);
    getAllQuestions(grade, subject, topic, level).subscribe({
      next: (response) => {
        setSelectedQuestionBank(response.data);
        setSelectedQuestion(null);
        setShowLoader(false);
        setQuestionCount(response.data.length);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const editQuestion = (question) => {
    setSelectedQuestion(question)
  }

  const getBottomPanel = () => {
    return (
      <div>
        <div className="TitleSmall">
          <span className="gradeSubject">Class {grade} - {subject}</span>
          <span className="spacing"> - </span>
          <span className="quizTitle">{topic} (Level {level})</span>
        </div>

        <Rif boolean={selectedQuestionBank !== null}>
          <Grid justify="center" container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
              <NewQuestionForm selectedQuestion={selectedQuestion} questionNumber={questionCount + 1} params={params}
                onQuestionBankSelection={loadQuestionBank}></NewQuestionForm></Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <QuestionsList questions={selectedQuestionBank} editQuestion={editQuestion} questionCount={questionCount} params={params}
                onQuestionBankSelection={loadQuestionBank}></QuestionsList>

            </Grid>
          </Grid>
        </Rif>
      </div>
    );
  }

  return (
    <div className="questionBankContainer">
      {showLoader ? <div className="showLoading"><Loader /></div> :
        getBottomPanel()}
    </div>
  );
}

export default AdminQuestionBank;