import Switch from '@material-ui/core/Switch';
import React, { useEffect, useState } from "react";
import { removeSpaces } from '../../../../services/util-service';
import Timer from "../../timer/timer.component";
import './chicken-eggs.styles.scss';
const ChickenEggsComponent = (props) => {

  const gameName = ['c', 'h', 'i', 'c', 'k', 'e', 'n', 'e', 'g', 'g', 's'];
  const [wrongCount, setWrongCount] = useState(0);
  const [optionLetters, setOptionLetters] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']);
  const [answer, setAnswer] = useState(removeSpaces(props.questionDetails.options[0].text.toUpperCase().split('')));
  const [showAnswer, setShowAnswer] = useState(props.questionDetails.options[0].text.toUpperCase().split(''));
  const [selectedAlphabets, setSelectedAlphabets] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const [audioExplode, setAudioExplode] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/savetheearth/explode.mp3'));
  const [audioRight, setAudioRight] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/savetheearth/rightAnswer.mp3'));
  const [timeOver, setTimeOver] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);

  const resetGame = () => {
    let emptyArray = [];
    setShowQuestion(false);
    setCorrectAnswer(false);
    setWrongAnswer(false);
    setSelectedAlphabets([...emptyArray]);
    setAnswer(removeSpaces(props.questionDetails.options[0].text.toUpperCase().split('')));
    setShowAnswer(props.questionDetails.options[0].text.toUpperCase().split(''));
    setWrongCount(0);
    setTimeOver(false);
    setTimeout(() => {
      // setOptionsList(props.questionDetails.options);
      setShowQuestion(true);
    }, 500);
  }

  useEffect(() => {
    resetGame();
  }, [props.questionDetails]);



  const timeOut = () => {
    // setOptionsList(null);
    setShowQuestion(false);
    setTimeOver(true);
    if (props.music) {
      audioExplode.play();
    }
  }


  const playLevelAgain = () => {
    if (props.currentQuestion === 1) {
      resetGame();
    } else {
      props.playAgain();
    }
  }

  const selectAlphabet = (alphabet) => {
    let temp = selectedAlphabets;
    setSelectedAlphabets(null);
    temp.push(alphabet.alphabet);
    setSelectedAlphabets([...temp]);

    //verify if selected alphabet was correct or not
    if (answer.includes(alphabet.alphabet)) {
      // verify if answer found or not
      if (answer.every(val => temp.includes(val))) {
        setCorrectAnswer(true);
        props.correctAnswer();
      }
    } else {
      let wc = wrongCount;
      setWrongCount(wrongCount + 1);
      if (wc + 1 === 11) {
        setWrongAnswer(true);
      }
    }

  }

  const renderView = () => {
    return (
      <div className="chicken-eggs-game-box">
        <div className="chicken-eggs-game-background">
          <img src="https://www.cudents.com/prod/assets/images/games/chickeneggs/game_bg.webp" alt="" />
        </div>
          <>
            <div className="game-word-list">
              {gameName.map((egg, index) => (
                <>
                  {index === 7 ? <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> : null}
                  <div className="game-word">
                    {index <= wrongCount - 1 ? <img className="wrong-alphabet" src={"https://www.cudents.com/prod/assets/images/games/chickeneggs/eggs/" + index + "_1.webp"} alt="" /> : <img src={"https://www.cudents.com/prod/assets/images/games/chickeneggs/eggs/" + index + ".webp"} alt="" />}
                  </div>
                </>
              ))}
            </div>

            <div className="game-word-blanks">
              {!(wrongAnswer || timeOver) ?
                <>{showAnswer.map((ansAlphabet, index) => (
                  <>
                    {selectedAlphabets.includes(ansAlphabet) ? <div key={index + ansAlphabet} className="game-word-blank-letter">{ansAlphabet}</div> : (ansAlphabet == ' ') ? <div key={index}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> : <div key={index} className="game-word-blank-letter">&nbsp;</div>}
                  </>
                ))}</> : null}
            </div>

            {wrongAnswer ? <div className="answer-out"><img src="https://www.cudents.com/prod/assets/images/games/chickeneggs/wa.webp" alt="" /><div><div className="game-button" onClick={playLevelAgain}>Play Again</div></div></div> : null}
            {correctAnswer ? <div className="answer-out"><img className="answer-out" src="https://www.cudents.com/prod/assets/images/games/chickeneggs/ca.webp" alt="" /></div> : null}
            {timeOver ? <div className="answer-out"><img src="https://www.cudents.com/prod/assets/images/games/chickeneggs/timeover.webp" alt="" /><div><div className="game-button" onClick={playLevelAgain}>Play Again</div></div></div> : null}
            {props.nextLevelButton ? <div className="answer-out"><div className="game-button" onClick={props.playNextLevel}>Play Level {props.currentLevel + 1}</div></div> : null}
            {props.serverMessage ? <div className="bonus-message">{props.serverMessage}</div> : null}
            {showQuestion ?
              <div className="game-level-number">Level - {props.currentLevel}</div>
              : null}
            {showQuestion ?
              <div className="question-alphabets">
                {showQuestion && !(wrongAnswer || correctAnswer || timeOver) && answer.length > 0 ?
                  <>
                    <div className="game-timer-section">
                      <Timer timeOut={timeOut} initialMinute={5} initialSeconds={0}></Timer>
                    </div>
                    <div className="game-question-section">
                      <span className="game-question">{props.questionDetails.questionText}</span>
                    </div>
                    <div className="game-alphabets-options">
                      {optionLetters.map((alphabet) => (
                        <>
                          {selectedAlphabets.includes(alphabet) ? <div className="alphabet-selected">{alphabet}</div> : <div className="option-alphabet" onClick={() => { selectAlphabet({ alphabet }) }}>{alphabet}</div>}
                        </>
                      ))}
                    </div>
                  </> : null
                }
              </div> : null}

          </>
      </div>
    );
  }

  return (
    <>
      {renderView()}
    </>


  );
}

export default ChickenEggsComponent;