import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useHistory } from 'react-router-dom';
import { getGrades } from '../../../services/category-service';
import './quiz-section.styles.scss';

const QuizSection = (props) => {

  const responsiveQuizzes = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisibilityGutter: 40
    }
  };
  const history = useHistory();
  const [gradeList, setGradeList] = useState([]);


  useEffect(() => {
    if (gradeList) {
      loadGrade();
    }
  }, []);


  const loadGrade = () => {
    getGrades(0).subscribe({
      next: (response) => {
        setGradeList(response.data);
      }, error : (error ) => {
        console.log(error);
      }});
  }

  const getGradeImage = (grade) => {
    switch (grade) {
      case 1:
        return <img src="/assets/images/homepage/quiz/class1.png" alt="class 1 pic"></img>
      case 2:
        return <img src="/assets/images/homepage/quiz/class2.png" alt="class 2 pic"></img>
      case 3:
        return <img src="/assets/images/homepage/quiz/class3.png" alt="class 3 pic"></img>
      case 4:
        return <img src="/assets/images/homepage/quiz/class4.png" alt="class 4 pic"></img>
      case 5:
        return <img src="/assets/images/homepage/quiz/class5.png" alt="class 5 pic"></img>
      default:
        return <img src="/assets/images/homepage/quiz/class1.png" alt="class 1 pic"></img>
    }
  }
  
  // const renderGrade = () => {
  //   return <Grid container>
  //     {gradeList.map((grd) => (
  //       <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
  //         <div className="quiz-card">
  //           <div className="quiz-image">   <a href={process.env.REACT_APP_PLAYSTORE_URL}>
  //             {getGradeImage(grd.grade)}</a></div>
  //           <div className="quiz-name">Class {grd.grade}</div>
  //           <div className="quiz-tag"><div>{Math.floor((grd.quizCount * 15) / 100) * 100}+ </div> Questions</div>
  //         </div>
  //       </Grid>
  //     ))}
  //   </Grid>
  // }

  const renderGrade = () => {
    return <Carousel 
    swipeable={true}
    draggable={false}
    showDots={false}
    responsive={responsiveQuizzes}
    ssr={true} // means to render carousel on server-side.
    infinite={false}
    keyBoardControl={true}
    transitionDuration={500}
    renderButtonGroupOutside={false}
    removeArrowOnDeviceType={["tablet", "mobile"]}
    containerClass="carousel-container"
    dotListClass="custom-dot-list-style"
    centerMode={true}
    >
      {gradeList.map((grd) => (
        <div className="quiz-card">
          <div className="quiz-image">   <a href={process.env.REACT_APP_PLAYSTORE_URL}>
               {getGradeImage(grd.grade)}</a></div>
          <div className="quiz-name">Class {grd.grade}</div>
          <div className="quiz-tag"><div>{Math.floor((grd.quizCount * 15) / 100) * 100}+ </div> Questions</div>
        </div>
      ))}
    </Carousel>
  };

  return (
    <div className="quiz-listing">
      {renderGrade()}
    </div>
  );
}

export default QuizSection;