import { EMPTY_STRING, FREE_GAME_END_MSG, FREE_PKG, FREE_QUIZ_END_MSG, FREE_VIDEO_END_MSG, SUBSCRIPTION_END_MSG } from "../components/constants/constants";

export function validGameForUser(userData) {
    let message = EMPTY_STRING;
        if (userData.packageType === FREE_PKG) {
            message = FREE_GAME_END_MSG;
        } else if (userData.validDays < 1 ) {
            message = SUBSCRIPTION_END_MSG;
        }
    return message;
}

export function validUserForQuiz(userData) {
    let message = EMPTY_STRING;
        if (userData.packageType === FREE_PKG) {
            message = FREE_QUIZ_END_MSG;
        } else if (userData.validDays < 1 ) {
            message = SUBSCRIPTION_END_MSG;
        }
    return message;
}

export function validUserForVideo(userData) {
    let message = EMPTY_STRING;
        if (userData.packageType === FREE_PKG) {
            message = FREE_VIDEO_END_MSG;
        } else if (userData.validDays < 1 ) {
            message = SUBSCRIPTION_END_MSG;
        }
    return message;
}

