import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Lottie from "lottie-react";
import gifts from "./animations/gifts.json";
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EMPTY_STRING, MANDATORY_FIELD_MSG } from '../../components/constants/constants';
import RedeemCard from '../../components/dashboard/redeem-card/redeem-card.component';
import AdComponent from '../../components/google-ads/google-ads.component';
import Loader from '../../components/loader/loader.component';
import { getRedeemItems, redeemItem } from '../../services/redeem-service';
import { findByUser, saveUserProfile } from '../../services/user-service';
import './redeem.styles.scss';
import { AppContext } from '../../app-context';
import { useContext } from 'react';
const Redeem = () => {

  const history = useHistory();
  const [giftList, setGiftList] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [selectedGift, setSelectedGift] = useState(null);
  const [showGiftList, setShowGiftList] = useState(true);
  const [showRedeemOrder, setShowRedeemOrder] = useState(false);
  const [showOrderSuccess, setShowOrderSuccess] = useState(false);
  const [showOrderFailed, setShowOrderFailed] = useState(false);
  const [formError, setFormError] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, updateFormData] = useState({
    emailId: EMPTY_STRING, deliveryName: EMPTY_STRING, mobile: EMPTY_STRING, address: EMPTY_STRING
  });
  const [showAds, setShowAds] = useState(false);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (giftList === null) {
      fetchGiftList();
    }
  }, [])


  useEffect(() => {
    if (formData.emailId === EMPTY_STRING) {
      loadProfile();
    }
  }, []);

  const loadProfile = () => {
    findByUser().subscribe({
      next: (response) => {
        updateFormData(response.data);
        setShowLoader(false);
      }});

  }


  const fetchGiftList = () => {
    getRedeemItems(0).subscribe({
      next: (response) => {
        setGiftList(response.data);
        setShowLoader(false);
      }});
  }

  const selectRedeemGift = (giftDetails) => {
    setSelectedGift(giftDetails);
    setShowGiftList(false);
    setShowRedeemOrder(true);
  }

  const cancelRedeemGift = () => {
    setSelectedGift(null);
    setShowGiftList(true);
    setShowRedeemOrder(false);
  }
  const gotoRedeemHistory = () => {
    history.push('/redeemhistory');
  }

  // Update the details in user profile
  // Make the redeem call  using funciton redeemGift method
  const redeemGift = () => {
    if (handleValidation()) {
      setShowLoader(true);
      saveUserProfile(formData).subscribe({
        next: (response) => {
          redeemItem(selectedGift.rowId).subscribe({
            next: (result) => {
              setShowOrderSuccess(true);
              setShowRedeemOrder(false);
              setShowLoader(false);
            }, error : (error ) => {
              // appContext.setErrorMessage(error.message);
              setErrorMessage(error.message);
              setShowRedeemOrder(false);
              setShowOrderFailed(true);
              setShowLoader(false);
            }});
        }, error : (error ) => {
          // appContext.setErrorMessage(error.message);
          setErrorMessage(error.message);
          setShowOrderFailed(true);
          setShowLoader(false);
        }});
    }

  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["deliveryName"]) {
      formIsValid = false;
      errors["deliveryName"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["mobile"]) {
      formIsValid = false;
      errors["mobile"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["address"]) {
      formIsValid = false;
      errors["address"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const renderGiftList = () => (
    <div className="redeem-list-section">
      <div className="redeem-top-section">

        <div className="redeem-page-top">
          <img src="/assets/images/homepage/newhome/newdesign/top9.png" alt="" />
        </div>
        {appContext.userData ? <div className="redeem-history" onClick={gotoRedeemHistory}><span>History</span></div> : null}
        <div className="redeem-main-image">
          {/* <img src="/assets/images/redeem/redeem_shop_main.png" alt="" /> */}
          <Lottie className="redeem-page-top-animation" animationData={gifts} loop={true} />
        </div>
        {/* <div className="redeem-page-title">Redeem Your Points

        </div> */}

      </div>
      {giftList !== null ?
        <div className="redeem-gift-list">
          <Grid container spacing={3}>
            {
              giftList.map((giftItem) => (
                <Grid key={giftItem.id} item xs={6} sm={4} md={3} lg={2} xl={2}>
                  <RedeemCard {...giftItem} redeemGift={() => { selectRedeemGift(giftItem) }} userData = {appContext.userData}/>
                </Grid>
              ))
            }
          </Grid>
        </div>
        : <div>Loading...</div>
      }
    </div>
  )

  const renderRedeemOrder = () => (
    <div className="render-order-summary-card">
      <div className="order-summary-title">
        Redeem Order Summary
      </div>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="order-gift-details">
              <div className="gift-image"><img src={selectedGift.imagePath} alt="" /></div>
              <div className="gift-name">{selectedGift.name}</div>
              <div className="gift-points"><span>{selectedGift.value} points</span></div>
              <div className="gift-description">{selectedGift.description}</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="formField"><TextField name="deliveryName" value={formData.deliveryName || EMPTY_STRING} onChange={handleChange} required fullWidth label="Full Name" variant="outlined" size="small" />
              <span className="error-text">{formError["deliveryName"]}</span></div>
            <div className="formField"><TextField type="phone" name="mobile" value={formData.mobile || EMPTY_STRING} onChange={handleChange} required fullWidth label="Phone Number" variant="outlined" size="small" />
              <span className="error-text">{formError["mobile"]}</span></div>
            <div className="formField"><TextField name="address" value={formData.address || EMPTY_STRING} onChange={handleChange} required fullWidth multiline rows={7} label="Delivery Address" variant="outlined" size="small" />
              <span className="error-text">{formError["address"]}</span></div>
          </Grid>
        </Grid>
        <div className="buttons-container">
          <Button variant="contained" color="secondary" onClick={cancelRedeemGift}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={redeemGift}>Redeem</Button>
        </div>
      </form>
      <div className="redeem-note">Note: The pictures of the gifts are for representation only and the actual gift may vary from the picture</div>
    </div>
  )

  const renderShowOrderSuccess = () => (
    <div className="order-status">
      <div className="status-image">
        <img src="/assets/images/redeem/orderSuccess.png" alt="" />
      </div>
      <div className="status-title">Congratulations</div>
      <div className="status-message">Your redeem request has been placed successfully</div>
    </div>
  )

  const renderShowOrderFailed = () => (
    <div className="order-status">
      <div className="status-image">
        <img src="/assets/images/redeem/orderFailed.png" alt="" />
      </div>
      <div className="status-title">OOPS</div>
      <div className="status-message">{errorMessage}</div>
    </div>
  )

  return (
    <>
      {showLoader ? <Loader /> : <div className="redeem-page-container">
        {showGiftList ? renderGiftList() : null}
        {showRedeemOrder ? renderRedeemOrder() : null}
        {showOrderSuccess ? renderShowOrderSuccess() : null}
        {showOrderFailed ? renderShowOrderFailed() : null}
      </div>}

      {showAds ? <><AdComponent></AdComponent></> : null}
    </>
  )
}

export default Redeem;