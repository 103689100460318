import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../../app-context';
import { CONTEST_TYPE_ACTIVITY, CONTEST_TYPE_CUDENTS, CONTEST_TYPE_QUIZ, EMPTY_STRING, GRADE_LIST, MANDATORY_FIELD_MSG, STATUS_ENDED, STATUS_NOT_STARTED, STATUS_STARTED } from '../../../components/constants/constants';
import Timer from "../../../components/games/timer/timer.component";
import AdComponent from '../../../components/google-ads/google-ads.component';
import Loader from '../../../components/loader/loader.component';
import YesNoDialog from '../../../components/message-dialog/yes-no-dialog.component';
import { startContest } from '../../../services/contest-service';
import { getContestByCode, getContestById } from '../../../services/contest-service';
import { findByUser, saveUserProfile } from '../../../services/user-service';
import { isNotEmpty } from '../../../services/util-service';
import CPQuiz from './../cp-quiz/cp-quiz.component';
import './cp-start-page.styles.scss';
import CPActivity from '../cp-activity/cp-activity.component';

const ContestStartPage = (props) => {

  const contestIdParam = props.match.params.contestId;
  const contestCodeParam = props.match.params.contestCode;
  const initProfile = {
    userIdentification: EMPTY_STRING, participantName: EMPTY_STRING, mobile: EMPTY_STRING, institutionId: EMPTY_STRING
  };
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(EMPTY_STRING);
  const [contestId, setContestId] = useState(contestIdParam);
  const [contestCode, setContestCode] = useState(contestCodeParam);
  const [contestDetail, setContestDetail] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [showCudentContest, setShowCudentContest] = useState(false);
  const [isResultAvailable, setIsResultAvailable] = useState(false);
  const [isStatusEnded, setIsStatusEnded] = useState(false);
  const [isParticipationStatusEnded, setIsParticipationStatusEnded] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isStatusNotStarted, setIsStatusNotStarted] = useState(false);
  const [isStatusStarted, setIsStatusStarted] = useState(false);
  const [isParticipationStatusStarted, setIsParticipationStatusStarted] = useState(false);
  const [formError, setFormError] = useState({});
  const [currentGrade, setCurrentGrade] = useState(null);
  const [formData, updateFormData] = useState(initProfile);
  const appContext = useContext(AppContext);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [showAds, setShowAds] = useState(false);

  useEffect(() => {
    if (appContext.userData) {
      if (formData.userIdentification === EMPTY_STRING) {
        loadProfile();
      }
      if (currentGrade === null) {
        setCurrentGrade(appContext.userData.grade);
      }
    } else {
      appContext.setDialogMessage('Please login to view the contest.');
      history.push('/signin');
    }

  }, []);

  useEffect(() => {
    if (appContext.userData && contestDetail.length === 0) {
      loadContestDetail();
    }
  }, []);

  const loadProfile = () => {
    findByUser().subscribe({
      next: (response) => {
        response.data.institutionId = contestDetail.institutionId;
        updateFormData(response.data);
      }, error: (error) => {
        appContext.setDialogMessage(error.message);
      }
    });
  }

  const loadContestDetail = () => {
    if (contestCodeParam) {
      getContestByCode(contestCodeParam).subscribe({
        next: (response) => {
          setContestId(response.data.contestId);
          updateContest(response.data);
        }, error: (error) => {
          setShowLoader(false);
        }
      });
    } else {
      getContestById(contestIdParam).subscribe({
        next: (response) => {
          updateContest(response.data);
        }, error: (error) => {
          setShowLoader(false);
        }
      });
    }
  }
  const updateContest = (data) => {
    setContestDetail(data);
    setIsResultAvailable(data.isResultAvailable === 1);
    setIsStatusEnded(data.status === STATUS_ENDED)
    setIsParticipationStatusEnded(data.participationStatus === STATUS_ENDED);
    setIsStatusStarted(data.status === STATUS_STARTED)
    setIsStatusNotStarted(data.status === STATUS_NOT_STARTED);
    setIsParticipationStatusStarted(data.participationStatus === STATUS_STARTED);
    setIsRegistered(data.isRegistered === 1);
    formData.institutionId = data.institutionId;
    setShowLoader(false);
  }

  const viewResult = () => {
    history.push(`/contestpage/result/${contestId}`);
  }

  const goToLeaderboard = () => {
    history.push(`/contestpage/leaderboard/${contestDetail.contestType}/${contestId}`);
  }

  const goToCertificate = () => {
    history.push(`/contestpage/certificate/${contestDetail.contestType}/${contestId}`);
  }

  const onOkClick = () => {
    setShowLoader(true);
    validateAndStartContest();
  }

  const saveProfile = () => {
    formData.institutionId = contestDetail.institutionId;
    saveUserProfile(formData).subscribe({
      next: (response) => {
        console.log(response.message);
      }
    });
  }

  const showStartContest = () => {
    if (isRegistered) {
      showContestSheet(true);
    } else if (handleValidation()) {
      setShowExitDialog(true);
    }
  }

  const validateAndStartContest = () => {
    if (!isRegistered) {
      startContest(contestId, currentGrade, contestCode).subscribe({
        next: (response) => {
          if (contestCode !== EMPTY_STRING) {
            saveProfile();
          }
          loadContestDetail();
          appContext.setSuccessMessage(response.message);
          setErrorMessage(EMPTY_STRING);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          setErrorMessage(error.message);
        }
      });
    } else if(isStatusStarted){
      showContestSheet(true);
      setShowLoader(false);
    } else{
      setShowLoader(false);
    }
  }

  const continueContest = () => {
    if (isParticipationStatusStarted && isStatusStarted) {
      showContestSheet(true);
    }
  }

  const handleChange = (e) => {
    setContestCode(e.target.value.toUpperCase());
  }
  const changeParticipant = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["participantName"]) {
      formIsValid = false;
      errors["participantName"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["mobile"]) {
      formIsValid = false;
      errors["mobile"] = MANDATORY_FIELD_MSG;
    }
    if (!contestCode) {
      formIsValid = false;
      errors["contestCode"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const selectGrade = (e) => {
    setCurrentGrade(e.target.value);
  }

  const onQuizComplete = () => {
    showContestSheet(false);
    setIsParticipationStatusEnded(true);
  }

  const showContestSheet = (show) => {
    switch (contestDetail.contestType) {
      case CONTEST_TYPE_QUIZ:
        setShowQuiz(show);
        break;
      case CONTEST_TYPE_ACTIVITY:
        setShowActivity(show);
        break;
      case CONTEST_TYPE_CUDENTS:
        setShowCudentContest(show);
        break;
    }

  }

  const getSubmitText = () => {
    if (!isRegistered && isStatusNotStarted) {
      return 'Register for Contest';
    } else {
      return 'Start Contest';
    }
  }

  const getRmainingTimeText = () => {
    let days = 0;
    let hour = 0;
    if (contestDetail.length !== 0) {
      days = Math.floor(contestDetail.timeToStart / (3600 * 24));
      hour = Math.floor((contestDetail.timeToStart % (3600 * 24)) / 3600);
    }
    let msg = EMPTY_STRING;
    if (days > 1) {
      msg = days + ' days ';
    } else if (days === 1) {
      msg = days + ' day ';
    }
    if (hour > 1) {
      msg = msg + hour + ' hours';
    } else if (hour === 1) {
      msg = msg + hour + ' hour';
    }
    return msg;
  }

  const timeOut = () => {
    loadContestDetail();
  }

  const startPage = () => {
    return <div className="contest-start-page-container">
      {errorMessage === EMPTY_STRING ? null : <div className="cp-error-msg">{errorMessage}</div>}
      <div className="contest-info-header">
        <div className="contest-image">
          <img src={contestDetail.imagePath} alt="" />
        </div>
        <div className="contest-details">
          <div className="contest-info">
            <div className="contest-name">
              {contestDetail.institutionName}
            </div>
            <div className="contest-date-time">
              Start: <span className="date-time">{moment(contestDetail.startDate).format('Do MMM YY, hh:mm A')}</span>
              <br />
              End: <span className="date-time">{moment(contestDetail.endDate).format('Do MMM YY, hh:mm A')}</span>
            </div>
          </div>
          {isResultAvailable ?
            <div className="contest-start-button">
              {isRegistered && contestDetail.contestType === CONTEST_TYPE_QUIZ? <div className="form-field">
                <Button variant="contained" color="primary" onClick={viewResult}>View Result</Button>
              </div> : null}
              <div className="form-field">
                <Button variant="contained" color="primary" onClick={goToLeaderboard}>Contest Leaderboard</Button>
              </div>
              {isRegistered ? <div className="form-field">
                <Button variant="contained" color="primary" onClick={goToCertificate}>View Certificate</Button>
              </div> : null}
            </div>
            :
            <>{isParticipationStatusEnded || isStatusEnded ?
              <div className='success-msg'>Contest is over. Please wait for the results to be announced.</div>
              :
              <>{isParticipationStatusStarted && isStatusStarted ?
                <div className="contest-start-button">
                  <div className="form-field">
                    <Button variant="contained" color="primary" onClick={continueContest}>Continue</Button>
                  </div>
                </div>
                :
                <>
                  {isRegistered && isStatusNotStarted ? <div className='success-msg '>Contest will start in {getRmainingTimeText()}
                    <span className='timer-msg'> {contestDetail.timeToStart < 3600 ? <Timer timeOut={timeOut} initialMinute={Math.floor(contestDetail.timeToStart / 60)} initialSeconds={contestDetail.timeToStart % 60}></Timer> : null}
                    </span></div> : null}
                  <div className="contest-start-button">
                    {
                      isRegistered ? null : <>
                        <div className="form-field">
                          <TextField name="participantName" value={formData.participantName || EMPTY_STRING}
                            onChange={changeParticipant} required fullWidth label={"Participant's Name"}
                            variant="outlined" size="small" />
                          <span className="error-text">{formError["participantName"]}</span>
                        </div>
                        {
                          formData.mobile !== formData.userId ? <div className="form-field">
                            <TextField name="mobile" value={formData.mobile || EMPTY_STRING}
                              onChange={changeParticipant} required fullWidth label={"Mobile"}
                              variant="outlined" size="small" />
                            <span className="error-text">{formError["mobile"]}</span>
                          </div> : null
                        }
                        <div className="form-field">
                          <TextField name="grade" className="cp-dropdown" value={currentGrade} select label="Class"
                            required onChange={selectGrade} variant="outlined" size="small">
                            {GRADE_LIST.map((grade) => (
                              <MenuItem key={grade.value} value={grade.value}>
                                {grade.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          <div className="error-text">{formError['grade']}</div>
                        </div>
                        <div className="form-field">
                          <TextField name="contestCode" value={contestCode} onChange={handleChange} label="Contest code"
                            required variant="outlined" size="small" disabled={isNotEmpty(contestCodeParam)} />
                          <div className="error-text">{formError['contestCode']}</div>
                        </div>
                      </>
                    }
                    <div className="form-field">
                      <Button variant="contained" color="primary" onClick={showStartContest} disabled={isRegistered && isStatusNotStarted}>{getSubmitText()}</Button>
                    </div>
                  </div>
                </>}</>
            }</>
          }
          {isRegistered && isStatusNotStarted ?
            <div className="ext-btn">
              <div className="success-button" onClick={() => { history.push('/') }} ><span>Practice Quizzes and Games</span></div>
            </div>
            : null
          }

        </div>
        <div className="contest-terms">
          <div className="contest-terms-header">
            Terms and Conditions
          </div>
          <div className="contest-terms-details">
            <iframe className="tnc-frame" src={contestDetail.tncLink}></iframe>
          </div>
        </div>
      </div>


      {showAds ? <><AdComponent></AdComponent></> : null}
    </div>
  }

  const renderMain = () => {
    return <>
      {showQuiz ? <CPQuiz contestId={contestId} grade={currentGrade} onSubmit={onQuizComplete} saveProfile={saveProfile}></CPQuiz> 
      : showActivity ? <CPActivity contestId={contestId} grade={currentGrade} onSubmit={onQuizComplete} saveProfile={saveProfile}></CPActivity> : startPage()}
    </>
  }

  return <>
    {showLoader ? <div className="showLoading"><Loader /></div> : renderMain()}
    <YesNoDialog open={showExitDialog} onClose={() => { setShowExitDialog(false); }} onOk={() => { setShowExitDialog(false); onOkClick(); }}
      message={'You can\'t change the grade and participant name once you start the contest. Do you really want to proceed?'} />

  </>
}

export default ContestStartPage;