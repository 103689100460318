// import { initializeApp } from 'firebase/app';
// import { getAuth } from "firebase/auth";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
// const firebase = initializeApp(firebaseConfig);
// const auth = getAuth(firebase);
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();


export const getAppVerifier = () =>
new firebase.auth.ApplicationVerifier();

export const doSignInWithGoogle = () =>
    auth.signInWithPopup(googleProvider);

export const doSignInWithFacebook = () =>
    auth.signInWithPopup(facebookProvider);

export const doSignOut = () => auth.signOut();

export const doCreateUserWithEmailAndPassword = (email, password) =>
    auth.createUserWithEmailAndPassword(email, password);

export const doCreateUserWithEmailAndPasswordAsync = (email, password) =>
    auth.createUserWithEmailAndPasswordAsync(email, password);

export const doSignInWithEmailAndPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

export const doSendSignInLinkToEmail = (email, password) =>
    auth.sendSignInLinkToEmail(email, password);

export const doPasswordReset = email => auth.sendPasswordResetEmail(email);

export const doPasswordUpdate = password =>
    auth.currentUser.updatePassword(password);

export const doSignInWithPhoneNumber = (phoneNumber, appVerifier) =>
    auth.signInWithPhoneNumber(phoneNumber, appVerifier);

export const getCurrentUser = () =>
    auth.currentUser;


export default firebase;