import React, { useState, useEffect, useContext } from "react";
import Lottie from "lottie-react";
import blast from "../animations/blast.json";
import heart from "../animations/heart.json";
import './game-console-fire.styles.scss';

function GameConsoleFire(props) {

  const [gunLeft, setGunLeft] = useState('30vw');
  const [bulletLeft, setBulletLeft] = useState('0vw');
  const [fireBullet, setFireBullet] = useState(false);
  const [gameLives, setGameLives] = useState([1, 1, 1]);
  const [currentScore, setCurrentScore] = useState(0);
  const [correctOptions, setCorrectOptions] = useState(null);
  const [wrongOptions, setWrongOptions] = useState(null);
  const [obstaclePositions, setObstaclePositions] = useState([false, false, false, false, false, false, false]);
  const [currentData, setCurrentData] = useState([null, null, null, null, null, null, null]);
  const [previousOptions, setPreviousOptions] = useState([null,null,null])

  var RightOrWrong = null;
  let livesLeft = 3;
  let counter = 0;
  let infiniteTimer = null;
  let obstacle = [];
  let obstacleTimeCounter;

  useEffect(() => {
    obstacle = [null, null, null, null, null, null, null];
    obstacleTimeCounter = 5000;
    setWrongOptions(props.gameData["wrongOptions"]);
    setCorrectOptions(props.gameData["correctOptions"]);
    // setTimeout(() => {
    //   checkDead();
    // }, 000);
  }, [])

  useEffect(() => {
    if (props.gameStart) {
      obstacle = [null, null, null, null, null, null, null];
      obstacleTimeCounter = 5000;
      setCurrentScore(0);
      setGameLives([1, 1, 1]);
      setTimeout(() => {
        checkDead();
      }, 1000);
    }
  }, [props.gameStart])

  const moveGunRight = () => {
    let left = gunLeft;
    left = parseInt(gunLeft.slice(0, -2));
    if (left < 60) {
      left = (left + 10) + 'vw';
      setGunLeft(left);
    }
  }

  const moveGunLeft = () => {
    let left = gunLeft;
    left = parseInt(gunLeft.slice(0, -2));
    if (left > 0) {
      left = (left - 10) + 'vw';
      setGunLeft(left);
    }
  }

  const gunFire = () => {
    if (!fireBullet) {
      let moveLeft = gunLeft;
      setBulletLeft(moveLeft);
      props.playMusic('shoot');
      setFireBullet(true);
      setTimeout(() => {
        setFireBullet(false);
      }, 2000);
    }
  }

  const addObstacle = () => {
    if (correctOptions != null && wrongOptions != null) {
      let randomLocation;
      let newArr
      let newDataArr = currentData;
      while (true) {
        randomLocation = Math.floor((Math.random() * 7));
        if (obstaclePositions[randomLocation] === false) {
          break;
        }
      }
      newDataArr[randomLocation] = getObstacle();
      setCurrentData([...newDataArr]);
      newArr = obstaclePositions;
      newArr[randomLocation] = true;
      setObstaclePositions([...newArr]);
    }
  }

  const getObstacle = () => {
    RightOrWrong = Math.round(Math.random());
    if (RightOrWrong === previousOptions[0] && RightOrWrong === previousOptions[1] && RightOrWrong === previousOptions[2]) {
      if (RightOrWrong === 0) {
        RightOrWrong = 1;
      } else {
        RightOrWrong = 0;
      }
    } else {
      let newArr = [...previousOptions];
      newArr.push(RightOrWrong);
      newArr = newArr.slice(1);
      setPreviousOptions([...newArr]);
    }
    if (RightOrWrong === 0) {
      return ({ result: 'correct', img: correctOptions[Math.floor(Math.random() * correctOptions.length)], showPoint: false, showError: false });
    } else {
      return ({ result: 'wrong', img: wrongOptions[Math.floor(Math.random() * wrongOptions.length)], showPoint: false, showError: false });
    }
  }

  const setLives = () => {
    let val = [0, 0, 0];
    for (let i = 1; i <= 3; i++) {
      if (i <= livesLeft) {
        val[i - 1] = 1;
      }
    }
    setGameLives([...val]);
  }


  const checkDead = () => {
    infiniteTimer = setInterval(function () {
      let bPos = null;
      let bLeft = null;
      let bCurrentPos = null;
      let bulletEle = document.getElementById("bullet");
      if (bulletEle != null) {
        bPos = parseInt(window.getComputedStyle(bulletEle).getPropertyValue("bottom"));
        bLeft = parseInt(window.getComputedStyle(bulletEle).getPropertyValue("left"));
      }
      for (let i = 0; i < 7; i++) {
        let obs = document.getElementById("obstacle" + i);
        if (obs != null) {
          obstacle[i] = parseInt(window.getComputedStyle(obs).getPropertyValue("bottom"))
          if (bPos !== null && bLeft === parseInt(window.getComputedStyle(obs).getPropertyValue("left"))) {
            bCurrentPos = i;
          }
        } else {
          obstacle[i] = null;
        }
      }

      if (bPos != null && obstacle[bCurrentPos] != null) {
        if ((bPos + 30) > obstacle[bCurrentPos] && obstacle[bCurrentPos] > 0) {
          obstacle[bCurrentPos] = null;
          setFireBullet(false);
          if (currentData[bCurrentPos]['result'] === 'correct') {
            // got point
            counter = counter + 1;
            setCurrentScore(counter);
            props.updateCurrentScore(counter);
            props.playMusic('won');
            let tempCurrData = currentData;
            tempCurrData[bCurrentPos]["showPoint"] = true;
            setCurrentData(tempCurrData);
            setObstaclePositions([...obstaclePositions]);
          } else {
            //reduce life
            let tempCurrData = currentData;
            tempCurrData[bCurrentPos]["showError"] = true;
            props.playMusic('lost');
            setCurrentData(tempCurrData);
            setObstaclePositions([...obstaclePositions]);
            livesLeft = livesLeft - 1;
            setLives();
            if (livesLeft === 0) {
              clearInterval(infiniteTimer);
              livesLeft = 3;
              props.stopGame(counter);
              counter = 0;
            }

          }
          setTimeout(() => {
            let temp = obstaclePositions;
            temp[bCurrentPos] = false;
            setObstaclePositions([...temp]);
          }, 1000);
        }
      }

      obstacleTimeCounter = obstacleTimeCounter + 10;
      if (obstacleTimeCounter >= 5000) {
        addObstacle();
        obstacleTimeCounter = 0;
      }
      let tempArr = obstaclePositions;
      for (let i = 0; i < 7; i++) {
        // Check if obstacle reached bottom
        if (obstacle[i] != null && obstacle[i] < -100) {
          if (currentData[i]["result"] === 'correct') {
            livesLeft = livesLeft - 1;
            setLives();
            if (livesLeft === 0) {
              clearInterval(infiniteTimer);
              livesLeft = 3;
              props.stopGame(counter);
              counter = 0;
            }
          }
          tempArr[i] = false;
        }
      }
      setObstaclePositions([...tempArr]);
    }, 10);
  }

  return (
    <div className="game-console-fire-container">
      {props.gameStart ? <>
        <div className="game-console-fire-controls-left">
          <div className="game-console-fire-left-button-fire" onClick={gunFire}>
            <img src="https://www.cudents.com/prod/assets/images/games/running/common/fire_left.webp" alt="" />
          </div>
          <div className="game-console-fire-left-button-move" onClick={moveGunLeft}>
            <img src="https://www.cudents.com/prod/assets/images/games/running/common/move_left.webp" alt="" />
          </div>
        </div>

        <div className="game-console-fire-play-area">
          <div className="game-console-fire-background">
            <img className="background-animation" src={props.gameData["backgroundImage"]} alt="" />
          </div>
          <div className="game-console-fire-current-score">
            <img src={props.gameData.pointImage} alt="" /> {currentScore}
          </div>
          <div className="game-console-fire-lives">
            {
              gameLives.map((val) => {
                return (val === 1 ? <Lottie className="game-console-fire-lives-image" animationData={heart} loop={true} /> : <div className="game-console-fire-lives-image">&nbsp;</div>)
              })
            }
          </div>

          <div className="game-console-fire-gun" style={{ 'left': gunLeft }}>
            <img src={props.gameData["runnerImage"]} alt="" />
          </div>
          <div className="game-console-fire-obstacle-container">
            {
              obstaclePositions.map((isVisible, index) => (
                isVisible ? <div className="game-console-fire-obstacle-item" id={'obstacle' + index} style={{ 'left': (index * 10) + 'vw' }}>
                  {!currentData[index]['showPoint'] && !currentData[index]['showError'] && <img src={currentData[index]['img']}></img>}
                  {currentData[index]['showPoint'] && <div className="bonus-point">
                    <img src={props.gameData.pointImage} alt="" />
                  </div>}
                  {currentData[index]['showError'] && <div className="error-point"><Lottie className="game-console-runner-props" animationData={blast} loop={false} /></div>}
                </div> : null
              ))
            }

          </div>
          {fireBullet && <div className="game-console-fire-bullet" id="bullet" style={{ 'left': bulletLeft }}><img src={props.gameData["bulletImage"]} alt="" /></div>
          }
        </div>

        <div className="game-console-fire-controls-right">
          <div className="game-console-fire-right-button-fire" onClick={gunFire}>
            <img src="https://www.cudents.com/prod/assets/images/games/running/common/fire_right.webp" alt="" />
          </div>
          <div className="game-console-fire-right-button-move" onClick={moveGunRight}>
            <img src="https://www.cudents.com/prod/assets/images/games/running/common/move_right.webp" alt="" />
          </div>
        </div>
      </> : null}
      {!props.gameStart ? <div className="game-console-start-game-container">
        <div className="game-console-scoreboard-image">
          <img src={props.gameData.scoreboard.image} alt="" />
          <div className="game-console-scorecard-info">
            {/* <div><img src={props.gameData.runnerImage} alt="" /></div> */}
            {/* <div className="game-console-scorecard-title">{props.gameData.gameName}</div> */}
            <div className="game-console-game-info">
              <img src={props.gameData.infoImage} alt="" />
            </div>
            <div className="game-console-scores">
              <div>
                <div>Highest-Score</div>
                <div>{props.gameData.highScore}</div>
              </div>
              <div>
                <div>Score</div>
                <div>{currentScore}</div>
              </div>
            </div>
          </div>
          <div className="game-console-play-button" onClick={props.restartGame}>
            <img src={props.gameData.scoreboard.playButton} alt="" />
          </div>
        </div>
      </div> : null}
    </div>
  );
}

export default GameConsoleFire;