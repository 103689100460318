import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../app-context';
import { getCoupons, saveCoupon } from '../../../services/coupon-service';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../services/grid-style';
import { EMPTY_STRING, MANDATORY_FIELD_MSG } from '../../constants/constants';
import Loader from '../../loader/loader.component';
import DateTimeComponent from '../date-time/date-time.component';
import './admin-coupon.styles.scss';

const AdminCoupon = () => {
  const initData = {
    code: EMPTY_STRING, discountPercentage: EMPTY_STRING, validity: EMPTY_STRING,  discountType: EMPTY_STRING, primaryOwnerId: EMPTY_STRING, secondaryOwnerId: EMPTY_STRING, 
  };
  const [couponList, setCouponList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [formData, updateFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const appContext = useContext(AppContext);

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    getCoupons(selectedPage - 1).subscribe({
      next: (response) => {
        setCouponList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }});
  }

  const savedata = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setShowLoader(true);
      saveCoupon(formData).subscribe({
        next: (response) => {
          search(page);
          updateFormData(initData);
          appContext.setSuccessMessage(response.message);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }});
    }
  }

  const editCoupon = (coupon) => {
    updateFormData(coupon);
  }

  const handleStartDateChange = (e) => {
    const validity = moment(e.target.value);
    updateFormData({
      ...formData,
      [e.target.name]: validity
    });
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["code"]) {
      formIsValid = false;
      errors["code"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["discountPercentage"]) {
      formIsValid = false;
      errors["discountPercentage"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["validity"]) {
      formIsValid = false;
      errors["validity"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["discountType"]) {
      formIsValid = false;
      errors["discountType"] = MANDATORY_FIELD_MSG;
    }
    // if (!formData["primaryOwnerId"]) {
    //   formIsValid = false;
    //   errors["primaryOwnerId"] = MANDATORY_FIELD_MSG;
    // }
    // if (!formData["secondaryOwnerId"]) {
    //   formIsValid = false;
    //   errors["secondaryOwnerId"] = MANDATORY_FIELD_MSG;
    // }
    setFormError(errors);
    return formIsValid;
  }

  const renderContact = () => {
    return (
      <div className="couponFormContainer">
        <div className="couponTitle">Coupon Details</div>
        <div className="couponForm">
          <form noValidate autoComplete="off" onSubmit={savedata}>
            <div className="formField">
              <TextField name="code" value={formData.code || EMPTY_STRING} onChange={handleChange} required fullWidth label="Coupon Code" variant="outlined" size="small" />
              <span className="error-text">{formError["code"]}</span>
            </div>
            <div className="formField">
              <TextField name="discountPercentage" value={formData.discountPercentage || EMPTY_STRING} onChange={handleChange} required fullWidth label="Discount Percentage" variant="outlined" size="small" />
              <span className="error-text">{formError["discountPercentage"]}</span>
            </div>
            <div className="formField">
              <DateTimeComponent name="validity" label="Validity" onDateSelection={handleStartDateChange} defaultDate={formData.validity ? moment(formData.validity) : null} />
              <span className="error-text">{formError["validity"]}</span>
            </div>
            <div className="formField">
              <TextField name="discountType" value={formData.discountType || EMPTY_STRING} onChange={handleChange} required fullWidth label="Discount Type" variant="outlined" size="small" />
              <span className="error-text">{formError["discountType"]}</span>
            </div>
            <div className="formField">
              <TextField name="primaryOwnerId" value={formData.primaryOwnerId || EMPTY_STRING} onChange={handleChange} fullWidth label="Primary Owner" variant="outlined" size="small" />
              <span className="error-text">{formError["primaryOwnerId"]}</span>
            </div>
            <div className="formField">
              <TextField name="secondaryOwnerId" value={formData.secondaryOwnerId || EMPTY_STRING} onChange={handleChange} fullWidth label="Secondary Owner" variant="outlined" size="small" />
              <span className="error-text">{formError["secondaryOwnerId"]}</span>
            </div>
            <div className="formField sendButton">
              {showLoader ? <div className="showLoading"><Loader /></div> : <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>}
            </div>
          </form>
        </div>
      </div>
    );
  }

  const getCouponsGrid = () => {
    return (
      <div className="couponFormContainer">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Coupon Coupons</StyledTableCell>
                <StyledTableCell>Discount Percentage</StyledTableCell>
                <StyledTableCell>Discount Type</StyledTableCell>
                <StyledTableCell>Primary Owner Name</StyledTableCell>
                <StyledTableCell>Secondary Owner Name</StyledTableCell>
                <StyledTableCell>Validity</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {couponList.map((coupon) => (
                <StyledTableRow key={coupon.code}>
                  <StyledTableCell>{coupon.code}</StyledTableCell>
                  <StyledTableCell>{coupon.discountPercentage}</StyledTableCell>
                  <StyledTableCell>{coupon.discountType===1 ? 'Clubbed' : 'Absolute'}</StyledTableCell>
                  <StyledTableCell>{coupon.primaryOwnerName}</StyledTableCell>
                  <StyledTableCell>{coupon.secondaryOwnerName}</StyledTableCell>
                  <StyledTableCell>{moment(coupon.validity).format('DD-MM-YYYY hh:mm:ss A')}</StyledTableCell>
                  <StyledTableCell>
                    <Button onClick={() => { editCoupon(coupon) }} variant="contained" color="secondary">
                      Edit
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }

  return (
    <div className="admin-coupon-details">
      {showLoader ? <div className="showLoading"><Loader /></div>
        : <>
          {renderContact()}
          {getCouponsGrid()}
        </>
      }
    </div>
  );
}


export default AdminCoupon;


