import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import Loader from "../../../components/loader/loader.component";
import { findGuessMasterTopic } from "../../../services/guess-master-service";
import confused from "../animations/confused.json";
import './guess-master-list.styles.scss';
function GuessMasterList(props) {

    const subject = props.match.params.subject;
    const history = useHistory();
    const [showLoader, setShowLoader] = useState(true);
    const [guessData, setGuessData] = useState(null);

    useEffect(() => {
        if (guessData === null) {
            findGuessMasterTopic(subject).subscribe({
                next: (response) => {
                    setGuessData(response.data);
                  setShowLoader(false);
                }, error: (error) => {
                  setShowLoader(false);
                }
              });
        }
    }, []);

    return (<>{showLoader ? <div className="showLoading">< Loader /></div>
        :
        <div className="guess-master-list-page">
            <div className="guess-master-main-top-image">
                <img src="/assets/images/homepage/newhome/newdesign/curve4.png" alt="" />
            </div>
            <div className="guess-master-main-top-section">
                <div className="guess-master-main-animation"> <Lottie animationData={confused} loop={true} /></div>
            </div>
            <div className="guess-master-main-card-list">
                    {
                        guessData.map((data, idx) => (
                            <div key={idx} className="guess-master-main-card" onClick={()=>{history.push(`/guessPlayer/${data.rowId}`);}}>
                                <div className="guess-master-main-card-image">
                                    <img src={data.topicImagePath} alt="" />
                                </div>
                                <div className="guess-master-main-card-title">
                                    {data.topic}
                                </div>
                            </div>
                        ))
                    }
                </div>
        </div>
    }
    </>);
}

export default GuessMasterList;