import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../app-context';
import Notification from '../../../pages/notification/notification.component';
import { saveNotification } from '../../../services/notification_service';
import { EMPTY_STRING, FEATURE_NAME } from '../../constants/constants';
import './admin-notification.styles.scss';

const AdminNotification = (props) => {
  const initData = {
    title: EMPTY_STRING, featureName: EMPTY_STRING, description: EMPTY_STRING
  };
  const [formData, updateFormData] = useState(initData);
  const [currRow, setCurrRow] = useState(initData);
  const appContext = useContext(AppContext);

  const savedata = (event) => {
    event.preventDefault();
    saveNotification(formData).subscribe({
      next: (response) => {
        updateFormData(initData);
        setCurrRow(response.data);
        appContext.setSuccessMessage(response.message);
      }});
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const getFormPanel = () => {
    return (
      <form noValidate autoComplete="off" onSubmit={savedata}>
        <div className="controlBox">
          <div className="supportControl" fullWidth>
            <TextField name="featureName" value={formData.featureName} select label="Feature Name" variant="outlined" size="small" fullWidth
              onChange={handleChange}>
              {FEATURE_NAME.map((featureName) => (
                <MenuItem key={featureName} value={featureName}>
                  {featureName}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="supportControl">
            <TextField name="title" label="Title" required variant="outlined" size="small" fullWidth
              value={formData.title} onChange={handleChange}
            />
          </div>
          <div className="supportControl">
            <TextField name="description" label="Description" required multiline rows={3} variant="outlined" size="small" fullWidth
              value={formData.description} onChange={handleChange}
            />
          </div>
          <div className="supportControl sendButton">
            <Button type="submit" variant="contained" color="secondary">
              Submit
            </Button>
          </div>
        </div>
      </form>
    );
  }


  return (
    <div className="notification-main">
      <div className="notification-title">Notifications</div>
      {getFormPanel()}
      <Notification role="admin" row={currRow} />
    </div>
  );
}

export default AdminNotification;