import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Timer from "../../../components/games/timer/timer.component";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../../app-context';
import ImageTextfield from '../../../components/admin/admin-question-bank/image-textfield.component';
import { EMPTY_STRING } from '../../../components/constants/constants';
import Loader from '../../../components/loader/loader.component';
import { saveActivity, startActivityContest } from '../../../services/activity-contest-service';
import { isNotNull } from '../../../services/util-service';
import './cp-activity.styles.scss';

const CPActivity = (props) => {
  const grade = props.grade;
  const code = props.code;
  const contestId = props.contestId;
  const initData = {
    contentPath: EMPTY_STRING
  };
  const appContext = useContext(AppContext);
  const [quizDetails, setQuizDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [formData, updateFormData] = useState(initData);
  const history = useHistory();
  const ref = useRef();

  useEffect(() => {
    if (quizDetails.length === 0) {
      loadActivity();
    }
  }, []);

  const loadActivity = () => {
    loadOnlyQuestion();
  }

  const loadOnlyQuestion = () => {
    startActivityContest(contestId).subscribe({
      next: (response) => {
        updateFormData(response.data);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }
    });
  }

  const handleImageChange = (e, binaryImage) => {
    updateFormData({
      ...formData,
      [e.target.name]: binaryImage
    });
  };

  const handleDeleteImage = (imageFieldName) => {
    updateFormData({
      ...formData,
      image: null,
      contentPath: null
    });
  };
  
  const saveActivityData = () => {
    if(isNotNull(formData.image)){
      setShowLoader(true);
      saveActivity(formData).subscribe({
        next: (response) => {
          updateFormData(response.data);
          appContext.setDialogMessage(response.message);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }
      });
    }
  }

  const timeOut = () => {
    // appContext.setDialogMessage('Contest is over. Please wait for the results to be announced.');
    saveActivityData();
    history.goBack();
  }

  const renderView = () => {
    return (
      <div className="activity-page">
         {formData.remainingTime < 3600 ?<div className="timer-section">
              <Timer timeOut={timeOut} initialMinute={Math.floor(formData.remainingTime / 60)} initialSeconds={formData.remainingTime % 60}></Timer>
          </div> : null}
        <span className="upload-image-title">Upload Image</span>
        <div className="image-container">
          <ImageTextfield id="activity-image" image={formData.contentPath} hideURLField={true}
            imageFieldName="image" handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage}
            imagePath="contentPath">
          </ImageTextfield>
          <div className="formField sendButton">
            {showLoader ? <div className="showLoading"><Loader /></div> : <Button variant="contained" color="secondary" onClick={saveActivityData}>
              Submit
            </Button>}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div ref={ref}>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        renderView()}
    </div>
  );
}

export default CPActivity;