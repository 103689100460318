import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import Loader from "../../components/loader/loader.component";
import PortraitLandscape from "../../components/portrait-to-landscape/portrait-to-landscape";
import { isNotEmpty, isNotNull } from "../../services/util-service";
import correctAnswerAnimation from "./animations/correct_answer.json";
import cuteTiger from "./animations/cute_tiger.json";
import faceFly from "./animations/face_fly.json";
import levelClearCelebrate from "./animations/level_clear.json";
import "./correct-sequence-game.styles.scss";

function CorrectSequenceGame(props) {

  const [optionsList, setOptionsList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [jumbledData, setJumbledData] = useState(null);
  const [answerData, setAnswerData] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const [correctAnswerCelebrate, setCorrectAnswerCelebrate] = useState([false, false, false, false, false]);
  const [backgroundMusic, setBackgroundMusic] = useState(new Audio(`https://www.cudents.com/prod/assets/images/games/thecorrectsequence/background.mp3`));
  const [correctAnswerMusic, setCorrectAnswerMusic] = useState(new Audio(`https://www.cudents.com/prod/assets/images/games/thecorrectsequence/correct.mp3`));
  const [winLevelMusic, setWinLevelMusic] = useState(new Audio(`https://www.cudents.com/prod/assets/images/games/thecorrectsequence/levelwin.mp3`));

  const resetGame = () => {
    setOptionsList([]);
    setTimeout(() => {
      const options = props.questionDetails.options.filter(item => isNotEmpty(item.imagePath))
      setOptionsList(options);
    }, 500);
  }

  useEffect(() => {
    setShowLoader(true);
    resetGame();
  }, [props.questionDetails]);


  useEffect(() => {
    setShowAnimation(true);
    if (optionsList.length > 0) {
      jumbleLevelData();
    }
  }, [optionsList]);

  useEffect(() => {
    setShowAnimation(true);
    setCorrectAnswerCelebrate([false, false, false, false, false, false]);
  }, [props.currentLevel])

  const getRandomAnimation = () => {
    if (props.currentLevel % 2 === 0) {
      return <Lottie animationData={cuteTiger} loop={true} />
    } else if (props.currentLevel % 3 === 0) {
      return <Lottie animationData={faceFly} loop={true} />
    } else {
      return <Lottie animationData={faceFly} loop={true} />
    }
  }

  // Shuffle the images in the option box everytime kid visits the game or refreshes the page
  const jumbleLevelData = () => {
    let shuffleData = optionsList
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    // Set the values for the answer data and the shuffled data
    setAnswerData(optionsList);
    setJumbledData(shuffleData);
    setShowLoader(false);
  }


  // Verify if the level is complte. If yes then show celebration animation and next level button
  const verifyLevelClear = (currentArr) => {
    let resultFlag = currentArr.filter(item => (item.isCorrect === 0 && item.imagePath !== null));
    if (resultFlag.length === 0) {
      if (props.music) {
        backgroundMusic.pause();
        winLevelMusic.volume = .2;
        winLevelMusic.play();
      }
      // setLevelClear(true);
      setCorrectAnswerCelebrate([true, true, true, true, true, true]);
      setShowAnimation(false);
      props.correctAnswer();
    } else {
      setTimeout(() => {
        setCorrectAnswerCelebrate([false, false, false, false, false, false])
      }, 1000);
    }

  }

  // If right pic placed then show that pic in the answer sequence
  const displayAnswer = (ind, ip) => {
    if (props.music) {
      correctAnswerMusic.play();
    }
    let newAnswer = answerData.map((item) => item.imagePath === ip ? { ...item, isCorrect: 1 } : { ...item })
    setAnswerData(newAnswer);
    let showCelebration = [...correctAnswerCelebrate];
    showCelebration[ind] = true;
    setCorrectAnswerCelebrate(showCelebration);
    // verify if the level is complete?
    verifyLevelClear(newAnswer);
  }

  return (
    <>
      <div className="sequence-game-container">
        <div className="sequence-game-play-area">
          <div className="sequence-game-bg">
            <img src={props.questionDetails.questionImagePath} alt="" />
          </div>
          <div className="sequence-game-level-button">
                  Level {props.currentLevel}
          </div>
          {/* {showAnimation ?
            <div className="sequence-game-animation">
              {getRandomAnimation()}
            </div> : <></>
          } */}
          {/* Placeholder for the options to choose from */}
          {showLoader ? <div className="showLoading"><Loader /></div> : <>
            <div className="sequence-game-list-container">
              <div className="sequence-game-list-backdrop">
                {props.nextLevelButton ?
                  <div className="sequence-game-level-clear">
                    <div className="sequence-game-level-clear-animation">
                      <Lottie animationData={levelClearCelebrate} loop={true} />
                    </div>
                    <div className="sequence-game-next-level">
                      <div className="sequence-game-next-level-button" onClick={props.playNextLevel}>
                        <img src="https://www.cudents.com/prod/assets/images/games/thecorrectsequence/nextLevelButton.webp" alt="" />
                      </div>
                    </div>
                  </div>
                  :
                  <>
                    <div className="sequence-game-list-title">
                      {isNotNull(jumbledData) ? <div>{props.questionDetails.questionText}</div> : <></>}
                    </div>
                    <div className="sequence-game-list-item">
                      {isNotNull(jumbledData) ?
                        jumbledData.map(({ text, imagePath }, index) => (
                          <> {isNotNull(text) ?
                            <DragDropContainer targetKey={imagePath} >
                              <div className="sequence-game-list-item-image">
                                <img src={imagePath} alt="" />
                              </div> </DragDropContainer> : null
                          } </>
                        )) : <></>
                      }
                    </div>
                  </>
                }
              </div>
            </div>

            {/* Placeholder for the correct options to be dropped */}
            <div className="sequence-game-placeholder-list">
              {answerData != null ?
                answerData.map(({ imagePath, isCorrect }, index) => (
                  <> {imagePath != null ?
                    <>

                      <DropTarget targetKey={imagePath} onHit={() => { displayAnswer(index, imagePath) }}>
                        <div className="sequence-game-placeholder-item-image">
                          {correctAnswerCelebrate[index] ?
                            <div className="sequence-game-placeholder-correct-answer">
                              <Lottie className="sequence-game-celebrate-answer" animationData={correctAnswerAnimation} loop={true} />
                            </div>
                            : <></>
                          }
                          {isCorrect === 1 ?
                            <img src={imagePath} alt="" />
                            :
                            <div className="sequence-game-placeholder-blank-box">{index + 1}</div>
                          }
                        </div>
                      </DropTarget>
                      {/* {answerData[index + 1]["imagePath"] != null ?
                          <div className="sequence-game-placeholder-list-arrow">
                            <img src="https://www.cudents.com/prod/assets/images/games/thecorrectsequence/rightArrow.webp" alt="" />
                          </div>
                          : <></>
                        }  */}
                    </> : null
                  } </>
                )) : <></>
              }
            </div>
          </>}
        </div>
        {/* } */}
        {/* </>
        } */}
      </div>
      <div className="sequence-game-portrait">
        <PortraitLandscape></PortraitLandscape>
      </div>
    </>
  );
}

export default CorrectSequenceGame;