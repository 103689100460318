import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { default as React, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../app-context';
import { getGrades, getSubjects, getTopics } from '../../../services/category-service';
import { getContestQuestions, saveContestQuestions } from '../../../services/quiz-contest-service';
import {  getContestsAdmin } from '../../../services/contest-service';
import { StyledTableCell, StyledTableRow } from '../../../services/grid-style';
import { getApprovedQuestions } from '../../../services/question-service';
import { CONTEST_TYPE_QUIZ, EMPTY_STRING } from '../../constants/constants';
import Loader from '../../loader/loader.component';
import YesNoDialog from '../../message-dialog/yes-no-dialog.component';
import ResultDialog from '../../result-dialog/result-dialog.component';
import './contest-question.styles.scss';
const ContestQuestion = () => {
  const OTHER = 'Other'
  const LEVELS = [1, 2, 3];
  const initFormData = {
    grade: 1,
    subject: EMPTY_STRING,
    topic: EMPTY_STRING,
    level: 1,
    name: EMPTY_STRING
  };

  const [disableHeader, setDisableHeader] = useState(false);
  const [showLoader1, setShowLoader1] = useState(false);
  const [showLoader2, setShowLoader2] = useState(false);
  const appContext = useContext(AppContext);
  const [levelQuestion, setLevelQuestion] = useState([]);
  const [contestQuestions, setContestQuestions] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const classes = useStyles();
  const [showMessageDialog, setShowMsgDialog] = useState(false);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [contest, setContest] = useState(EMPTY_STRING);
  const [contestImported, setContestImported] = useState(EMPTY_STRING);
  const [contests, setContests] = useState([]);
  const [formData, updateFormData] = useState(initFormData);
  const [showPreview, setShowPreview] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  useEffect(() => {
    if (contests.length === 0) {
      loadActiveContests();
    }
  }, []);

  const loadActiveContests = () => {
    getContestsAdmin(CONTEST_TYPE_QUIZ).subscribe({
      next: (response) => {
        setContests(response.data);
      }});
  }
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (gradeList) {
      loadGrade();
    }
  }, []);

  const loadGrade = () => {
    getGrades(1).subscribe({
      next: (response) => {
        setGradeList(response.data);
      }});
  }

  useEffect(() => {
    loadSubjects();
  }, [formData.grade]);

  useEffect(() => {
    loadTopics();
  }, [formData.grade, formData.subject]);

  const loadSubjects = () => {
    if (formData.grade) {
      setShowLoader1(true);
      getSubjects(formData.grade, 1).subscribe({
        next: (response) => {
          setSubjectList([...response.data, OTHER]);
          setShowLoader1(false);
        }, error: (error) => {
          setShowLoader1(false);
        }});
    }
  }

  const loadTopics = () => {
    if (formData.grade && formData.subject !== EMPTY_STRING) {
      setShowLoader1(true);
      getTopics(formData.grade, formData.subject, 1).subscribe({
        next: (response) => {
          setTopicList([...response.data, OTHER]);
          setShowLoader1(false);
        }, error: (error) => {
          setShowLoader1(false);
        }});
    }
  }

  const findAllQuestions = () => {
    setShowLoader2(true);
    getApprovedQuestions(formData.grade, formData.subject, formData.topic, formData.level).subscribe({
      next: (response) => {
        setLevelQuestion(response.data);
        setDisableHeader(true);
        setShowLoader2(false);
      }, error: (error) => {
        setShowLoader2(false);
        appContext.setDialogMessage(error.message);
      }});
  }

  const findContestQuestions = () => {
    setShowLoader2(true);
    getContestQuestions(contest.contestId, formData.grade).subscribe({
      next: (response) => {
        setContestQuestions(response.data);
        setDisableHeader(true);
        setShowLoader2(false);
      }, error: (error) => {
        setShowLoader2(false);
        appContext.setDialogMessage(error.message);
      }});
  }

  const onImportContest = () => {
    setShowLoader2(true);
    getContestQuestions(contestImported.contestId, formData.grade).subscribe({
      next: (response) => {
        updateQuestion(response.data);
        setDisableHeader(true);
        setShowLoader2(false);
      }, error: (error) => {
        setShowLoader2(false);
        appContext.setDialogMessage(error.message);
      }});
  }

  const updateQuestion = (exportedQuestions) => {
    let newList = [];
    exportedQuestions.forEach(inputItem => {
      inputItem.contestId = contest.contestId;
      inputItem.rowId = null;
      newList.push(inputItem);
    });
    setContestQuestions(newList);
  }

  const onEditHeader = () => {
    setDisableHeader(false);
  }

  const addQuestion = (item) => {
    if (!itemExist(item)) {
      item.contestId = contest.contestId;
      item.number = contestQuestions.length + 1;
      setContestQuestions(
        [...contestQuestions, item]
      );
    }
  }

  const sortTable = () => {
    contestQuestions.sort((a, b) => {

    });
  }

  const itemExist = (inputItem) => {
    let exist = false;
    contestQuestions.forEach(item => {
      if (inputItem.grade === item.grade && inputItem.subject === item.subject
        && inputItem.topic === item.topic && inputItem.level === item.level
        && inputItem.questionText === item.questionText && inputItem.questionImagePath === item.questionImagePath) {
        exist = true
      }
    });
    return exist;
  }

  const deleteQuestion = (item) => {
    let filtered = [];
    let index = 1;
    contestQuestions.forEach(inputItem => {
      if (inputItem.number !== item.number) {
        inputItem.number = index
        filtered.push(inputItem);
        index++;
      }
    });
    setContestQuestions(filtered);
  }

  const onSaveContestQuestions = () => {
    setMessage('Do you want to save all contest questions?');
    setShowMsgDialog(true);
  }

  const onOkClick = () => {
    const model = { models: contestQuestions };
    saveContestQuestions(model).subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
      }, error: (error) => {
        appContext.setErrorMessage(error.message);
      }});
  }

  const handleContestChange = (e) => {
    setContest(e.target.value);
  };

  const handleContestImportedChange = (e) => {
    setContestImported(e.target.value);
  };

  const showQuestionDialog = (question) => {
    setCurrentQuestion(question);
    setShowPreview(true);
  }

  const headerFormPanel = () => {
    return <div className='contest-header'>
    <div className="contest-title">Contest Question</div>
    <div className="header-panel">
      <div className="header-field">
        <TextField name="contestCode" value={contest} select label="Contest Code" variant="outlined" size="small"
          fullWidth onChange={handleContestChange}>
          {contests.map((contest) => (
            <MenuItem key={contest.contestCode} value={contest}>
              {contest.institutionName} - {contest.contestCode}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="header-field"><TextField name="grade" value={formData.grade} select label="Class"
        disabled={disableHeader} required onChange={handleChange} variant="outlined" fullWidth size="small">
        {gradeList.map((grd) => (
          <MenuItem key={grd.grade} value={grd.grade}>
            Class {grd.grade}
          </MenuItem>
        ))}
      </TextField>
      </div>
      <div className="header-field">
        <TextField name="subject" value={formData.subject} select label="Subject" fullWidth
          disabled={disableHeader} required onChange={handleChange} variant="outlined" size="small">
          {subjectList.map((subject) => (
            <MenuItem key={subject.name} value={subject.name}>
              {subject.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="header-field">
        <TextField name="topic" value={formData.topic} select label="Topic" fullWidth
          disabled={disableHeader} required onChange={handleChange} variant="outlined" size="small">
          {topicList.map((topic) => (
            <MenuItem key={topic.name} value={topic.name}>
              {topic.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="header-field"><TextField name="level" value={formData.level} select label="Level"
        disabled={disableHeader} required onChange={handleChange} variant="outlined" fullWidth size="small">
        {LEVELS.map((level) => (
          <MenuItem key={level} value={level}>
            Level  {level}
          </MenuItem>
        ))}
      </TextField>
      </div>
      <div className="header-field">
        <Button variant="contained" color="secondary" onClick={findAllQuestions}> Search questions </Button>
      </div>
      <div className="header-field">
        <Button variant="contained" color="primary" onClick={onEditHeader}> Edit Header </Button>
      </div>
      <div className="header-field">
        <Button variant="contained" color="secondary" onClick={findContestQuestions}> Search Contest questions</Button>
      </div>
    </div>
    </div>
  }

  const getQuestionList = () => {
    return (
      <>{levelQuestion.length > 0 ? <div className="contestFormContainer">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Number</StyledTableCell>
                <StyledTableCell>Question</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {levelQuestion.map((question) => (
                <StyledTableRow key={question.number}>
                <StyledTableCell>{question.number}</StyledTableCell>
                  <StyledTableCell>{question.questionText}</StyledTableCell>
                  <StyledTableCell>
                    <VisibilityOutlinedIcon className="icon" onClick={() => { showQuestionDialog(question) }} />
                    <ExitToAppOutlinedIcon className="icon priviewIcon" onClick={() => { addQuestion(question) }} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div> : null}</>
    );
  }

  const getContestsQuestionList = () => {
    return (
      <div className="contestFormContainer">
        <div className="header-panel">
          <div className="header-field">
            <TextField name="contestCode" value={contestImported} select label="Contest Code" variant="outlined" size="small"
              fullWidth onChange={handleContestImportedChange}>
              {contests.map((contest) => (
                <MenuItem key={contest.contestCode} value={contest}>
                  {contest.institutionName} - {contest.contestCode}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="header-field">
            <Button variant="contained" color="primary" onClick={onImportContest}> Import to Current Contest </Button>
          </div>
          <div className="header-field count-text">
            Total Questions:  {contestQuestions.length}
          </div>
        </div>
        {contestQuestions.length > 0 ? <><TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Number</StyledTableCell>
                <StyledTableCell>Grade</StyledTableCell>
                <StyledTableCell>Subject</StyledTableCell>
                <StyledTableCell>Topic</StyledTableCell>
                <StyledTableCell>Level</StyledTableCell>
                <StyledTableCell>Question</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contestQuestions.map((question) => (
                <StyledTableRow key={question.number}>
                <StyledTableCell>{question.number}</StyledTableCell>
                <StyledTableCell>{question.grade}</StyledTableCell>
                  <StyledTableCell>{question.subject}</StyledTableCell>
                  <StyledTableCell>{question.topic}</StyledTableCell>
                  <StyledTableCell>{question.level}</StyledTableCell>
                  <StyledTableCell>{question.questionText}</StyledTableCell>
                  <StyledTableCell>
                    <VisibilityOutlinedIcon className="icon" onClick={() => { showQuestionDialog(question) }} />
                    <DeleteForeverOutlinedIcon className="icon deleteIcon" onClick={() => { deleteQuestion(question) }} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
          <Button onClick={() => { onSaveContestQuestions() }} variant="contained" color="secondary">
            Save Contest Questions
          </Button></>
          : null}
      </div>

    );
  }

  const renderMain = () => {
    return <div className="main-container">
      {headerFormPanel()}
      <Grid justify="center" container spacing={3} xs={12}>
        {levelQuestion.length > 0 ?
          <Grid item xs={12} sm={12} md={5} lg={6} xl={6}>
            {showLoader1 ? <Loader /> : getQuestionList()}
          </Grid>
          : null
        }

        <Grid item xs={12} sm={12} md={7} lg={6} xl={6}>
          {showLoader2 ? <Loader /> : getContestsQuestionList()}
        </Grid>

      </Grid>
      <YesNoDialog open={showMessageDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
      <ResultDialog open={showPreview} onClose={() => { setShowPreview(false) }} result={currentQuestion} />
    </div>
  }

  return (
    <>
      {
        renderMain()
      }
    </>
  );
}

export default ContestQuestion;
