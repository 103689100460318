import React, { useEffect, useState } from "react";

var adsbygoogle = null;
const AdComponent = (props) => {

  useEffect(() => {
    // (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    // <ins className='adsbygoogle'
    //   style={{ display: "inline-block", width: "100%", height: "100%", marginTop: "40px" }}
    //   data-ad-client="ca-pub-6615211630616299"
    //   data-ad-slot="7397681707"
    //   data-adtest="on"
    //   data-ad-format='auto' />
    <></>
  );
}

export default AdComponent;
