import Grid from '@material-ui/core/Grid';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './top-carousel-section.styles.scss';

const TopCarousel = () => {

  const responsiveInfo = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    }
  };

  return (
    <div className="top-carousel">
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={true}
        responsive={responsiveInfo}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        keyBoardControl={true}
        transitionDuration={500}
        autoPlay={true}
        autoPlaySpeed={7000}
        renderButtonGroupOutside={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
      >
        <div className="top-carousel-image">
          <img src="/assets/images/homepage/newhome/banner1.png" alt="" />
        </div>
        <div className="top-carousel-image">
          <img src="/assets/images/homepage/newhome/banner2.png" alt="" />
        </div>
        {/* <div className="top-carousel-image">
          <img src="/assets/images/homepage/banner3.png" alt="" />
        </div> */}
      </Carousel>
    </div>
  );
}


export default TopCarousel;