import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../../app-context';
import { EMPTY_STRING } from '../../../components/constants/constants';
import Loader from '../../../components/loader/loader.component';
import YesNoDialog from '../../../components/message-dialog/yes-no-dialog.component';
import { startQuizContest } from '../../../services/quiz-contest-service';
import CPQuizPlayer from '../cp-quiz-player/cp-quiz-player.component';
import './cp-quiz.styles.scss';

const CPQuiz = (props) => {
  const grade = props.grade;
  const contestId = props.contestId;
  const [quizDetails, setQuizDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const ref = useRef();

  useEffect(() => {
    appContext.setShowHeader(false);
    appContext.setShowFooter(false);
    if (ref.requestFullscreen) {
      ref.requestFullscreen();
    }
    if (quizDetails.length === 0) {
      loadQuizzes();
    }

    return () => {
      appContext.setShowHeader(true);
      appContext.setShowFooter(true);
    }
  }, []);

  const loadQuizzes = () => {
    loadOnlyQuestion();
  }

  const loadOnlyQuestion = () => {
    startQuizContest(contestId).subscribe({
      next: (response) => {
        if (response.data.latestAttemptedQuestion === response.data.models.length) {
          props.onSubmit();
        } else {
          setQuizDetails(response.data);
        }
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }});
  }

  const onOkClick = () => {
    history.goBack();
  }

  const onCloseClick = () => {
    setShowExitDialog(true);
  }

  
  const getClass = (item) => {
    return item < 1 ? 'KG ' + (item+3) : 'Class ' + item;
  }

  const renderView = () => {
    return (
      <div className="quiz-page">
        <div className="top-left-shape"><img src="/assets/images/quiz/topleft.png" alt="" /></div>
        <div className="bottom-right-shape"><img src="/assets/images/quiz/bottomright.png" alt="" /></div>
        <div>
          <Grid justify="center" container xs={12}>
            <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
              {quizDetails.models.length > 1 ? <CPQuizPlayer quizDetails={quizDetails} setShowExitDialog={onCloseClick} quizTitle={getClass(grade)} 
                grade={grade} contestId={contestId} onSubmit={props.onSubmit} /> : null}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  return (
    <div ref={ref}>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        renderView()}
      <YesNoDialog open={showExitDialog} onClose={() => { setShowExitDialog(false); }} onOk={() => { setShowExitDialog(false); onOkClick(); }} message={'Do you really want to exit the contest?'} />
    </div>
  );
}

export default CPQuiz;