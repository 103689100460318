import React from 'react'
import { useState, useEffect } from 'react';
import './clock.styles.scss';

const ClockComponent = (props) => {
  const [hourStyle, setHourStyle] = useState(null);
  const [minuteStyle, setMinuteStyle] = useState(null);
  const [secondStyle, setSecondStyle] = useState(null);
  useEffect(() => {
    let hourDeg = 'rotate(' + ((props.hours + props.minutes / 60) / 12 * 360) + 'deg)';
    let minutesDeg = 'rotate(' + ((props.minutes / 60) * 360) + 'deg)';
    let secondsDeg = 'rotate(' + ((props.seconds / 60) * 360) + 'deg)';
    setHourStyle({
      transform: hourDeg
    });
    setMinuteStyle({
      transform: minutesDeg
    });
    setSecondStyle({
      transform: secondsDeg
    })
  });

  return (
    <div className="watch-body">
      <div id="watch">
        <div className="frame-face"></div>
        <ul className="minute-marks">
          <li></li><li></li><li></li><li></li><li></li><li></li>
          <li></li><li></li><li></li><li></li><li></li><li></li>
          <li></li><li></li><li></li><li></li><li></li><li></li>
          <li></li><li></li><li></li><li></li><li></li><li></li>
          <li></li><li></li><li></li><li></li><li></li><li></li>
          <li></li><li></li><li></li><li></li><li></li><li></li>
          <li></li><li></li><li></li><li></li><li></li><li></li>
          <li></li><li></li><li></li><li></li><li></li><li></li>
        </ul>
        <ul className="digits">
          <li>1</li><li>2</li><li>3</li><li>4</li><li>5</li><li>6</li>
          <li>7</li><li>8</li><li>9</li><li>10</li><li>11</li><li>12</li>
        </ul>
        <div className="hours-hand" style={hourStyle}></div>
        <div className="minutes-hand" style={minuteStyle}></div>
        <div className="seconds-hand" style={secondStyle}></div>
      </div>
    </div>
  )
}

export default ClockComponent;