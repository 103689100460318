import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../app-context';
import Loader from '../../components/loader/loader.component';
import YesNoDialog from '../../components/message-dialog/yes-no-dialog.component';
import QuizPlayer from '../../components/quiz-player/quiz-player.component';
import { getQuestions, saveAllAttempts } from '../../services/question-service';
import './quiz.styles.scss';

const Quiz = (props) => {
  const grade = props.match.params.grade;
  const subject = props.match.params.subject;
  const topic = props.match.params.topic;
  const level = props.match.params.level;
  const [quizDetails, setQuizDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  // const [levelAttempted, setLevelAttempted] = useState(false);
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const ref = useRef();

  useEffect(() => {
    appContext.setShowHeader(false);
    appContext.setShowFooter(false);
    // let elem = document.querySelector('#content');
    if (ref.requestFullscreen) {
      ref.requestFullscreen();
    }
    if (quizDetails.length === 0) {
      loadQuizzes();
    }

    return () => {
      appContext.setShowHeader(true);
      appContext.setShowFooter(true);
    }
  }, []);

  const loadQuizzes = () => {
    loadOnlyQuestion();
  }

  const loadOnlyQuestion = () => {
    getQuestions(grade, subject, topic, level).subscribe({
      next: (response) => {
        setQuizDetails(response.data);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
        history.goBack();
      }});
  }

  const onSubmit = (ansDetails) => {
    // send the quiz answers to server and get the result back.
    // clear the state once result has come back.
    // show the score card and on close take back to Quizzes list page.
    const data = { models: ansDetails }
    setShowLoader(true);
    saveAllAttempts(data).subscribe({
      next: (response) => {
        setShowLoader(false);
        gotoScorecard(response.data.attemptId);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const gotoScorecard = (attemptId) => {
    history.push(`/scorecard/${grade}/${subject}/${topic}/${level}/${attemptId}`);
  }

  const onOkClick = () => {
    history.goBack();
  }

  const onCloseClick = () => {
    setShowExitDialog(true);
  }

  const renderView = () => {
    return (
      <div className="quiz-page-common">
        <div className="top-left-shape"><img src="/assets/images/quiz/topleft.png" alt="" /></div>
        <div className="bottom-right-shape"><img src="/assets/images/quiz/bottomright.png" alt="" /></div>
        <div>
          <Grid justify="center" container xs={12}>
            <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
              {quizDetails.length > 1 ? <QuizPlayer quizDetails={quizDetails} setShowExitDialog={onCloseClick} onSubmit={onSubmit} quizTitle={`Class ${grade} - ${subject} - ${topic} (Level ${level})`} grade={grade} /> : null}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  return (
    <div ref={ref}>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        renderView()}
      <YesNoDialog open={showExitDialog} onClose={() => { setShowExitDialog(false); }} onOk={() => { setShowExitDialog(false); onOkClick(); }} message={'Do you really want to exit the quiz?'} />
    </div>
  );
}

export default Quiz;