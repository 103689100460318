import React, { useState } from 'react';
import AdComponent from '../../../components/google-ads/google-ads.component';
import './point-system.styles.scss'

const PointSystem = () => {
  const [showAds, setShowAds] = useState(false);
  return (
    <div className="point-system-page">
      <div className="main-image">
        <img src="/assets/images/redeem/redeem_main.png" alt="" />
        <div className="page-title">How to earn points?</div>
      </div>
      <div className="points-info">
        <div className="points-info-label">Earn by playing Quizzes</div>
        <div className="points-info-details">
          Only the first attempt (not the retake) is considered for point calculation
          <ul>
            <li>1 point for playing a quiz</li>
            <li>2 points for scoring 60% or above</li>
            <li>5 points for scoring 100%</li>
          </ul>
        </div>
        <div className="points-info-label">Earn by completing zone journeys</div>
        <div className="points-info-details">
          <ul>
            <li>100 points for completing a zone journey (i.e. Jungle zone, Space zone, Ice zone, etc.)</li>
          </ul>
        </div>
        {/* <div className="points-info-label">Earn by winning badges for any grade</div>
        <div className="points-info-details">
          <ul>
            <li>50 points for becoming a Novice</li>
            <li>100 points for becoming a GrandMaster</li>
            <li>150 points for becoming a Ninja</li>
            <li>200 points for becoming a Warrior</li>
          </ul>
        </div> */}
        {/* <div className="points-info-label">Sign-up points for purchasing a subscription package</div>
        <div className="points-info-details">
          <ul>
            <li>0 points for Rabbit package</li>
            <li>100 points for Panda package</li>
            <li>300 points for Tiger package</li>
            <li>500 points for Elephant package</li>
          </ul>
        </div> */}
        <div className="points-info-label">Earn by playing games</div>
        <div className="points-info-details">
          <ul>
            <li>2 points for clearing a level of a game</li>
            <li>30 points for clearing all the levels of a game</li>
          </ul>
        </div>
        <div className="points-info-label">Earn by referring your friends</div>
        <div className="points-info-details">
          <ul>
            <li>50 points to you when your friend signs up with your referral code</li>
            <li>50 points to your friend when he/she signs up with your referral code</li>
          </ul>
        </div>
      </div>

      {showAds ? <><AdComponent></AdComponent></> : null}
    </div>
  );
}

export default PointSystem;