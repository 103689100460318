import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Lottie from "lottie-react";
import yesno from "./animations/yesno.json";
import './yes-no-dialog.styles.scss';

export const YesNoDialog = props => {

  const handleClose = (onClose) => {
    onClose();
  }
  const handleOk = (onOk) => {
    onOk();
  }

  const getBGStyle = () => {
    if (props.isHorizontal) {
      return 'yesNoDialogContainer bg-horizontal';
    } else {
      return 'yesNoDialogContainer bg-vertical';
    }
  }

  return (
    <Dialog {...props}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
      <div className={getBGStyle()}>
        {/* <img src="/assets/images/site/yesno.png" alt="" /> */}
        <div className="message-box-animation"><Lottie className="message-box-top-animation" animationData={yesno} loop={true} /></div>
        <div className="message">{props.message}</div>
        <div className="buttonPanel">
          <Button variant="contained" onClick={() => { handleClose(props.onClose) }} color="secondary">No</Button>
          <Button variant="contained" onClick={() => { handleOk(props.onOk) }} color="primary">Yes</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default YesNoDialog;