import download from 'downloadjs';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getPosterData } from '../../../services/contest-service';
import { isNotEmpty, isNotNull } from '../../../services/util-service';
import Loader from '../../loader/loader.component';
import './admin-contest-posters.styles.scss';

const AdminContestPosters = (props) => {

  const [showLoader, setShowLoader] = useState(true);
  const [posterData, setPosterData] = useState({});
  const [ranks, setRanks] = useState({});

  const gradeRow1 = [1,2,3];
  const gradeRow2 = [4,5];

  useEffect(() => {
    setShowLoader(true);
    getPosterData(props.contestCode).subscribe({
      next: (result) => {
        setPosterData(result.data);
        setRanks(result.data.ranks)
        setShowLoader(false);
      }});
  }, []);

  const downloadPosterPartner = () => {
    htmlToImage.toPng(document.getElementById('print-fb-poster-true'))
      .then(function (dataUrl) {
        download(dataUrl, 'PosterPartner.png');
      });
  }
  const goBack = () => {
    props.back();
  }

  const downloadPosterCudents = () => {
    htmlToImage.toPng(document.getElementById('print-fb-poster-false'))
      .then(function (dataUrl) {
        download(dataUrl, 'PosterCudents.png');
      });
  }

  const getMiddleRow = (rowArray) => {
    return <div className="middle-row">
      {rowArray.map(grade => (
        <div className="class-result">
          <div className="class-title">Class - {grade}</div>
          <div className="result-list">
            {isNotNull(ranks[grade+'1']) && isNotEmpty(ranks[grade+'1'].name) ? <div><img src="/assets/images/contestplatform/gold.png" alt="" /> {ranks[grade+'1'].name}</div> : null}
            {isNotNull(ranks[grade+'2']) && isNotEmpty(ranks[grade+'2'].name) ? <div><img src="/assets/images/contestplatform/silver.png" alt="" /> {ranks[grade+'2'].name}</div> : null}
            {isNotNull(ranks[grade+'3']) && isNotEmpty(ranks[grade+'3'].name) ? <div><img src="/assets/images/contestplatform/bronze.png" alt="" /> {ranks[grade+'3'].name} </div> : null}
          </div>
        </div>
      ))}
     </div>
  }

  const getPoster = (isPartner) => {
    return (<div className="contest-fb-poster" id={'print-fb-poster-' + isPartner} >
      <div className="bg-image"><img src="/assets/images/contestplatform/bg1.jpg" alt="" /></div>
      <div className="top-row">
        <div className="top-image"><img src="/assets/images/contestplatform/poster_cup.png" alt="" /></div>
        <div className="top-info">
          <div className="info-text">Curathon Winners</div>
          <div className="info-subtext">in association with</div>
          <div className="school-logo"><img src={posterData.instituteLogo} alt="" /></div>
          <div className="info-text">{posterData.instituteName}</div>
        </div>
      </div>
      <div className="data-row">
        <div className="data-info">Date: {moment(posterData.contestDate).format('DD-MM-YYYY')}</div>
        <div className="data-info">Total Participants: {posterData.totalParticipants}</div>
      </div>
      {getMiddleRow(gradeRow1)}
      {getMiddleRow(gradeRow2)}
      {
        isPartner ?
          <div className="final-row">
            <div className="cudents-logo"><img src="/assets/images/contestplatform/cudents.png" alt="" /></div>
            <div className="partner-logo"><img src="/assets/images/contestplatform/partner.png" alt="" /></div>
          </div>
          :
          <div className="final-row not-partner">
            <div className="cudents-logo"><img src="/assets/images/contestplatform/cudents.png" alt="" /></div>
          </div>
      }
    </div >
  )}

  return (
    <div className="contest-posters-main">
      {showLoader ? <div className="showLoading"><Loader /></div> : <div>
        <div className="download-button" onClick={goBack}>Back</div>
        <div className="download-button" onClick={downloadPosterPartner}>Download</div>
        {getPoster(true)}
        <br></br>
        <br></br>
        <div className="download-button" onClick={downloadPosterCudents}>Download</div>
        {getPoster(false)}
        <div className="download-button" onClick={goBack}>Back</div>
      </div>}
    </div>
  );
}


export default AdminContestPosters;