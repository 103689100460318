import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { AppContext } from '../../app-context';
import { QUIZ_UNLOCK_MSG, SUBSCRIPTION_END_MSG } from '../../components/constants/constants';
import AdComponent from '../../components/google-ads/google-ads.component';
import LevelCard from '../../components/level-card/level-card.component';
import { Loader } from '../../components/loader/loader.component';
import GuidelineDialog from '../../components/quiz-guideline/guideline-dialog.component';
import { logExceptionService } from '../../services/logging-service';
import { getLevels } from '../../services/question-service';
import './level-listing.styles.scss';

const LevelListing = (props) => {

  const grade = props.match.params.grade;
  const subject = props.match.params.subject;
  const topic = props.match.params.topic;
  const [currentLevel, setCurrentLevel] = useState({});
  const [levelList, setLevelList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showGuidelineDialog, setShowGuidelineDialog] = useState(false);
  const [showAds, setShowAds] = useState(false);
  const history = useHistory();
  const appContext = useContext(AppContext);


  useEffect(() => {
    if (levelList.length === 0) {
      loadLevels();
    }
  }, []);

  const loadLevels = () => {
    getLevels(grade, subject, topic).subscribe({
      next: (response) => {
        setLevelList(response.data);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const onLevelSelect = (level) => {
    setCurrentLevel(level);
    if (level.isAttempted === 1) {
      setShowGuidelineDialog(true);
    } else {
      // const msg = validUserForQuiz(appContext.userData, level.level, level.isAttempted);
      // if (msg !== EMPTY_STRING) {
      //   appContext.setDialogMessage(msg);
      //   logMessage(msg);
      // } else {
        if (level.isLocked === 1) {
          appContext.setDialogMessage(QUIZ_UNLOCK_MSG);
        } else {
          setShowGuidelineDialog(true);
        }
      // }
    }
  }

  // const logMessage = (msg) => {
  //   const logMsg = msg === SUBSCRIPTION_END_MSG ? 'Subscription validity has ended' : 'More than one level';
  //   logExceptionService('QuestionService.getQuestions()', logMsg).subscribe({
  //     next: (response) => {
  //     }, error: (error) => {
  //       console.log(error.message);
  //     }
  //   });
  // }

  const handleStart = () => {
    history.push(`/quiz/${grade}/${subject}/${topic}/${currentLevel.level}`);
  }

  const goToScorecardPage = (level) => {
    history.push(`/attempts/${grade}/${subject}/${topic}/${currentLevel.level}`);
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        <div className="level-listing-page">
          <div className="levelListHeader-listing-page">
            <div className="gradeName">Class {grade}</div>
            <div className="subjectName">{subject} ({topic})</div>
          </div>
          <div className="levelList">
            <Grid container spacing={5}>
              {
                levelList.map((level, idx) => (

                  <Grid key={idx} item xs={6} sm={4} md={4} lg={3} xl={3}>
                    <div onClick={() => { onLevelSelect(level) }}>
                      <LevelCard level={level} />
                    </div>
                  </Grid>
                ))
              }
            </Grid>
          </div>
          <GuidelineDialog open={showGuidelineDialog} level={currentLevel}
            gotoquiz={() => { handleStart() }} gotoscorecard={() => { goToScorecardPage() }} onClose={() => { setShowGuidelineDialog(false) }} />
        </div>
      }

      {showAds ? <><AdComponent></AdComponent></> : null}
    </div>
  );
}

export default withRouter(LevelListing);