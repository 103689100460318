
import { Checkbox, FormControl, FormControlLabel, TextField } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import { useState } from 'react';
import { from } from 'rxjs';
import { isEmpty, isNull, Rif } from '../../../services/util-service';
import { EMPTY_STRING, NO_IMAGE } from '../../constants/constants';
import './admin-question-bank.styles.scss';
const ImageTextfield = (props) => {


    const [binaryImage, setBinaryImage] = useState([]);
    const [selectedImage, setSelectedImage] = useState(NO_IMAGE);

    const onChange = (e) => {
        const files = [...e.target.files]
        const file = files[0];
        if (isNull(file)) {
            setBinaryImage([]);
            setSelectedImage(NO_IMAGE);
            props.handleImageChange(e, []);
        } else {
            from(getBinary(file)).subscribe({next: (binary) => {
                const img = btoa(binary);
                setBinaryImage(img);
                setSelectedImage(`data:image/png;base64,${btoa(binary)}`);
                props.handleImageChange(e, img);
            }});
        }
    }

    const getBinary = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                resolve(event.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsBinaryString(file);
        });
    }

    const getImagePath = () => {
        if (selectedImage === NO_IMAGE) {
            return  props.image || selectedImage;
        } else {
            return selectedImage;
        }
       
    }
    return (
        <div>
            {props.label? 
            <FormControl fullWidth className="formFields halfWidth">
                <TextField id={props.id} label={props.label} value={props.value || EMPTY_STRING} variant="outlined" multiline={props.multiline}
                    rows={props.rows} onChange={props.handleTextChange} name={props.textFieldName}  size="small"/>
            </FormControl>
           : null}
            <FormControl className="formFields">
            {!props.hideURLField ? <TextField label="Image URL" value={props.image || EMPTY_STRING} variant="outlined" 
                    rows={props.rows} onChange={props.handleTextChange} name={props.imagePath}  size="small"/> : null}
                <label><PhotoCameraOutlinedIcon className="file-image" />
                    <input type="file" style={{ display: "none" }} accept=".png"
                        onChange={e => { onChange(e) }} name={props.imageFieldName} />
                </label>
                {isEmpty(props.image) ? null : <DeleteForeverIcon onClick={() => {props.onDeleteImage(props.imageFieldName)}} className="deleteIcon" />}
                <span> <img src={getImagePath()} alt="" /></span>
                <Rif boolean={props.checkName !== undefined}>
                    <FormControlLabel
                        control={<Checkbox checked={props.checked} onChange={props.onSelection} name={props.checkName} />}
                    />
                </Rif>
            </FormControl>
                    
        </div>
    );
}

export default ImageTextfield;
