import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SubtitlesOutlinedIcon from '@material-ui/icons/SubtitlesOutlined';
import React, { useContext, useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { NavLink, useHistory } from 'react-router-dom';
import { AppContext } from '../../app-context';
import AdComponent from '../../components/google-ads/google-ads.component';
import FeatureCard from '../../feature-card/feature-card.component';
import { getStats } from '../../services/dashboard-service';
import { getRedeemItems } from '../../services/redeem-service';
import GameSection from './game-section/game-section.component';
import './home-page.styles.scss';
import QuizSection from './quiz-section/quiz-section.component';
import TopCarousel from './top-carousel-section/top-carousel-section.component';
import VideoSection from './video-section/video-section.component';

const HomePage = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };

  const responsiveRedeem = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisibilityGutter: 40
    }
  };

  const features = [
    {
      image: '/assets/images/homepage/game.webp',
      title: 'Gamified Format',
      info: 'Progressive levels, Stickers, Badges, Leaderboard, Dynamic points and Adventure zones to keep the kids engaged while learning'
    },
    {
      image: '/assets/images/homepage/organized.webp',
      title: 'Organized Content',
      info: 'Class, Subject and Topic wise categorization helps search and attempt the Quizzes easily'
    },
    {
      image: '/assets/images/homepage/performance.webp',
      title: 'Student Dashboard',
      info: 'Graphical and insightful representation of scores to monitor learning development'
    },
    {
      image: '/assets/images/homepage/quiz.webp',
      title: 'Curated Quizzes',
      info: 'Meticulously designed questions by experienced teachers to cover entire gamut of learning'
    },
    {
      image: '/assets/images/homepage/flexible.webp',
      title: 'Flexible Packages',
      info: 'Options to choose from multiple duration based subscription packages'
    },
    {
      image: '/assets/images/homepage/redeem.webp',
      title: 'Points Redemption',
      info: 'Earn and redeem points through a variety of options which inculcate a sense of achievement in the kids'
    }
  ]
  const history = useHistory()
  const appContext = useContext(AppContext);
  const [giftList, setGiftList] = useState(null);
  const [stats, setStats] = useState(null);
  const [showAds, setShowAds] = useState(false);

  const tryFree = () => {
    if (appContext.userData) {
      history.push('/userdashboard');
    } else {
      history.push('/signin');
    }
  }

  useEffect(() => {

    if (giftList === null) {
      fetchGiftList();
    }
    if (stats === null) {
      fetchStats();
    }
  }, []);

  const fetchGiftList = () => {
    getRedeemItems(0).subscribe({
      next: (response) => {
        setGiftList(response["data"]);
      }});
  }

  const fetchStats = () => {
    getStats().subscribe({
      next: (response) => {
        setStats(response.data);
      }});
  }


  const takeFreeAssessment = () => {
    if (appContext.userData) {
      history.push('/assessment');
    } else {
      history.push('/signin');
    }
  }

  const goToContests = () => {
    history.push('/contests');
  }

  return (
    <div className="App homePage">
      {/* <div className="topMainBar">
        <div className="bottom-curve">
          <img src="/assets/images/homepage/bottomCurve.webp" alt="" />
        </div>
        <div className="middleArea">
          <div className="infoImage"><img src="/assets/images/homepage/astro1.webp" alt="" /></div>
          <div className="title">Practice & Revise</div>
          <div className="tagLine">A platform for the curious students to learn while having fun</div>
          <div className="try-buttons">
            {appContext.userData ? null : <div className="tryFree" onClick={tryFree}><span>Try For Free</span></div>}
            <div className="tryFree delay-animation" onClick={takeFreeAssessment}><span>Take Free Assessment</span></div>
            <div className="tryFree delay-animation" onClick={goToContests}><span>Go to contest</span></div>
          </div>
        </div>
        <div className="rocket"><img src="/assets/images/homepage/rocket.webp" alt="" /></div>
        <div className="mainBarBottomArea">
          <div><img className="leftImage" src="/assets/images/homepage/leftpic.webp" alt="" /></div>
          <div><img className="rightImage" src="/assets/images/homepage/rightpic.webp" alt="" /></div>
        </div>
      </div> */}
      {/* <div className="infoSection">
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          keyBoardControl={true}
          transitionDuration={500}
          renderButtonGroupOutside={true}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          slidesToSlide={1}
        >
          {
            features.map(feature => (
              <div><FeatureCard {...feature}></FeatureCard></div>
            ))
          }
        </Carousel>
      </div>
     

      <div className="contest-banner">
        <div className="contest-info">
          CURATHON
          <div>A School Level Contest Series</div>
          <div className="contest-button" onClick={goToContests}>View All Contests</div>
        </div>
        <div className="contest-image"><img src="/assets/images/homepage/contest.png" alt="" /></div>
      </div> */}

      <div className="home-page-top-area">
        <img src="/assets/images/homepage/home_top_image.png" alt="" />
      </div>

      <div className="home-page-content-area">

        <div className="top-carousel-info-area">
          <TopCarousel></TopCarousel>
        </div>

        {/* Listing of pre-school class games */}
        <div className="subject-list">
          <div className="grade-title">
            {/* <img src="/assets/images/homepage/t_games.png" alt="" /> */}
            Pre-School Games
          </div>
          <GameSection></GameSection>
        </div>


        {/* Listing of primary class games */}
        <div className="subject-list">
          <div className="grade-title">
            {/* <img src="/assets/images/homepage/t_games.png" alt="" /> */}
            Primary Games
          </div>
          <GameSection></GameSection>
        </div>

        {/* Listing of grades for quizzes */}
        <div className="subject-list">
          <div className="grade-title">
            {/* <img src="/assets/images/homepage/t_quiz.png" alt="" /> */}
            Quizzes
          </div>
          <QuizSection></QuizSection>
        </div>


        {
          giftList !== null ?
            <div className="showcase-section">
              <div className="showcase-title">Redemption Zone
                <div className="showcase-sub-title">PLAY - LEARN - REDEEM - REPEAT</div>
              </div>
              <Carousel
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsiveRedeem}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                transitionDuration={500}
                renderButtonGroupOutside={false}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                centerMode={true}
              >
                {giftList.map((giftItem, index) => {
                  if (giftItem.priority === 1) {
                    return (
                      <div className="redeem-item">
                        <img src={giftItem.imagePath} alt="" />
                        <div>{giftItem.name}</div>
                        <div className="redeem-item-value">{giftItem.value} points</div>
                      </div>
                    )
                  }
                })}
              </Carousel>

              <div className="showcase-page-link">
                <NavLink to="/redemptionzone" className="view-all-link" exact={true} >View All</NavLink>
              </div>

            </div>
            : null
        }


        {showAds ? <><AdComponent></AdComponent></> : null}

        <div className="subject-list">
          <div className="grade-title">
            {/* <img src="/assets/images/homepage/t_shampy.png" alt="" /> */}
            Rhymes
          </div>
          <VideoSection sectionName="Shampy"></VideoSection>
        </div>

        {showAds ? <><AdComponent></AdComponent></> : null}

        <div className="subject-list">
          <div className="grade-title">
            {/* <img src="/assets/images/homepage/t_sweety.png" alt="" /> */}
            Stories
          </div>
          <div>
            <VideoSection sectionName="SweetyMam"></VideoSection>
          </div>
        </div>
        {/* <div className="sectionHeading">
        <div className="">
          <img src="/assets/images/homepage/quizSection.webp" alt="" />
          Quizzes
        </div>

      </div> */}
        {/* {gradeList.map(grade => (
        <div className="subject-list">
          <div className="grade-title">Class {grade}
            {getMonsterImage({ grade })}
          </div>
          <SubjectList grade={grade} />
          <br /><br />
        </div>
      ))} */}
        {/* {showAds ? <><AdComponent></AdComponent></> : null} */}
      </div>
    </div >
  );
}

export default HomePage;
