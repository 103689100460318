const avatarList = [
  {
    img: "/assets/images/avatars/avatar1.png"
  },
  {
    img: "/assets/images/avatars/avatar2.png"
  },
  {
    img: "/assets/images/avatars/avatar3.png"
  },
  {
    img: "/assets/images/avatars/avatar4.png"
  },
  {
    img: "/assets/images/avatars/avatar5.png"
  },
  {
    img: "/assets/images/avatars/avatar6.png"
  },
  {
    img: "/assets/images/avatars/avatar7.png"
  },
  {
    img: "/assets/images/avatars/avatar8.png"
  },
  {
    img: "/assets/images/avatars/avatar9.png"
  },
  {
    img: "/assets/images/avatars/avatar10.png"
  },
  {
    img: "/assets/images/avatars/avatar11.png"
  },
  {
    img: "/assets/images/avatars/avatar12.png"
  },
  {
    img: "/assets/images/avatars/avatar13.png"
  },
  {
    img: "/assets/images/avatars/avatar14.png"
  },
  {
    img: "/assets/images/avatars/avatar15.png"
  },
  {
    img: "/assets/images/avatars/avatar16.png"
  },
  {
    img: "/assets/images/avatars/avatar17.png"
  },
  {
    img: "/assets/images/avatars/avatar18.png"
  },
  {
    img: "/assets/images/avatars/avatar19.png"
  },
  {
    img: "/assets/images/avatars/avatar20.png"
  },
  {
    img: "/assets/images/avatars/avatar21.png"
  },
  {
    img: "/assets/images/avatars/avatar22.png"
  },
  {
    img: "/assets/images/avatars/avatar23.png"
  },
  {
    img: "/assets/images/avatars/avatar24.png"
  }
];

export default avatarList;