import { Pagination } from "@material-ui/lab";
import Lottie from "lottie-react";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../app-context";
import Loader from "../../components/loader/loader.component";
import { findHistoryByToday } from "../../services/history-service";
import { logService } from "../../services/logging-service";
import calendar from "./animations/calendar.json";
import timeanimation from "./animations/timeanimation.json";
import './history.styles.scss';
function DailyHistory() {

  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const appContext = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (historyData.length === 0) {
      loadTodayHistory();
    }
  }, []);


  const loadTodayHistory = () => {
    setShowLoader(true)
    findHistoryByToday().subscribe({
      next: (response) => {
        setHistoryData(response.data);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
        history.goBack();
      }
    });


    logService("TDTY", new Date()).subscribe({
      next: (response) => {
      }, error: (error) => {
        console.log(error.message);
      }
    });
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (<>{showLoader ? <div className="showLoading">< Loader /></div>
    :
    <div className="history-page">

      <div className="history-top-animation">
        <Lottie className="history-page-top-animation" animationData={calendar} loop={true} />
      </div>
      {historyData && historyData.length > 0 ? <>
        {historyData.map((tdty, idx) => (
          <div key={idx} className="top-section">
            <div className="top-animation"> <Lottie className="top-section-animation" animationData={timeanimation} loop={true} /></div>
            <div className="top-section-image">
              <img src={tdty ? tdty.url : "assets\images\homepage\newhome\newdesign\tdty\Sunday.png"} alt="" />
            </div>
            <div className="top-section-info">
              <div className="top-section-info-title">
                This Day That Year
              </div>
              <div className="top-section-year">{moment(tdty.historyDate).format('Do MMMM yyyy')}</div>
              <div className="top-section-info-text">
                {tdty.description}
              </div>
            </div>
          </div>
        ))}
      </> : null}
      <div className="history-pagination">
        <Pagination count={pageCount} page={page} onChange={handlePageChange} variant="outlined" color="secondary" />
      </div>
    </div>

  }
  </>);
}

export default DailyHistory;