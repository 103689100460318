import React from 'react';
import Button from '@material-ui/core/Button';
import './redeem-card.styles.scss';
const RedeemCard = (props) => {
  return (
    <div className="redeem-gift-card">
      <div className="gift-points"><span>{props.value} pts</span></div>
      <div className="gift-image">
        <img src={props.imagePath} alt="" />
      </div>
      <div className="gift-item-details">
        <div className="gift-name">{props.name}</div>

        {props.userData ? <>{props.isAvailable ?
          <div className="gift-redeem-button">
            <Button variant="contained" onClick={props.redeemGift}>Redeem</Button>
          </div>
          :
          <div className="gift-need-more-points">Need more points</div>
        } </> : null}
      </div>
    </div>
  );
}

export default RedeemCard;