import { PAGE_SIZE } from "../components/constants/constants";
import { get, post, remove, whitelistGet } from "./http-services";

export function findAllVideo(type, pageNumber) {
    return whitelistGet(`/video/page?type=${type}&page=${pageNumber}&size=100&sort=isFree,desc`);
}
export function findAllAdminVideo(type, pageNumber) {
    return get(`/video/admin/page?type=${type}&page=${pageNumber}&size=${PAGE_SIZE}&sort=isFree,desc`);
}

export function findVideoById(id) {
    return get(`/video/byid?id=${id}`);
}

export function saveVideo(videoModel) {
    return post("/video", videoModel);
}

export function deleteVideo(id) {
    return remove(`/video?id=${id}`);
}