
import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { EMPTY_STRING } from "../../constants/constants";
import './date.styles.scss';
const DateComponent = (props) => {

    const handleDateChange = (date) => {
        props.onDateSelection({target: {name: props.name, value:  date ? date.toDate() : EMPTY_STRING}});
      };

    return <MuiPickersUtilsProvider utils={MomentUtils}>
       <KeyboardDatePicker
        value={props.defaultDate}
        onChange={handleDateChange}
        label={props.label}
        format="DD-MM-YYYY"
        inputVariant="outlined"
        size="small"
        fullWidth
      />
  </MuiPickersUtilsProvider>
}

export default DateComponent;
