import { Button, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../app-context';
import { EMPTY_STRING, MANDATORY_FIELD_MSG } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import { getQuizPackage } from '../../services/payment-service';
import { redeemSubscription } from '../../services/subscription-service';
import { isNotEmpty, isNotNull, numberFormat, Rif } from '../../services/util-service';
import Lottie from "lottie-react";
import premium from "./animations/premium.json";
import './quiz-package.styles.scss';
import { getCurrentUser } from '../../services/user-service';

const QuizPackage = () => {

  const [quizPackages, setQuizPackages] = useState([]);
  const [currentPackage, setCurrntPackage] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [couponCode, setCouponCode] = useState(EMPTY_STRING);
  const [formError, setFormError] = useState({});
  const appContext = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (quizPackages.length === 0) {
      loadQuizPackages();
    }
  }, []);

  const loadQuizPackages = () => {
    getQuizPackage().subscribe({
      next: (response) => {
        setQuizPackages(response.data);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const onSubmit = () => {
    if (appContext.userData) {
      if (currentPackage) {
        history.push(`/payment/${currentPackage.rowId}`);
      } else {
        appContext.setDialogMessage('Select a package to buy.');
      }
    } else {
      appContext.setDialogMessage('Please login to buy.');
      history.push('/signin');
    }
  }

  const selectPackage = (packageDetails) => {
    setCurrntPackage(packageDetails);
  }

  const handleChange = (e) => {
    setCouponCode(e.target.value.toUpperCase());
  };

  const applyCoupon = () => {
    if (handleValidation()) {
      setShowLoader(true);
      redeemSubscription(couponCode).subscribe({
        next: (response) => {
          appContext.setDialogMessage(response.data);
          setCouponCode(EMPTY_STRING);
          reloadUserData();
          setShowLoader(false);
        }, error: (error) => {
          appContext.setDialogMessage(error.message);
          setShowLoader(false);
        }
      });
    }
  }

  const reloadUserData = () => {
    getCurrentUser().subscribe({
      next: (response) => {
        if (isNotNull(response) && isNotEmpty(response.displayName)) {
          appContext.setUserData(response);
        }
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!couponCode) {
      formIsValid = false;
      errors["couponCode"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const getValidity = (days) => {
    if (days <= 40) {
      return "1 Month"
    } else if (days <= 100 && days > 40) {
      return "3 Months"
    } else if (days <= 200 && days > 100) {
      return "6 Months"
    } else if (days <= 400 && days > 200) {
      return "1 Year"
    }
  }

  const renderView = () => {
    return (
      <div className="pricingContainer">
        {/* <div className="TitleSmall">Flexible Packages
          <div className="taxInfo"> (Points redeemable only in India)</div>
          <div className="coupon-Info">Cudents Offer - Use code <span>CUDENTS22</span> to get 22% off</div>
        </div> */}
        {/* <div className="coupon-banner"> 
        Introductory promo code is <b>PREOFF50</b>, Please apply code while make payment
        </div> */}
        {/* <div className="pricing-animation">
          <Lottie className="pricing-package-animation" animationData={premium} loop={true} />
        </div> */}
        <div className="subscription">
          <div className="subscription-text">Have a free subscription code?</div>
          <div className="formField">
            <TextField name="couponCode" value={couponCode} onChange={handleChange} label="Code" variant="outlined" size="small" className='subscription-code'/>
            <Button variant="contained" onClick={applyCoupon} color="secondary">
              Apply
            </Button>
            <div className="error-text">{formError['couponCode']}</div>
          </div>
        </div>
        <div className="package-title">
          - Or -
        </div>
        <div className="package-title">
          Choose a package
        </div>
        <div className="packagesList">
          {
            quizPackages.map((packageDetails, idx) => (
              <> {packageDetails.name !== "Ant" ?
                <div key={idx} className="packageCard" onClick={() => { selectPackage(packageDetails) }}>

                  {/* <div className="packageName">{packageDetails.name}</div> */}
                  {/* <div className="packageImage"><img src={packageDetails.imagePath} alt="" /></div> */}
                  <div className="package-validity">{getValidity(packageDetails.validity)}</div>
                  {packageDetails.description !== "" ?
                    <div className="package-savings">
                      {packageDetails.description} <sup>*</sup>
                    </div> : null}
                  <div className="package-image">
                    <img src={packageDetails.imagePath} alt="" />
                  </div>
                  <div className="packageDetails">
                    <div>
                      {packageDetails.price !== packageDetails.discountedPrice ?
                        <div className="packagePrice no-background">

                          {packageDetails.price > 0 && packageDetails.price !== packageDetails.discountedPrice ?
                            <span className="discounted">{numberFormat(packageDetails.price).split('.')[0]}</span>
                            :
                            <span className="discounted no-strike">&nbsp;</span>
                          }
                          <Rif boolean={packageDetails.discountPercentage > 0}>
                            <span className="priceDiscount">{" (" + packageDetails.discountPercentage + "%)"}</span>
                          </Rif>
                        </div>
                        : null}

                      <Rif boolean={packageDetails.discountPercentage > -1}>
                        <div className="packagePrice">
                          <span className="priceValue">{packageDetails.discountPercentage > -1 ? numberFormat(packageDetails.discountedPrice).split('.')[0] : EMPTY_STRING}</span>
                        </div>
                      </Rif>



                      <div className={currentPackage !== null && packageDetails.name === currentPackage.name ? "package-name selected-package-name" : "package-name"}>
                        {packageDetails.name}
                      </div>
                      {/* <div className="package-features-list">
                          {packageDetails.features.map((feature) => (<div className="package-feature">{feature}</div>))}
                        </div> */}
                    </div>
                    <div>

                      {/* {packageDetails.price > 0 ?
                          <Button variant="contained" disabled={packageDetails.discountPercentage === 100}
                            color="secondary" className="buyButton" onClick={() => { onSubmit(packageDetails) }}>
                            Buy Now
                          </Button> : <Button className="freeButton" disabled={true}>Free</Button>} */}
                    </div>
                  </div>
                </div> : null}</>
            ))
          }
        </div>

        <div className="pricing-buy-button" onClick={onSubmit}><span>Continue</span></div>

        <div className="savings-based-on"><sup>*</sup> When compared with the monthly package.</div>
      </div>
    );
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
    </div>
  );
}

export default QuizPackage;