import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { Pagination } from '@material-ui/lab';
import Lottie from "lottie-react";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../app-context';
import { ROLE_ADMIN } from '../../components/constants/constants';
import { CLASS_TYPE_PRESCHOOL, CLASS_TYPE_PRIMARY } from '../../components/games/game-constants';
import Loader from '../../components/loader/loader.component';
import YesNoDialog from '../../components/message-dialog/yes-no-dialog.component';
import { deleteNotification, findAllNotification } from '../../services/notification_service';
import { Rif } from '../../services/util-service';
import notifications from "./animations/notifications.json";
import './notification.styles.scss';

const NotificationPage = (props) => {

  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [notificationMap, updateNotificationList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const appContext = useContext(AppContext);
  const [showMessageDialog, setShowMsgDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [currDeletedId, setCurrDeletedId] = useState('');
  const [currRow, setCurrRow] = useState('');
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    search(page);
  }, [page, currRow]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    findAllNotification(selectedPage - 1).subscribe({
      next: (response) => {
        updateNotificationList(response.data.content);
        setPageCount(response.data.totalPages);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const deleteRow = (id) => {
    setCurrDeletedId(id);
    setMessage('Do you want to delete this question ?');
    setShowMsgDialog(true);

  }

  const onOkClick = () => {
    setShowLoader(true);
    deleteNotification(currDeletedId).subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
        setShowLoader(false);
        setCurrRow(response);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getNotificationImage = (notificationType) => {
    switch (notificationType) {
      case "Games":
        return <img src="/assets/images/notifications/gaming.png" alt="" />
      case "Stories":
        return <img src="/assets/images/notifications/poetry.png" alt="" />
      case "Contest":
        return <img src="/assets/images/notifications/contest.png" alt="" />
      case "News":
        return <img src="/assets/images/notifications/news.png" alt="" />
      case "Toys":
        return <img src="/assets/images/notifications/toys.png" alt="" />
      case "Learn":
        return <img src="/assets/images/notifications/learn.png" alt="" />
      case "Rhymes":
        return <img src="/assets/images/notifications/rhyme.png" alt="" />
      case "Quiz":
        return <img src="/assets/images/notifications/quiz.png" alt="" />
      default:
        return <img src="/assets/images/notifications/notification.png" alt="" />
    }
  }

  const navigateTo = (notificationType) => {
    switch (notificationType) {
      case 'Rhymes':
        history.push('/rhymes');
        break;
      case 'News':
        history.push('/news');
        break;
      case 'Toys':
        history.push('/toys');
        break;
      case 'Stories':
        history.push('/stories');
        break;
      case 'Quiz':
        history.push('/quizzes');
        break;
      case 'Games':
        history.push('/gamesselection');
        break;
      case 'Learn':
        history.push('/learning');
        break;
      case 'GK':
        history.push('/dailygk');
        break;
      case 'Preschool Games':
        history.push(`/gameslisting/${CLASS_TYPE_PRESCHOOL}`);
        break;
      case 'Primary Games':
        history.push(`/gameslisting/${CLASS_TYPE_PRIMARY}`);
        break;
      case 'My Story':
        history.push('/mystorylisting');
        break;
      case 'Riddle':
        history.push('/dailyriddle');
        break;
      case 'TDTY':
        history.push('/dailyhistory');
        break;
      case 'Contest':
        history.push('/contestlist');
        break;
      case 'Account':
        history.push('/userdashboard');
        break;
      case 'Payments':
        history.push('/pricing');
        break;
      case 'Points':
        history.push('/userdashboard/pointsystem');
        break;
      default:
        history.push('/');
        break;
    }
  }


  const renderNotification = () => {
    return (
      <div>
        {/* <Hidden only={['xl', 'lg', 'md']}>
          <div className="notification-title">
            Notifications
          </div>
        </Hidden> */}
        {
          <div className="notification-section">
            <div className="notification-list">{notificationMap.map((notification, idx) => (
              <div key={idx} className="notification-card" onClick={() => { navigateTo(notification.featureName) }}>
                <div className="notification-icon">
                  {getNotificationImage(notification.featureName)}
                </div>
                <div className="notification-info">
                  <div className="heading">{notification.title}
                  </div>
                  <div className="notification-date">{moment(notification.creationDate).format('Do MMM, hh:mm A')}
                  </div>
                  <div className="notification-answers">{notification.description}</div>
                  <Rif boolean={props.role === ROLE_ADMIN}>
                    <DeleteForeverOutlinedIcon onClick={() => { deleteRow(notification.rowId) }} className="delete-icon" />
                  </Rif>
                </div>
              </div>
            ))}</div>
          </div>
        }
      </div>
    );
  }
  const renderView = () => {
    return (
      <div className="notification-container">
        <div className="notifications-animation">
          <Lottie className="notifications-page-animation" animationData={notifications} loop={true} />
        </div>

        <div className="notification-container-content">
          {renderNotification()}
        </div>
        <div className="notification-paging">
          <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
        </div>
        <YesNoDialog open={showMessageDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
      </div>
    );
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
    </div>
  );
}

export default NotificationPage;