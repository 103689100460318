export const ACCESS_TOKEN = 'accessToken';
export const EMPTY_STRING = '';
export const MANDATORY_FIELD_MSG = 'Cannot be empty';
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_TUTOR = 'TUTOR';
export const ROLE_PARTNER = 'PARTNER';
export const ROLE_USER = 'USER';
export const ALL = 'ALL';
export const NA = 'N/A';
export const PAGE_SIZE = 20;
export const SMALL_PAGE_SIZE = 10;
export const REFERRAL_CODE = 'referralCode';
export const CONTEST_CODE = 'contestCode';

export const VIDEO_TYPE_NEWS = 'NEWS';
export const VIDEO_TYPE_RHYME = 'RHYME';
export const VIDEO_TYPE_TOYS = 'TOYS';
export const VIDEO_TYPE_LEARNING = 'LEARNING';
export const VIDEO_TYPE_STORY = 'STORY';
export const VIDEO_TYPE = ['NEWS', 'RHYME', 'TOYS', 'LEARNING', 'STORY'];

export const TREND_USER = "User";
export const TREND_SALES = "Sales";
export const TREND_ACTIVITY = "Activity";
export const TREND_DAILY = "Daily";
export const TREND_MONTHLY = "Monthly";

export const SUPPORT_TYPE = ['General', 'Quiz', 'Games', 'Contest', 'Account', 'Payments', 'Points', 'Redeem', 
'Video', 'This Day That Year', 'GK', 'Riddle', 'Rhymes', 'Learn', 'Stories', 'My Story', 'Toys'];
export const FEATURE_NAME = ['General', 'Quiz', 'Game', 'Primary Games', 'Preschool Games', 'Guess Master', 'Contest', 'Account', 
'Riddle', 'TDTY', 'Payments', 'Points', 'Rhymes', 'Learn', 'Stories', 'My Story', 'Toys', 'News', 'GK'];
export const TXN_STATUS = ['All', 'Created', 'Pending', 'Successful', 'Failed'];
export const REDEEM_STATUS = ['Completed', 'In progress', 'Requested', 'Refunded'];
export const USER_ROLE = ['ADMIN', 'USER', 'TUTOR', 'PARTNER'];
export const CONTEST_TYPE_QUIZ = 'QUIZ';
export const CONTEST_TYPE_ACTIVITY = 'ACTIVITY';
export const CONTEST_TYPE_CUDENTS = 'CUDENTS';

export const NO_IMAGE = '/assets/images/admin/noImage.png';
export const STATUS_ENDED = "Ended";
export const STATUS_STARTED = "Started";
export const STATUS_NOT_STARTED = "Not started";

export const GRADE_LIST = [{name: 'KG 1', value: -2},{name: 'KG 2', value: -1},{name: 'Class 1', value: 1},{name: 'Class 2', value: 2},{name: 'Class 3', value: 3},{name: 'Class 4', value: 4},{name: 'Class 5', value: 5}];

export const SUBSCRIPTION_TYPES = [{name: 'FREE', value: 1}, {name: 'PREMIUM', value: 0}];
export const STATUS_TYPES = [{name: 'ACTIVE', value: 1}, {name: 'INACTIVE', value: 0}];

export const OTHER = 'Other'

export const FREE_PKG = 'FREE';
export const SUBSCRIPTION_END_MSG = 'Your subscription validity has ended. Do you want to renew?';
export const FREE_QUIZ_END_MSG = 'This is a paid quiz. Do you want to subscribe?';
export const FREE_GAME_END_MSG = 'This is a paid game. Do you want to subscribe?';
export const FREE_VIDEO_END_MSG = 'This is a paid content. Do you want to subscribe?';
export const QUIZ_UNLOCK_MSG = 'Complete previous level to unlock this level.';

export const COUNTRIES = [EMPTY_STRING, 'India'];
export const STATES = [
    EMPTY_STRING,
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli and Daman and Diu',
    'Delhi',
    'Jammu and Kashmir',
    'Ladakh',
    'Lakshadweep',
    'Puducherry'

];