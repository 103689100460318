import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../app-context';
import { getAttempt } from '../../services/score-service';
import Loader from '../loader/loader.component';
import ResultDialog from '../result-dialog/result-dialog.component';
import { AllAnswers } from './all-answers/all-answers.component';
import './scorecard.styles.scss';

const ScoreCard = (props) => {

  const grade = props.match.params.grade;
  const subject = props.match.params.subject;
  const topic = props.match.params.topic;
  const level = props.match.params.level;
  const attemptId = props.match.params.attemptId;
  const [quizResult, setQuizResult] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showScorecard, setShowScorecard] = useState(true)
  const [currentAnswer, setCurrentAnswer] = useState({});
  const appData = useContext(AppContext);
  const history = useHistory();


  useEffect(() => {
    if (quizResult.length === 0) {
      loadScorecard();
    }
  }, []);

  const loadScorecard = () => {
    getAttempt(grade, subject, topic, level, attemptId).subscribe({
      next: (response) => {
        setQuizResult(response.data);
        setShowLoader(false);
      }});
  }

  const goToQuiz = () => {
    history.push(`/grade/${grade}`);
  }

  const goToAllAttempt = () => {
    history.push(`/attempts/${grade}/${subject}/${topic}/${level}`);
  }

  const renderStars = () => {
    let totQuestions = parseInt(quizResult.models.length);
    let corAnswers = parseFloat(quizResult.score);// TODO: will change
    if (corAnswers === totQuestions) {
      return (
        <div className="scoreboard-stars">
          <img className="scoreboard-star-on" src="/assets/images/scorecard/star_on.png" alt="" />
          <img className="scoreboard-star-on" src="/assets/images/scorecard/star_on.png" alt="" />
          <img className="scoreboard-star-on" src="/assets/images/scorecard/star_on.png" alt="" />
        </div>
      );
    } else if (corAnswers >= (totQuestions * .8)) {
      return (
        <div className="scoreboard-stars">
          <img className="scoreboard-star-on" src="/assets/images/scorecard/star_on.png" alt="" />
          <img className="scoreboard-star-on" src="/assets/images/scorecard/star_on.png" alt="" />
          <img className="scoreboard-star-off" src="/assets/images/scorecard/star_off.png" alt="" />
        </div>
      );
    } else if (corAnswers >= (totQuestions * .6)) {
      return (
        <div className="scoreboard-stars">
          <img className="scoreboard-star-on" src="/assets/images/scorecard/star_on.png" alt="" />
          <img className="scoreboard-star-off" src="/assets/images/scorecard/star_off.png" alt="" />
          <img className="scoreboard-star-off" src="/assets/images/scorecard/star_off.png" alt="" />
        </div>
      );
    } else {
      return (
        <div className="scoreboard-stars">
          <img className="scoreboard-star-off" src="/assets/images/scorecard/star_off.png" alt="" />
          <img className="scoreboard-star-off" src="/assets/images/scorecard/star_off.png" alt="" />
          <img className="scoreboard-star-off" src="/assets/images/scorecard/star_off.png" alt="" />
        </div>
      );
    }
  }

  const renderScorecard = () => {
    return (
      <div>
        <div className="scorecard-top-area">
          <div className="scorecard-kid-details">
            <div className="scorecard-avatar">
              <img src={appData.userData.profilePicPath} alt="" />
            </div>
            <div className="scorecard-kid-name">{appData.userData.displayName}</div>
          </div>
          <div>
            <div className="scorecard-score">{quizResult.score} / {quizResult.models.length}</div>
          </div>
        </div>
        <div className="scorecard-middle-area">
          <div className="scorecard-result-area">
            <div className="scorecard-result">
              <div className="scorecard-percentage">{quizResult.scorePercentage}%</div>
              <div className="stars">
                {renderStars()}
              </div>
            </div>
          </div>
          <div className="scorecard-subject-details">
            <div>
              <div className="scorecard-label">Subject</div>
              <div className="scorecard-value">Class {grade} - {subject}</div>
            </div>
            <div>
              <div className="scorecard-label">Topic</div>
              <div className="scorecard-value">{topic} (Level {level})</div>
            </div>
          </div>
        </div>
        <hr />
        <div>
          {renderAnswers()}
        </div>
      </div>
    );
  }


  const getClass = (isCorrect) => {
    if (isCorrect === 1) {
      return 'question correctAnswer';
    } else {
      return 'question wrongAnswer';
    }

  }

  const showAnswerDialog = (answer) => {
    setCurrentAnswer(answer);
    setShowAnswer(true);
  }

  const renderAnswers = () => {
    return (
      <div>
        {
          quizResult.models.map((question, index) => (
            <div key={question.questionId} className={getClass(question.isCorrect)}
              onClick={() => { showAnswerDialog(question) }}>{index + 1} </div>
          ))
        }

        <div className="answersTitle">
          <p>(Click the number to view the answer)</p>
        </div>
        <div className="view-all-answers">
          <span onClick={() => { goToQuiz() }}>Go to Quiz</span>
          <span onClick={() => { goToAllAttempt() }}>View All Attempts</span>
          <span onClick={() => { setShowScorecard(false) }}>View All Answers</span>
        </div>
      </div>
    );
  }

  const getScorecardImage = () => {
    let tempPath;
    if (quizResult.scorePercentage === 100) {
      tempPath = '/assets/images/scorecard/score_best_' + (Math.floor(Math.random() * 3) + 1) + '.png';
      return <img src={tempPath} alt="Scorecard image"></img>
    } else if (quizResult.scorePercentage >= 60 && quizResult.scorePercentage <= 99) {
      tempPath = '/assets/images/scorecard/score_good_' + (Math.floor(Math.random() * 3) + 1) + '.png';
      return <img src={tempPath} alt="Scorecard image"></img>
    } else {
      tempPath = '/assets/images/scorecard/score_ok_' + (Math.floor(Math.random() * 3) + 1) + '.png';
      return <img src={tempPath} alt="Scorecard image"></img>
    }
  }

  const renderScorecardView = () => (
    <div className="scorecard-page-common">

      {/* <Grid container justify="center" xs={12}> */}
      {/* {showScorecard ?
          <Grid item xs={6} sm={4} md={5} lg={5} xl={5}>
            <div className="scorecard-image">
              {getScorecardImage()}
            </div>
          </Grid> : null} */}
      {/* <Grid item xs={11} sm={12} md={7} lg={7} xl={7}> */}
      <div>
        {/* <Hidden only={'xs'}>
              {showScorecard ?
                <div className="scorecardContainer">
                  {renderScorecard()}
                </div>
                :
                <AllAnswers quizResult={quizResult} onExit={() => setShowScorecard(true)} buttonLabel={"View Scorecard"} />
              }
            </Hidden> */}
        {/* <Hidden only={['xl', 'lg', 'md', 'sm']}> */}
        {showScorecard ?
          <div className="scorecardContainer scorecardContainerMobile">
            {renderScorecard()}
          </div>
          :
          <AllAnswers quizResult={quizResult} onExit={() => setShowScorecard(true)} buttonLabel={"View Scorecard"} />
        }
        {/* </Hidden> */}
      </div>
      {/* </Grid> */}
      {/* </Grid> */}
      <ResultDialog open={showAnswer} onClose={() => { setShowAnswer(false) }} result={currentAnswer} />
    </div>
  );

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        renderScorecardView()
      }
    </div>
  );

}

export default ScoreCard;