import { PAGE_SIZE } from "../components/constants/constants";
import { get, post } from "./http-services";


export function getInstitutions(pageNumber) {
    return get(`/institution/get?page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function getAllInstitutions() {
    return get(`/institution/get/all`);
}

export function saveInstitution(institutionModel) {
    return post(`/institution/save`, institutionModel);
}