import React from 'react';
import './game-info.styles.scss';
import gameInfo from "../../game-loaders/game_info.json";
import Lottie from "lottie-react";

function GameInfo({ levelData, onLevelSelect, hideInfoBox, exitGame }) {
  return (
    <div className="game-info-container">
      <div className="game-info-close-button">
        <img src="https://www.cudents.com/prod/assets/images/games/game_info_close.webp" alt="" onClick={hideInfoBox} />
      </div>
      <div className="game-info-image"><Lottie className="game-info-image-animation" animationData={gameInfo} loop={true} /></div>
      <div className="game-info-level-data">
        {levelData ? <div className="game-levels-list">
          {
            levelData.map((lvl, index) => (
              <div className={lvl.isLocked ? "game-level" : "game-level level-unlocked"} onClick={() => { onLevelSelect(lvl, index) }}>{lvl.isLocked ? <img src="https://www.cudents.com/prod/assets/images/games/multiplication/lock.webp" alt="" /> : lvl.level}</div>
            ))
          }
        </div> : null}
      </div>
      <div className="game-exit-button"><span onClick={exitGame}>Exit Game</span></div>
    </div>
  );
}

export default GameInfo;