import { forwardRef } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import 'react-phone-number-input/style.css'

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: '#fff'
  }
}))

const PhoneNumber = (props, ref) => {
  const classes = useStyles()

  return (

    <TextField
      {...props}
      InputProps={{
        className: classes.input,
      }}
      inputRef={ref}
      fullWidth
      size='small'
      label='Mobile Number'
      variant='outlined'
      name='phone'
      autoComplete='off' 
    />
  )
}
export default forwardRef(PhoneNumber)