import React, { useState, useEffect, useContext } from "react";
import useInterval from 'react-useinterval';
import Lottie from "lottie-react";
import blast from "../animations/blast.json";
import yellowScooter from "../animations/yellowScooter.json";
import flyingWitch from "../animations/flyingWitch.json";
import './game-console-jump.styles.scss';

function GameConsoleJump(props) {


  const [runnerJumping, setRunnerJumping] = useState(false);
  const [hideObstacle, setHideObstacle] = useState(false);
  const [correctOptions, setCorrectOptions] = useState(null);
  const [wrongOptions, setWrongOptions] = useState(null);
  const [currentOption, setCurrentOption] = useState(null);
  const [currentScore, setCurrentScore] = useState(0);
  const [showBlast, setShowBlast] = useState(false);
  const [showPoint, setShowPoint] = useState(false);
  const [infiniteTimer, setInfiniteTimer] = useState(null);
  const [RightOrWrong, setRightOrWrong] = useState(1);
  const [changeOption, setChangeOption] = useState(true);
  const [previousOptions, setPreviousOptions] = useState([null,null,null])
  let counter;
  let scoreCountedOnce = false;

  useEffect(() => {
    setWrongOptions(props.gameData["wrongOptions"]);
    setCorrectOptions(props.gameData["correctOptions"]);
    setCurrentOption(props.gameData["wrongOptions"][0]);
  }, []);

  useEffect(() => {
    if (props.gameStart) {
      setCurrentScore(0);
      setInfiniteTimer(10);
      setTimeout(() => {
        counter = 0;
      }, 1000);
    }
  }, [props.gameStart])

  const randomOption = () => {
    let temp = Math.round(Math.random());
    if (temp === previousOptions[0] && temp === previousOptions[1] && temp === previousOptions[2])
    {
      if (temp === 0) {
        temp = 1;
      } else {
        temp = 0;
      }
    }else{
      let newArr = [...previousOptions];
      newArr.push(temp);
      newArr = newArr.slice(1);
      setPreviousOptions([...newArr]);
    }

    if (temp == 0) {
      setCurrentOption(correctOptions[Math.floor(Math.random() * correctOptions.length)]);
    } else {
      setCurrentOption(wrongOptions[Math.floor(Math.random() * wrongOptions.length)]);
    }
    setRightOrWrong(temp);
  }
  const jump = () => {
    if (!runnerJumping) {
      setRunnerJumping(true);
      setTimeout(() => {
        setRunnerJumping(false);
      }, 2000);
    }
  }

  const getRunner = (val) => {
    switch (val) {
      case 'yellowScooter':
        return <Lottie className="game-console-jump-runner-props" animationData={yellowScooter} loop={true} />;
      case 'flyingWitch':
        return <Lottie className="game-console-jump-runner-props" animationData={flyingWitch} loop={true} />;
      default:
        return <Lottie className="game-console-jump-runner-props" animationData={yellowScooter} loop={true} />;
    }
  }


  const checkDead = () => {
    let character = document.getElementById("character");
    let block = document.getElementById("block");
    let characterBottom = parseInt(window.getComputedStyle(character).getPropertyValue("bottom"));
    let blockLeft = parseInt(window.getComputedStyle(block).getPropertyValue("left"));
    if (blockLeft < 230 && blockLeft > 150 && characterBottom <= 60) {
      if (RightOrWrong) {
        props.playMusic('lost');
        setShowBlast(true);
        setInfiniteTimer(null);
        character = null;
        block = null;
        setRunnerJumping(false);
        counter = 0;
        props.stopGame(currentScore);
        setTimeout(() => {
          setShowBlast(false);
        }, 500);
      } else {
        if (!scoreCountedOnce) {
          scoreCountedOnce = true;
          props.playMusic('won');
          setShowPoint(true);
          setTimeout(() => {
            setShowPoint(false);
            setCurrentScore(currentScore + 1);
            props.updateCurrentScore(currentScore + 1);
          }, 1000);
          counter = counter + 1;
          props.applaudPlayer();
          setHideObstacle(true);
        }
      }
    }
    if (blockLeft < -50 && changeOption) {
      setChangeOption(false);
      setHideObstacle(false);
      randomOption();
    }
    if (blockLeft > 300 && !changeOption) {
      setChangeOption(true);
      scoreCountedOnce = false;
    }
  }


  useInterval(() => {
    checkDead();
  }, infiniteTimer);

  return (
    <>
      <div className="game-console-jump" onClick={jump}>
        <div className="game-console-jump-background">
          <img className="background-jump-animation" src={props.gameData["backgroundImage"]} alt="" />
        </div>
        {/* <div className="game-console-jump-path">
          &nbsp;
        </div> */}
        {props.gameStart ? <>
          <div className="game-console-jump-current-score">
            <img src={props.gameData.pointImage} alt="" /> {currentScore}
          </div>
          <div className={runnerJumping ? "game-console-jump-runner jump-runner-jumping" : "game-console-jump-runner"} id="character">
            {getRunner(props.gameData.runnerImage)}
            {/* <img src={props.gameData["runnerImage"]} alt="" /> */}
          </div>
          {showPoint ?
            <div className="game-console-jump-runner bonus-point">
              <img src={props.gameData.pointImage} alt="" />
            </div> : null
          }
          <div className={!hideObstacle ? "game-console-jump-obstacles jump-obstacle-animation" : "game-console-jump-obstacles jump-obstacle-animation hide-jump-obstacle"} id="block">
            {currentOption !== null ? <img src={currentOption} alt="" /> : null}
          </div></> :
          showBlast ? <div className="game-console-jump-runner"><Lottie className="game-console-jump-runner-props" animationData={blast} loop={false} /></div> : null
        }
        {!props.gameStart ? <div className="game-console-jump-start-game-container">
          <div className="game-console-jump-scoreboard-image">
            <img src={props.gameData.scoreboard.image} alt="" />
            <div className="game-console-jump-scorecard-info">
              {/* <div><img src={props.gameData.runnerImage} alt="" /></div> */}
              {/* <div className="game-console-jump-scorecard-title">{props.gameData.gameName}</div> */}
              <div className="game-console-jump-game-info">
                <img src={props.gameData.infoImage} alt="" />
              </div>
              <div className="game-console-jump-scores">
                <div>
                  <div>Highest-Score</div>
                  <div>{props.gameData.highScore}</div>
                </div>
                <div>
                  <div>Score</div>
                  <div>{currentScore}</div>
                </div>
              </div>
            </div>
            <div className="game-console-jump-play-button" onClick={props.restartGame}>
              <img src={props.gameData.scoreboard.playButton} alt="" />
            </div>
          </div>
        </div> : null}
      </div>
    </>
  );
}

export default GameConsoleJump;