
import Lottie from "lottie-react";
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../app-context";
import Loader from "../../components/loader/loader.component";
import { EMPTY_STRING, VIDEO_TYPE_STORY } from "../../components/constants/constants";
import { validUserForVideo } from "../../services/validation-util";
import { findAllVideo } from '../../services/video-service';
import sitting from "./animations/sitting.json";
import './stories-listing.styles.scss';
import { isNotEmptyArray } from "../../services/util-service";
import YesNoDialog from "../../components/message-dialog/yes-no-dialog.component";
function StoriesListing() {

  const history = useHistory();
  const appContext = useContext(AppContext);
  const [storyList, setStoryList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [showMsgDialog, setShowMsgDialog] = useState(false);

  useEffect(() => {
    if (storyList.length === 0) {
      loadRhymes();
    }
  }, []);

  const loadRhymes = () => {
    findAllVideo(VIDEO_TYPE_STORY, 0).subscribe({
      next: (response) => {
        setStoryList(response.data.content);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const navigateTo = (video) => {
    if (video.isFree === 1) {
      history.push(`/videoplayer/${VIDEO_TYPE_STORY}/${video.rowId}`);
    } else {
      const msg = validUserForVideo(appContext.userData);
      if (msg === EMPTY_STRING) {
        history.push(`/videoplayer/${VIDEO_TYPE_STORY}/${video.rowId}`);
      } else {
        // appContext.setDialogMessage(msg);
            setMessage(msg);
            setShowMsgDialog(true);
      }
    }

  }

  const onOkClick = () => {
    history.push('/pricing');
  }

  return (<>
    {showLoader ? <div className="showLoading"><Loader /></div> :
      <div className="stories-listing-page">
        {isNotEmptyArray(storyList) ? <>
          <div className="stories-listing-page-top">
            <img src="/assets/images/homepage/newhome/newdesign/curve5.png" alt="" />
          </div>

          <div className="top-animation">
            <Lottie className="stories-page-top-animation" animationData={sitting} loop={true} />
          </div>
          <div className="stories-listing">
            {
              storyList.map((story, idx) => (
                <div key={idx} className="story-card" onClick={() => { navigateTo(story) }}>
                  {story.isFree === 1 ? null :
                      <div className="premium-icon"><img src="/assets/images/homepage/newhome/icons/crown.png" alt="" /></div>
                    }
                  <div className="story-card-image">
                    <img src={story.thumbnailImagePath} alt="" />
                  </div>
                  <div className="story-title">
                    {story.name}
                  </div>
                </div>
              ))
            }
          </div></> : <div className="coming-soon"><img src="/assets/images/site/comingsoon.webp" alt="" /></div>}
      <YesNoDialog open={showMsgDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
      </div>}</>
  );
}

export default StoriesListing;