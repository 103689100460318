
import Lottie from "lottie-react";
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../app-context";
import { EMPTY_STRING, VIDEO_TYPE_NEWS } from "../../components/constants/constants";
import Loader from '../../components/loader/loader.component';
import YesNoDialog from "../../components/message-dialog/yes-no-dialog.component";
import { isNotEmptyArray } from "../../services/util-service";
import { validUserForVideo } from '../../services/validation-util';
import { findAllVideo } from '../../services/video-service';
import reporting from "./animations/reporting.json";
import './news-listing.styles.scss';
function NewsListing() {

  const history = useHistory();
  const appContext = useContext(AppContext);
  const [newsList, setNewsList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [showMsgDialog, setShowMsgDialog] = useState(false);

  useEffect(() => {
    if (newsList.length === 0) {
      loadRhymes();
    }
  }, []);

  const loadRhymes = () => {
    findAllVideo(VIDEO_TYPE_NEWS, 0).subscribe({
      next: (response) => {
        setNewsList(response.data.content);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const navigateTo = (video) => {
    if (video.isFree === 1) {
      history.push(`/videoplayer/${VIDEO_TYPE_NEWS}/${video.rowId}`);
    } else {
      const msg = validUserForVideo(appContext.userData);
      if (msg === EMPTY_STRING) {
        history.push(`/videoplayer/${VIDEO_TYPE_NEWS}/${video.rowId}`);
      } else {
        // appContext.setDialogMessage(msg);
        setMessage(msg);
        setShowMsgDialog(true);
      }
    }

  }

  const onOkClick = () => {
    history.push('/pricing');
  }

  return (
    <>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        <div className="news-listing-page">
          {isNotEmptyArray(newsList) ? <>
            <div className="news-listing-page-top">
              <img src="/assets/images/homepage/newhome/newdesign/curve8.png" alt="" />
            </div>

            <div className="top-animation">
              <Lottie className="news-page-top-animation" animationData={reporting} loop={true} />
            </div>
            <div className="news-listing">
              {
                newsList.map((news, idx) => (
                  <div key={idx} className="news-card" onClick={() => { navigateTo(news) }}>
                    {news.isFree === 1 ? null :
                      <div className="premium-icon"><img src="/assets/images/homepage/newhome/icons/crown.png" alt="" /></div>
                    }
                    <div className="news-card-image">
                      <img src={news.thumbnailImagePath} alt="" />
                    </div>
                    <div className="news-title">
                      {news.name}
                    </div>
                  </div>
                ))
              }
            </div></> : <div className="coming-soon"><img src="/assets/images/site/comingsoon.webp" alt="" /></div>}
      <YesNoDialog open={showMsgDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
        </div>
      }
    </>
  );
}

export default NewsListing;