import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from "react";
import { isNotEmpty } from '../../../../services/util-service';
import Timer from "../../timer/timer.component";
import './save-the-earth.styles.scss';
const SaveTheEarthComponent = (props) => {

  const [optionsList, setOptionsList] = useState(props.questionDetails.options);
  const [audioExplode, setAudioExplode] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/savetheearth/wrong_answer.mp3'));
  const [audioRight, setAudioRight] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/savetheearth/right_answer.mp3'));
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [timeOver, setTimeOver] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);

  const resetGame = () => {
    setShowQuestion(false);
    setOptionsList(null);
    setCorrectAnswer(false);
    setWrongAnswer(false);
    setTimeOver(false);
    setTimeout(() => {
      setOptionsList(props.questionDetails.options);
      setShowQuestion(true);
    }, 500);
  }

  useEffect(() => {
    resetGame();
  }, [props.questionDetails]);

  const wrongAnswerSelected = () => {
    setWrongAnswer(true);
    if (props.music) {
      audioExplode.play();
    }
  }

  const timeOut = () => {
    setOptionsList(null);
    setShowQuestion(false);
    setTimeOver(true);
    if (props.music) {
      audioExplode.play();
    }
  }

  const correctAnswerSelected = () => {
    setCorrectAnswer(true);
    if (props.music) {
      audioRight.play();
    }
    props.correctAnswer();
  }

  const answerSelected = (ans) => {
    console.log("Answer selected is: ", ans);
    setShowQuestion(false);
    setOptionsList(null);
    // Verify the answer
    if (ans.isCorrect === 1) {
      correctAnswerSelected();
    } else {
      wrongAnswerSelected();
    }
  }

  const playLevelAgain = () => {
    if (props.currentQuestion === 1) {
      resetGame();
    } else {
      props.playAgain();
    }
  }

  const renderView = () => {
    return (
      <div className="save-the-earth-game-box">
        <div className="save-the-earth-game-background">
          <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/game_bg.webp" alt="" />
        </div>

        {wrongAnswer ? <div className="answer-out"><img src="https://www.cudents.com/prod/assets/images/games/savetheearth/wa.webp" alt="" /><div><div className="game-button" onClick={playLevelAgain}>Play Again</div></div></div> : null}
        {correctAnswer ?
          <div className="answer-out">
            <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/ca.webp" alt="" />
            {props.nextLevelButton ? <div><div className="game-button" onClick={props.playNextLevel}>Play Level {props.currentLevel + 1}</div></div> : null}
            {props.serverMessage ? <div className="bonus-message">{props.serverMessage}</div> : null}
          </div> : null}
        {timeOver ? <div className="answer-out"><img src="https://www.cudents.com/prod/assets/images/games/savetheearth/timeover.webp" alt="" /><div><div className="game-button" onClick={playLevelAgain}>Play Again</div></div></div> : null}

          <>
            {showQuestion && !(wrongAnswer || correctAnswer || timeOver) ?
              <div className="game-timer-section">
                <Timer timeOut={timeOut} initialSeconds={30}></Timer>
              </div> : null
            }
            {showQuestion ?
              <div className="game-question-section">
                <span className="game-question">{props.questionDetails.questionText}</span>
              </div> : null}
            {showQuestion ?

              <div className="game-level-number">Level - {props.currentLevel} ({props.currentQuestion}/{props.totalQuestions})</div>
              : null}

            <div className="meteor-container">

              {wrongAnswer ? <>
                <div className="blast-1"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/blast.webp" alt="" /> </div>
                <div className="blast-2"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/blast2.webp" alt="" /> </div>
                <div className="blast-3"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/blast.webp" alt="" /> </div>
                <div className="blast-4"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/blast4.webp" alt="" /> </div>
              </> : null}
              {timeOver ? <>
                <div className="blast-1"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/blast.webp" alt="" /> </div>
                <div className="blast-2"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/blast2.webp" alt="" /> </div>
                <div className="blast-3"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/blast.webp" alt="" /> </div>
                <div className="blast-4"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/blast4.webp" alt="" /> </div>
              </> : null}

              {optionsList ?
                <>
                  <div className="game-car-1"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/car1.webp" alt="" /> </div>
                  <div className="game-car-2"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/car2.webp" alt="" /> </div>
                  <div className="game-bike-1"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/bike1.webp" alt="" /> </div>
                  <div className="game-bike-2"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/bike2.webp" alt="" /> </div>
                  <div className="game-aero-1"> <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/aeroplane.webp" alt="" /> </div>
                  <Grid container xs={12}>
                    {
                      optionsList.map(option => (
                        <>
                          {isNotEmpty(option.text) ?
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                              <div className={"meteor meteor-delay-" + (Math.floor(Math.random() * 4) + 1)} onClick={() => { answerSelected(option) }} >
                                <img src="https://www.cudents.com/prod/assets/images/games/savetheearth/meteor.webp" alt="" />
                                <div className="meteor-value" >{option.text}</div>
                              </div>
                            </Grid>
                            : null}
                        </>
                      ))
                    }
                  </Grid>
                </>
                : null
              }
            </div>
          </>
      </div>
    );
  }

  return (
    <>
      {renderView()}
    </>


  );
}

export default SaveTheEarthComponent;