import { get, post } from "./http-services";

export function getContestQuestions(contestId, grade) {
    return get(`/contest/quiz/question/get?contestId=${contestId}&grade=${grade}`);
}

export function saveContestQuestions(contestQuestions) {
    return post(`/contest/quiz/question/save`, contestQuestions);
}

export function getQuizContestGrades(contestId) {
    return get(`/contest/quiz/getgrades?contestId=${contestId}`);
}

export function saveContestAttempt(contestAttemptModel, isSubmitted) {
    return post(`/contest/quiz/attempt?isSubmitted=${isSubmitted}`, contestAttemptModel);
}

export function getContestResult(contestId) {
    return get(`/contest/quiz/result?contestId=${contestId}`);
}

export function startQuizContest(contestId) {
    return post(`/contest/quiz/start?contestId=${contestId}`, null);
}

export function completeContestAdmin(contestId) {
    return post(`/contest/quiz/complete?contestId=${contestId}`, null);
}
