import './badges.styles.scss';

function UserBadges() {
  return (
    <div className="badges-page">
      Badges
    </div>
  );
}

export default UserBadges;