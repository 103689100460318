import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../app-context';
import Support from '../../../pages/support/support.component';
import { saveSupport } from '../../../services/support-service';
import { EMPTY_STRING, SUPPORT_TYPE } from '../../constants/constants';
import './admin-support.styles.scss';

const AdminSupport = (props) => {
  const initData = {
    type: 'General', question: EMPTY_STRING, description: EMPTY_STRING
  };
  const [formData, updateFormData] = useState(initData);
  const [currRow, setCurrRow] = useState(initData);
  const appContext = useContext(AppContext);

  const savedata = (event) => {
    event.preventDefault();
    saveSupport(formData).subscribe({
      next: (response) => {
        updateFormData(initData);
        setCurrRow(response.data);
        appContext.setSuccessMessage(response.message);
      }});
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const getFormPanel = () => {
    return (
      <form noValidate autoComplete="off" onSubmit={savedata}>
        <div className="controlBox">
          <div className="supportControl" fullWidth>
            <TextField name="type" value={formData.type} select label="Type" variant="outlined" size="small" fullWidth
              onChange={handleChange}>
              {SUPPORT_TYPE.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="supportControl">
            <TextField name="question" label="Question" required multiline rows={3} variant="outlined" size="small" fullWidth
              value={formData.question} onChange={handleChange}
            />
          </div>
          <div className="supportControl">
            <TextField name="description" label="Description" required multiline rows={3} variant="outlined" size="small" fullWidth
              value={formData.description} onChange={handleChange}
            />
          </div>
          <div className="supportControl sendButton">
            <Button type="submit" variant="contained" color="secondary">
              Save
            </Button>
          </div>
        </div>
      </form>
    );
  }


  return (
    <div className="support-main">
      <div className="support-title">Support</div>
      {getFormPanel()}
      <Support role="admin" row={currRow} />
    </div>
  );
}

export default AdminSupport;