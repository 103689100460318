import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useHistory } from 'react-router-dom';
import { getGrades } from '../../../services/category-service';
import { findAllGuessMaster } from '../../../services/guess-master-service';
import './guess-master.styles.scss';

const GuessMasterSection = (props) => {

  const responsiveQuizzes = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisibilityGutter: 40
    }
  };
  const history = useHistory();
  const [guessMasterList, setGuessMasterList] = useState([]);


  useEffect(() => {
    if (guessMasterList) {
      loadGuessMaster();
    }
  }, []);


  const loadGuessMaster = () => {
    findAllGuessMaster().subscribe({
      next: (response) => {
        setGuessMasterList(response.data);
      }, error : (error ) => {
        console.log(error);
      }});
  }
  
  const renderGrade = () => {
    return <Carousel 
    swipeable={true}
    draggable={false}
    showDots={false}
    responsive={responsiveQuizzes}
    ssr={true} // means to render carousel on server-side.
    infinite={false}
    keyBoardControl={true}
    transitionDuration={500}
    renderButtonGroupOutside={false}
    removeArrowOnDeviceType={["tablet", "mobile"]}
    containerClass="carousel-container"
    dotListClass="custom-dot-list-style"
    centerMode={true}
    >
      {guessMasterList.map((guessMaster) => (
        <div className="guess-master-card">
          <div className="guess-master-image">   <a href={process.env.REACT_APP_PLAYSTORE_URL}>
          <img src={guessMaster.topicImagePath} alt="" /></a></div>
          <div className="guess-master-name">{guessMaster.topic}</div>
        </div>
      ))}
    </Carousel>
  };

  return (
    <div className="guess-master-listing">
      {renderGrade()}
    </div>
  );
}

export default GuessMasterSection;