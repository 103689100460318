import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../../app-context';
import { findAllActivitiesByContestId, saveAdminActivity } from '../../../services/activity-contest-service';
import { getContestsAdmin } from '../../../services/contest-service';
import { isNotEmptyArray } from '../../../services/util-service';
import { CONTEST_TYPE_ACTIVITY, EMPTY_STRING, MANDATORY_FIELD_MSG } from '../../constants/constants';
import Loader from '../../loader/loader.component';
import './admin-activity.styles.scss';
import ImageViewerDialog from '../../image-viewer/image-viewer';

const AdminActivity = (props) => {
  const grade = props.grade;
  const code = props.code;
  const contestId = props.contestId;
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [activityList, setActivityList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState(null);
  const [contests, setContests] = useState([]);
  const [formError, setFormError] = useState({});
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [imagePath, setImagePath] = useState(EMPTY_STRING);
  const ref = useRef();

  useEffect(() => {
    if (contests.length === 0) {
      loadActiveContests();
    }
  }, []);

  const loadActiveContests = () => {
    getContestsAdmin(CONTEST_TYPE_ACTIVITY).subscribe({
      next: (response) => {
        setContests(response.data);
        if(isNotEmptyArray(response.data)){
          setSearchText(response.data[0]);
        }
      }
    });
  }

  useEffect(() => {
    if (contests.length > 0) {
      loadActivities(page);
    }
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const loadActivities = (page) => {
    if(handleValidation()){
      setShowLoader(true);
      findAllActivitiesByContestId(searchText.contestId, page - 1).subscribe({
        next: (response) => {
          setActivityList(response.data.content);
          setPageCount(response.data.totalPages);
          setTotalCount(response.data.totalElements);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setDialogMessage(error.message);
        }
      });
    }
  }

  const saveScore = (activity) => {
    setShowLoader(true);
    saveAdminActivity(activity).subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
        appContext.setErrorMessage(error.message);
      }
    });
  }
  
  const handleEdit = (activity) => {
    const newList = activityList.map((item) => {
      if (item.rowId === activity.rowId) {
        const updatedItem = {
          ...item,
          score: activity.score,
        };
        return updatedItem;
      }

      return item;
    });
    setActivityList(newList);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!searchText) {
      formIsValid = false;
      errors["searchText"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }
  
  const openImageViewer = (contentPath) => {
    setImagePath(contentPath);
    setShowImageViewer(true);
  }

  const renderView = () => {
    return (
      <div className="activity-list-page">
        <div className="activity-search">
          <div className="formField">
            <TextField name="searchText" value={searchText || EMPTY_STRING} onChange={handleSearchChange} select label="Search By Contest" variant="outlined" size="small">
              {contests.map((contest) => (
                <MenuItem key={contest.contestId} value={contest}>
                  {contest.institutionName} - {contest.contestCode} ({contest.contestId})
                </MenuItem>
              ))}
            </TextField>
            <Button variant="contained" onClick={loadActivities} color="secondary">
              Search
            </Button>
            <span className='search-total'>Total : {totalCount}</span>
            <div className="error-text">{formError['searchText']}</div>
          </div>
        </div>
        <Grid container spacing={1}>
          {activityList.map((activity) => (
            <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
              <img className="uploaded-image" src={activity.contentPath} alt="" onClick={(e) => {openImageViewer(activity.contentPath)}}/>
              <div className="control">
                <TextField name="score" value={activity.score} onChange={(e) => { activity.score = +e.target.value; handleEdit(activity); }}>
                </TextField>
                <span className="formField sendButton">
                  <Button type="submit" variant="contained" color="secondary" onClick={(e) => { saveScore(activity) }}>
                    save
                  </Button>
                </span>
              </div>
            </Grid>))}
        </Grid>
      <ImageViewerDialog open={showImageViewer} onClose={() => { setShowImageViewer(false) }} imagePath={imagePath}></ImageViewerDialog>
        <Pagination className="pagination" count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }
  return (
    <div ref={ref}>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        renderView()}
    </div>
  );
}

export default AdminActivity;