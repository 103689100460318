import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useState } from 'react';
import { a11yProps, TabPanel, useStyles } from '../../../services/tab-util-service.jsx';
import UserTransaction from "../admin-user-transaction/admin-user-transaction.component.jsx";
import LoggingException from '../exceptions/exceptions.component.jsx';
import GameAttempt from '../game-attempt/game-attempt.component';
import Logging from '../logging/logging.component.jsx';
import ScoreReport from '../score-report/score-report.component';
import { Accordion, AccordionDetails, AccordionSummary, Hidden, Dialog } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './user-details.styles.scss';

const UserDetails = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderView = () => {
    return (
      <div className={classes.root}>
        <Tabs
          className="tab-header"
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab label="User Details" {...a11yProps(0)} />
          <Tab label="Quiz Report" {...a11yProps(1)} />
          <Tab label="Game Attempt" {...a11yProps(2)} />
          <Tab label="Logging Report" {...a11yProps(3)} />
          <Tab label="Exception Report" {...a11yProps(4)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className="dashboard-container-box">
            <UserTransaction user={props.user}></UserTransaction>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="dashboard-container-box">
            <ScoreReport userId={props.user.rowId}></ScoreReport>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="dashboard-container-box">
            <GameAttempt userId={props.user.rowId}></GameAttempt>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="dashboard-container-box">
            <Logging userId={props.user.rowId}></Logging>
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className="dashboard-container-box">
            <LoggingException userId={props.user.rowId}></LoggingException>
          </div>
        </TabPanel>
      </div>
    );
  }


  const renderMobileView = () => {
    return <>
      <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">User Details</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <UserTransaction user={props.user}></UserTransaction>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Quiz Report</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <ScoreReport userId={props.user.rowId}></ScoreReport>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Game Attempt</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <GameAttempt userId={props.user.rowId}></GameAttempt>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Logging Report</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <Logging userId={props.user.rowId}></Logging>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Exception Report</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <LoggingException userId={props.user.rowId}></LoggingException>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  }
  const renderMain = () => {
    return <>
      <Hidden only={['xs']}>
        <Dialog fullWidth maxWidth={false} {...props}>
          <div className="user-details-container">
            <div className="user-details-title">User Details</div>
            {renderView()}
          </div>
        </Dialog>
      </Hidden>
      <Hidden only={['xl', 'lg', 'md', 'sm']}>
        <Dialog {...props}>
          <div className="user-details-container">
            <div className="user-details-title">User Details</div>
            {renderMobileView()}
          </div>
        </Dialog>
      </Hidden>
    </>
  }

  return (
    <>{ renderMain() }</>
  );
}
export default UserDetails;