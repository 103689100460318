import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppContext } from '../../app-context';
import DashboardSidebar from '../../components/dashboard/dashboard-sidebar/dashboard-sidebar.component';
import ChangePasswordComponent from '../../components/login/change-password.component';
import Grade from '../home-page/grade/grade.component';
import PricingComponent from '../invoice/invoice.component';
import LevelListing from '../level-listing/level-listing.component';
import MyTransactions from '../my-transactions/my-transactions.component';
import OrderList from '../order-list/order-list.component';
import StudentProfile from '../student-profile/student-profile.component';
import Dashboard from './dashboard/dashboard-component';
import PointSystem from './point-system/point-system.component';
import ReferralComponent from './referral/referral.component';
import './user-dashboard.styles.scss';
import UserProfile from './user-profile/user-profile.component';


const UserDashboard = () => {

  const [drawerOpen, setDrawerOpen] = useState(false);
  const appContext = useContext(AppContext);
  const [wish, setWish] = useState('');
  const [wishImage, setWishImage] = useState(null);
  const toggleDrawer = (drawerStatus) => {
    setDrawerOpen(drawerStatus);
  }
  useEffect(() => {
    setWishDetails();
    return () => {
      setWish(null);
      setWishImage(null);
    }
  }, [])

  const setWishDetails = () => {
    const dt = new Date();
    const hour = dt.getHours();
    if (hour < 4) {
      setWish('Time to go to bed');
      setWishImage('https://www.cudents.com/prod/assets/images/userdashboard/night.png');
    } else if (hour < 12) {
      setWish('Good Morning');
      setWishImage('https://www.cudents.com/prod/assets/images/userdashboard/morning.png');
    } else if (hour < 16) {
      setWish('Good Afternoon')
      setWishImage('https://www.cudents.com/prod/assets/images/userdashboard/afternoon.png');
    } else if (hour < 21) {
      setWish('Good Evening');
      setWishImage('https://www.cudents.com/prod/assets/images/userdashboard/evening.png');
    } else {
      setWish('Time to go to bed');
      setWishImage('https://www.cudents.com/prod/assets/images/userdashboard/night.png');
    }
  }

  return (

    <div className="user-dashboard-container">

      <div className="floating-dashboard-button" onClick={() => { toggleDrawer(true) }}>
        {/* <MenuOutlinedIcon /> */}
        <img src="https://www.cudents.com/prod/assets/images/userdashboard/menu_icon.png" alt="" />
      </div>



      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={() => { toggleDrawer(false) }}
      >
        <DashboardSidebar />
      </Drawer>
      <Grid justify="center" container xs={12}>
        {/* <Hidden only={['xs', 'sm']}>
          <Grid item xs={2} sm={2} md={3} lg={2} xl={2}>
            <DashboardSidebar />
          </Grid>
        </Hidden>
        <Hidden only={['md', 'lg', 'xl']}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
            <DashboardSidebar orientation="horizontal" />
          </Grid>
        </Hidden> */}


        <div className="dashboard-top-user-section">

          <div className="dashboard-top-user-section-info">
            <div className="dashboard-top-user-section-image">
              <img src={appContext.userData.profilePicPath} alt="" />
            </div>
            <div className="dashboard-top-user-section-wish">{wish}</div>
            <div className="dashboard-top-user-section-username">{appContext.userData.displayName}</div>
            <div className="dashboard-top-user-section-usergrade">Class-{appContext.userData.grade}</div>
          </div>
        </div>

        <Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
          <div className="user-dashboard-right-area">
            <Switch>
              <Route exact path='/userdashboard' component={Dashboard} />
              <Route exact path='/userdashboard/grade/:grade' component={Grade} />
              <Route path="/userdashboard/levels/:grade/:subject/:topic" component={LevelListing} />
              <Route exact path='/userdashboard/userprofile' component={UserProfile} />
              <Route exact path='/userdashboard/editprofile' component={StudentProfile} />
              <Route exact path='/userdashboard/transactions' component={MyTransactions} />
              <Route exact path='/userdashboard/orders' component={OrderList} />
              <Route exact path='/userdashboard/invoice/:orderId' component={PricingComponent} />
              <Route exact path='/userdashboard/pointsystem' component={PointSystem} />
              <Route exact path='/userdashboard/changepassword' component={ChangePasswordComponent} />
              <Route exact path='/userdashboard/referral' component={ReferralComponent} />
            </Switch>

          </div>
        </Grid>
      </Grid>
    </div >
  );
}

export default UserDashboard;