import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { DialogTitle } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import './video-player.styles.scss';
import { logService } from '../../services/logging-service';

export const VideoPlayerComponent = props => {
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);

  const onVideoClicked = (show) => {
    setShowVideoPlayer(show);
    if (show) {
      logService(props.sectionName, props.videoTitle).subscribe({
        next: (response) => {
        }, error : (error ) => {
          console.log(error.message);
        }});
    }
  }

  return (
    <div className="video-player">
      {/* <div className="dialog-icon">
        <HelpOutlineOutlinedIcon style={{ fontSize: '1.75rem' }} onClick={() => { onVideoClicked(true) }} />
      </div> */}
      <div className="video-card" onClick={() => { onVideoClicked(true) }} >
        <div className="video-image"><img src={props.videoImage} alt="" /></div>
        <div className="video-play-button"><img src="/assets/images/homepage/play.png" alt="" /></div>
        <div className="video-name">{props.videoTitle}</div>
      </div>

      <Hidden only={['xs', 'sm']}>
        <Dialog open={showVideoPlayer} maxWidth={840} onClose={() => { onVideoClicked(false) }}>
          {/* <DialogTitle id="info-dialog-title">{props.videoTitle}</DialogTitle> */}
          <iframe width="840" height="473" src={props.videoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <div className="info-dialog-close-button">
            <Button variant="contained" onClick={() => { onVideoClicked(false) }} color="primary">Close</Button>
          </div>
        </Dialog>
      </Hidden>
      <Hidden only={['xl', 'lg', 'md', 'xs']}>
        <Dialog open={showVideoPlayer} maxWidth={500} onClose={() => { onVideoClicked(false) }}>
          {/* <DialogTitle id="info-dialog-title">{props.videoTitle}</DialogTitle> */}
          <iframe width="500" height="281" src={props.videoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <div className="info-dialog-close-button">
            <Button variant="contained" onClick={() => { onVideoClicked(false) }} color="primary">Close</Button>
          </div>
        </Dialog>
      </Hidden>
      <Hidden only={['xl', 'lg', 'md', 'sm']}>
        <Dialog open={showVideoPlayer} maxWidth={320} onClose={() => { onVideoClicked(false) }}>
          {/* <DialogTitle id="info-dialog-title">{props.videoTitle}</DialogTitle> */}
          <iframe width="320" height="180" src={props.videoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <div className="info-dialog-close-button">
            <Button variant="contained" onClick={() => { onVideoClicked(false) }} color="primary">Close</Button>
          </div>
        </Dialog>
      </Hidden>
    </div>
  );
}




export default VideoPlayerComponent;