import React from 'react'
import Lottie from "lottie-react";
import helicopter from "./animations/helicopter.json";
import './loader.styles.scss'

export const Loader = () => (
  <div className="loading">
    <Lottie className="loader-animation" animationData={helicopter} loop={true} />
  </div>
);

export default Loader