import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import Lottie from "lottie-react";
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../app-context';
import { isEmpty, isNotEmpty, replaceUnderscore, Rif, speakTheText } from '../../services/util-service';
import confused from "./animations/confused.json";
import './quiz-player.styles.scss';

const QuizPlayer = (props) => {
  const [quizDetails, setQuizDetails] = useState(null);
  const [ansDetails, setAnsDetails] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(0);
  const history = useHistory();
  const appData = useContext(AppContext);

  useEffect(() => {
    if (quizDetails === null) {
      setQuizDetails(props.quizDetails);
      initializeAnswerList(props.quizDetails);
    }
  }, []);

  const initializeAnswerList = (qData) => {
    const tempAns = [];
    qData.map(ques => {
      tempAns.push({
        questionId: ques.rowId,
        isOption1Marked: 0,
        isOption2Marked: 0,
        isOption3Marked: 0,
        isOption4Marked: 0,
        isOption5Marked: 0,
        answerSelected: false
      });
    });
    setAnsDetails(tempAns);
  }

  const onPrevious = () => {
    setCurrentPosition(currentPosition - 1);
  }

  const onNext = () => {
    setCurrentPosition(currentPosition + 1);
  }


  const onAnswerSelection = (ansSelected) => {
    const tempAns = [...ansDetails];
    const currUser = appData.userData;
    const newData = {
      ...tempAns[currentPosition],
      userId: currUser.rowId,
      isOption1Marked: ansSelected === 'isOption1Marked' ? 1 : 0,
      isOption2Marked: ansSelected === 'isOption2Marked' ? 1 : 0,
      isOption3Marked: ansSelected === 'isOption3Marked' ? 1 : 0,
      isOption4Marked: ansSelected === 'isOption4Marked' ? 1 : 0,
      isOption5Marked: ansSelected === 'isOption5Marked' ? 1 : 0,
      answerSelected: true
    };
    tempAns[currentPosition] = newData;
    setAnsDetails(tempAns);
    setQuizDetails(quizDetails);
  }


  const previousButton = () => {
    if ((currentPosition) !== 0) {
      return (
        <span className="next-prev-button">
          <img src="/assets/images/quiz/previous.png" onClick={onPrevious} alt="" />
        </span>
      );
    }
  }

  const submitNextButton = () => {
    if ((currentPosition + 1) !== ansDetails.length) {
      return (
        <span className="next-prev-button">
          {ansDetails[currentPosition].answerSelected ?
            <img src="/assets/images/quiz/next.png" onClick={onNext} alt="" />
            :
            <img src="/assets/images/quiz/next_disabled.png" alt="" />
          }
        </span>
      );
    } else {
      return (
        <span className="next-prev-button">
          {ansDetails[currentPosition].answerSelected ?
            <img src="/assets/images/quiz/submit.png" onClick={() => { props.onSubmit(ansDetails) }} alt="" />
            :
            <img src="/assets/images/quiz/submit_disabled.png" alt="" />
          }
        </span>
      );
    }
  }

  const getAnswerClass = (answerOption) => {
    // Return the color class for answer: Purple if selected, Green for correct
    let answerClass = 'answer';
    if (ansDetails[currentPosition][answerOption] === 1) {
      answerClass = 'selectedAnswer';
    }
    return answerClass;
  }

  const renderQuiz = () => {
    return (
      <div className="quiz-space">
        <div className="quiz-info">
          <div className="quiz-subject-details">
            {/* <div>Class {grade} - {subject} - </div>
            <div>{topic} (Level {level})</div> */}
            <div>{props.quizTitle}</div>
          </div>
          <div className="questionNumber">{currentPosition + 1} / {quizDetails.length}</div>
          {props.grade < 3 ?
            <div className="quiz-question-audio">
              <VolumeUpIcon className="icon" onClick={() => { speakTheText(replaceUnderscore(quizDetails[currentPosition].questionText)) }} />
            </div> : null}
          <div className="exit-quiz" onClick={() => { props.setShowExitDialog(true) }}>
            <img src="/assets/images/quiz/exit.png" alt="" />
          </div>
        </div>
        <div className="quiz-top-section">
          <div className="quizQuestion">{quizDetails[currentPosition].questionText}
          </div>
          <div className="quizImage">
            <Rif boolean={isNotEmpty(quizDetails[currentPosition].questionImagePath)}>
              <img src={quizDetails[currentPosition].questionImagePath} alt="Q" />
            </Rif>
            <Rif boolean={isEmpty(quizDetails[currentPosition].questionImagePath === null)}>
              <Lottie className="quiz-question-animation" animationData={confused} loop={true} />
            </Rif>

          </div>
        </div>
        <div className={isNotEmpty(quizDetails[currentPosition].option1ImagePath) ? "quizAnswers answerImage" : "quizAnswers"}>
          <div className="quiz-options">
            <Rif boolean={isNotEmpty(quizDetails[currentPosition].option1Text) || isNotEmpty(quizDetails[currentPosition].option1ImagePath)}>
              <div className={getAnswerClass('isOption1Marked')} onClick={() => { onAnswerSelection('isOption1Marked') }}> A. &nbsp; {quizDetails[currentPosition].option1Text}
                <Rif boolean={isNotEmpty(quizDetails[currentPosition].option1ImagePath)}>
                  <img src={quizDetails[currentPosition].option1ImagePath} alt="O" />
                </Rif>
              </div>
            </Rif>
            <Rif boolean={isNotEmpty(quizDetails[currentPosition].option2Text) || isNotEmpty(quizDetails[currentPosition].option2ImagePath)}>
              <div className={getAnswerClass('isOption2Marked')} onClick={() => { onAnswerSelection('isOption2Marked') }}> B. &nbsp; {quizDetails[currentPosition].option2Text}
                <Rif boolean={isNotEmpty(quizDetails[currentPosition].option2ImagePath)}>
                  <img src={quizDetails[currentPosition].option2ImagePath} alt="O" />
                </Rif>
              </div>
            </Rif>
            <Rif boolean={isNotEmpty(quizDetails[currentPosition].option3Text) || isNotEmpty(quizDetails[currentPosition].option3ImagePath)}>
              <div className={getAnswerClass('isOption3Marked')} onClick={() => { onAnswerSelection('isOption3Marked') }}> C. &nbsp; {quizDetails[currentPosition].option3Text}
                <Rif boolean={isNotEmpty(quizDetails[currentPosition].option3ImagePath)}>
                  <img src={quizDetails[currentPosition].option3ImagePath} alt="O" />
                </Rif>
              </div>
            </Rif>
            <Rif boolean={isNotEmpty(quizDetails[currentPosition].option4Text) || isNotEmpty(quizDetails[currentPosition].option4ImagePath)}>
              <div className={getAnswerClass('isOption4Marked')} onClick={() => { onAnswerSelection('isOption4Marked') }}> D. &nbsp; {quizDetails[currentPosition].option4Text}
                <Rif boolean={isNotEmpty(quizDetails[currentPosition].option4ImagePath)}>
                  <img src={quizDetails[currentPosition].option4ImagePath} alt="O" />
                </Rif>
              </div></Rif>
          </div>

          <div className="quiz-buttons">
            {previousButton()}{submitNextButton()}
          </div>
        </div>

      </div>
    );
  }

  return (
    <div className="quiz-container-common">
      {quizDetails !== null ? renderQuiz() : <div>Loading...</div>}
    </div>
  );
}

export default QuizPlayer;