import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory, withRouter } from 'react-router-dom';
import { AppContext } from '../../../app-context';
import { EMPTY_STRING } from '../../../components/constants/constants';
import { Loader } from '../../../components/loader/loader.component';
import YesNoDialog from '../../../components/message-dialog/yes-no-dialog.component';
import { TopicCard } from '../../../components/topic-card/topic-card.component';
import { getTopics } from '../../../services/category-service';
import { validUserForQuiz } from '../../../services/validation-util';
import './topic-list.styles.scss';

export const TopicList = (props) => {

  const history = useHistory();
  const location = useLocation();
  const grade = props.grade;
  const subject = props.subject;
  const [topicList, setTopicList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const appContext = useContext(AppContext);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [showMsgDialog, setShowMsgDialog] = useState(false);

  useEffect(() => {
    if (topicList.length === 0) {
      loadTopics();
    }
  }, []);

  const loadTopics = () => {
    getTopics(grade, subject.name, 0).subscribe({
      next: (response) => {
        setTopicList(response.data);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const isDashboard = () => {
    return location.pathname.includes('/userdashboard');
  }

  const onTopicSelect = (topic) => {
    if(topic.isFree === 1){
      startQuiz(topic);
    } else {
      const msg = validUserForQuiz(appContext.userData);
      if (msg === EMPTY_STRING) {
        startQuiz(topic);
      } else {
        // appContext.setDialogMessage(msg);
            setMessage(msg);
            setShowMsgDialog(true);

      }
    }
  }

  const onOkClick = () => {
    history.push('/pricing');
  }

  const startQuiz = (topic) => {
    if (topic.approvedCount !== 0) {
      if (isDashboard()) {
        history.push(`/userdashboard/levels/${grade}/${subject.name}/${topic.name}`);
      } else {
        history.push(`/levels/${grade}/${subject.name}/${topic.name}`);
      }
    }
  }

  const getTopicDetail = () => {
    if (appContext.userData) {
      return `${subject.quizCount} Quizzes  ~ ${subject.attemptCount} Attemptappjsed)`;
    } else {
      return `${subject.quizCount} Quizzes)`;
    }
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        <div className="quiz-subject-list">
          <div className="quizList-header">
            <div className="subject-name">{subject.name}</div>
            {/* <div className="subject-details">({subject.quizCount === 0 ? 'Coming Soon)' : getTopicDetail()}</div> */}
          </div>
          <div className="quiz-list">
            {
              topicList.map((topic, idx) => (
                <div key={idx} className="quiz-list-card" onClick={() => { onTopicSelect(topic) }}>
                  <TopicCard topic={topic} />
                </div>
              ))
            }
          </div>
        </div>
      }
      <YesNoDialog open={showMsgDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
    </div>
  );
}

export default withRouter(TopicList);