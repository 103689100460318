import { PAGE_SIZE } from '../components/constants/constants';
import { get, whitelistGet } from './http-services';

export function getDashboardDetails() {
    return get('/dashboard/details');
}

export function getQuizScores(pageNumber) {
    return get('/dashboard/quizscore?page=' + pageNumber+ '&size=' + PAGE_SIZE +'&sort=creationDate,desc');
}

export function getSubjectScores() {
    return get('/dashboard/subjectscore');
}

export function getTopicScores(subject) {
    return get('/dashboard/topicscore?subject='+subject);
}

export function getStats() {
    return whitelistGet('/dashboard/stats');
}

