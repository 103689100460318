import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EMPTY_STRING } from '../../../components/constants/constants';
import AdComponent from '../../../components/google-ads/google-ads.component';
import Loader from '../../../components/loader/loader.component';
import { findByUser } from '../../../services/user-service';
import { showDataDash } from '../../../services/util-service';
import './user-profile.styles.scss';


const UserProfile = () => {

  const history = useHistory()
  const [profileData, updateProfileData] = useState({
    name: EMPTY_STRING, fatherName: EMPTY_STRING, motherName: EMPTY_STRING,
    emailId: EMPTY_STRING, grade: EMPTY_STRING, school: EMPTY_STRING, aboutMe: EMPTY_STRING
  });
  const [showLoader, setShowLoader] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showAds, setShowAds] = useState(false);
  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    findByUser().subscribe({
      next: (response) => {
        setShowLoader(false);
        updateProfileData(response.data);
      }, error : (error ) => {
        setShowLoader(false);
        setShowError(true);
      }});
  }

  const goToEditProfile = () => {
    history.push('/userdashboard/editprofile');
  }

  const renderView = () => (
    <div className="user-profile-container">
      <Grid justify="center" container spacing={3} xs={12}>
        <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
          <div className="profile-card">
            <div className="profile-edit">
              {/* <div className="profile-image">
                <img src={profileData.imagePath} alt="" />
              </div> */}
              <div className="profile-edit-button" onClick={goToEditProfile}>
                <span>Edit Profile</span>
              </div>
            </div>
            <div className="topArea">
              <Grid justify="center" container spacing={3} xs={12}>
                <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>

                  <div className="profile-info">
                    <div className="profile-info-label">
                      User Identification
                    </div>
                    <div className="profile-info-value">
                      {showDataDash(profileData.userIdentification)}
                    </div>
                    <div className="profile-info-label">
                      Kid's Name
                    </div>
                    <div className="profile-info-value">
                      {showDataDash(profileData.name)}
                    </div>
                    <div className="profile-info-label">
                      Class
                    </div>
                    <div className="profile-info-value">
                      {showDataDash(profileData.grade)}
                    </div>
                    <div className="profile-info-label">
                      Gender
                    </div>
                    <div className="profile-info-value">
                      {showDataDash(profileData.gender)}
                    </div>
                    <div className="profile-info-label">
                      Date of Birth
                    </div>
                    <div className="profile-info-value">
                      {showDataDash(moment(profileData.dateOfBirth).format('DD MMM, YYYY'))}
                    </div>
                    <div className="profile-info-label">
                      Father's Name
                    </div>
                    <div className="profile-info-value">
                      {showDataDash(profileData.fatherName)}
                    </div>
                    <div className="profile-info-label">
                      Mother's Name
                    </div>
                    <div className="profile-info-value">
                      {showDataDash(profileData.motherName)}
                    </div>
                    <div className="profile-info-label">
                      Email Id
                    </div>
                    <div className="profile-info-value">
                      {showDataDash(profileData.emailId)}
                    </div>
                    <div className="profile-info-label">
                      School
                    </div>
                    <div className="profile-info-value">
                      {showDataDash(profileData.school)}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className="profile-info">
                <div className="profile-info-label">
                  Mobile Number
                </div>
                <div className="profile-info-value">
                  {showDataDash(profileData.mobile)}
                </div>
                <div className="profile-info-label">
                  Address
                </div>
                <div className="profile-info-value">
                  {showDataDash(profileData.address)}
                </div>
                <div className="profile-info-label">
                  About
                </div>
                <div className="profile-info-value">
                  {showDataDash(profileData.aboutMe)}
                </div>
              </div>
            </div>

          </div>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      {showLoader ?
        <div className="showLoading"><Loader /> </div>
        :
        <>
          {
            showError ?
              <div className="profile-show-error">
                <img src="/assets/images/errors/dataerror.png" alt="Error" />
                <p>We are facing some issues in fetching data. Kindly try after some time.</p>
              </div>
              :
              renderView()
          }
        </>
      }

      {showAds ? <><AdComponent></AdComponent></> : null}
    </>
  )


}

export default UserProfile;