
import MomentUtils from "@date-io/moment";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { EMPTY_STRING } from "../../constants/constants";
import './date-time.styles.scss';
const DateTimeComponent = (props) => {

    const handleDateChange = (date) => {
        props.onDateSelection({target: {name: props.name, value:  date ? date.toDate() : EMPTY_STRING}});
      };

    return <MuiPickersUtilsProvider utils={MomentUtils}>
       <KeyboardDateTimePicker
        value={props.defaultDate}
        onChange={handleDateChange}
        label={props.label}
        format="DD-MM-YYYY hh:mm:ss A"
        inputVariant="outlined"
        size="small"
        fullWidth
      />
  </MuiPickersUtilsProvider>
}

export default DateTimeComponent;
