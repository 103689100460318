import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useContext } from 'react';
import { AppContext } from '../../app-context';
import { EMPTY_STRING } from '../constants/constants';
import { Rif } from '../../services/util-service';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MessageComponent = () => {
    const appData = useContext(AppContext);

    const isOpen = () => {
        return appData.errorMessage !== EMPTY_STRING || appData.successMessage !== EMPTY_STRING;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        appData.setErrorMessage(EMPTY_STRING);
        appData.setSuccessMessage(EMPTY_STRING);
    };

    return (
        <div>
            <Snackbar open={isOpen()} autoHideDuration={6000} onClose={handleClose}>
                <div>
                    <Rif boolean={appData.successMessage !== EMPTY_STRING}>
                        <Alert onClose={handleClose} severity="success">
                            {appData.successMessage}
                        </Alert>
                    </Rif>
                    <Rif boolean={appData.errorMessage !== EMPTY_STRING}>
                        <Alert onClose={handleClose} severity="error">
                            {appData.errorMessage}
                        </Alert>
                    </Rif>
                </div>
            </Snackbar>
        </div>
    );
}


export default MessageComponent;