import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Lottie from "lottie-react";
import info from "./animations/info.json";
import './message-dialog.styles.scss';

const handleClose = (onClose) => {
  onClose();
}
export const MessageDialog = props => {


  const getBGStyle = () => {
    if (window.screen.orientation.type === 'portrait') {
      return 'lockedDialogContainer bg-horizontal';
    } else {
      return 'lockedDialogContainer bg-vertical';
    }
  }

return (
  <Dialog {...props}
  PaperProps={{
    style: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  }}>
     <div className={getBGStyle()}>
      {/* <img src="/assets/images/site/ok-msg.png" alt=""/> */}
      <div className="message-box-animation"><Lottie className="message-box-top-animation" animationData={info} loop={true} /></div>
      <div className="loginMessage">{props.message}</div>
      <div className="loginButton">
        <Button variant="contained"  onClick={()=>{handleClose(props.onClose)}} color="primary">Ok</Button>
      </div>
    </div>
  </Dialog>
); 
}




export default MessageDialog;