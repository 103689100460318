import { PAGE_SIZE } from '../components/constants/constants';
import { downloadPost, get, post } from './http-services';

export function getCoupons(pageNumber) {
    return get(`/coupon?page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function saveCoupon(couponModel) {
    return post('/coupon', couponModel);
}

export function getValidity(code) {
    return get(`/coupon/validity?code=${code}`);
}

export function findByCode(code) {
    return get(`/coupon/findbycode?code=${code}`);
}

export function getReferralCode() {
    return get('/coupon/referralcode');
}

export function redeemReferralCode(code) {
    return post(`/coupon/redeemreferralcode?code=${code}`);
}

export function getReferralTransactions(pageNumber) {
    return get(`/coupon/referraltransactions?page=${pageNumber}&size=${PAGE_SIZE}&sort=date,desc`);
}

export function getPartnerReferral(pageNumber) {
    return get(`/coupon/partnerreferral?page=${pageNumber}&size=${PAGE_SIZE}`);
}

export function getAdminReferral(pageNumber) {
    return get(`/coupon/referral?page=${pageNumber}&size=${PAGE_SIZE}`);
}

export function exportPartnerReferrals() {
    return downloadPost('/coupon/partnerreferral/export', null);
}

export function exportReferrals() {
    return downloadPost('/coupon/referral/export', null);
}
