import React from 'react'
import {
    EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton
} from 'react-share'
import './social-share.styles.scss'

export const SocialShare = (props) => {

    return <div className="share-container">

        <div className="share-network">
            <WhatsappShareButton
                url={props.shareUrl}
                title={props.title}
                separator=""
                className="share-buttonn"
            >
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>
        </div>

        <div className="share-network">
            <FacebookShareButton
                url={props.shareUrl}
                quote={props.title}
                className="share-button"
            >
                <FacebookIcon size={32} round />
            </FacebookShareButton>
        </div>

        <div className="share-network">
            <TelegramShareButton
                url={props.shareUrl}
                title={props.title}
                className="share-buttonn"
            >
                <TelegramIcon size={32} round />
            </TelegramShareButton>
        </div>

        <div className="share-network">
            <TwitterShareButton
                url={props.shareUrl}
                title={props.title}
                className="share-buttonn"
            >
                <TwitterIcon size={32} round />
            </TwitterShareButton>
        </div>

        <div className="share-network">
            <LinkedinShareButton url={props.shareUrl} className="share-buttonn">
                <LinkedinIcon size={32} round />
            </LinkedinShareButton>
        </div>
        <div className="share-network">
            <EmailShareButton
                url={props.shareUrl}
                subject={props.title}
                body="body"
                className="share-button"
            >
                <EmailIcon size={32} round />
            </EmailShareButton>
        </div>

    </div>
}

export default SocialShare