
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/loader/loader.component';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../services/grid-style';
import { getPointReport } from '../../../services/payment-service';
import './admin-user-transaction.styles.scss';

const UserTransaction = (props) => {

  const [userDetails, setUserDetails] = useState({});
  const [orderList, updateOrderList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    loadOrderList();
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const loadOrderList = () => {
    setShowLoader(true);
    getPointReport(props.user.rowId, page - 1).subscribe({
      next: (response) => {
        console.log(response);
        setUserDetails(response.data)
        updateOrderList(response.data.transactions.content);
        setPageCount(response.data.transactions.totalPages);
        setTotalCount(response.data.transactions.totalElements);
        setShowLoader(false);
      }, error: (error ) => {
        setShowLoader(false);
      }});
  }
  

  const getPointsRemaining = () => {
    const pointsEarned = userDetails.pointsEarned ?  userDetails.pointsEarned: 0
    const pointsRedeemed = userDetails.pointsRedeemed ?  userDetails.pointsRedeemed: 0
    return pointsEarned - pointsRedeemed;
  }

  const getHeaderCard = () => {
    return <div className="user-top-widgets">
       <div className="kid-title">
       Name : {props.user.displayName || 'No Display Name'}
           </div>
      <Grid justify="space-evenly" container spacing={3} xs={12}>
        <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
          <div className="blue-text">Points Earned : {userDetails.pointsEarned || 0}</div>
        </Grid>
        <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
          <div className="purple-text">Points Redeemable : {userDetails.pointsRedeemable|| 0}</div>
        </Grid>
        <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
          <div className="yellow-text">Points Redeemed : {userDetails.pointsRedeemed || 0}</div>
        </Grid>
        <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
          <div className="green-text">Points Remaining : { getPointsRemaining() }</div>
        </Grid>
        <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
          <div className="red-text">Zone Cleared : {userDetails.zoneCleared || 0}</div>
        </Grid>
      </Grid>
    </div>
  }


  const renderBadges = () => {
    // return <> {userDetails.badges ? <Badges dashboardDetail={userDetails.badges} grade={1}/> : null}</>
    return <Grid justify="space-evenly" container spacing={3} xs={12}>
      {userDetails.badges.map((badge) => (
        <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
          <div className="badge-grade">Grade - {badge.grade}</div>
          <div className="badge-badge">Badge - {badge.badge}</div>
        </Grid>
      ))}
    </Grid>
  }
  
  const renderOrders = () => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Grade</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Milestone</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Topic</TableCell>
              <TableCell>Point</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderList.map((order) => (
              <StyledTableRow key={order.packageName}>
                <StyledTableCell component="th" scope="row">
                  {order.grade}
                </StyledTableCell>
                <StyledTableCell>{order.level}</StyledTableCell>
                <StyledTableCell>{order.milestone}</StyledTableCell>
                <StyledTableCell>{order.details}</StyledTableCell>
                <StyledTableCell>{order.subject}</StyledTableCell>
                <StyledTableCell>{order.topic}</StyledTableCell>
                <StyledTableCell>{order.point}</StyledTableCell>
                <StyledTableCell>{moment(order.creationDate).format('lll')}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const renderView = () => {
    return (
      <div>
        <div> {getHeaderCard()}</div>
        <div className="badge">{renderBadges()}</div>
        {orderList.length > 0 ?
          <>
            <div className="user-dtl-list">
            <div className="total-count">
              <span className="total-label">Total Count : </span>
              <span className="total-value">{totalCount}</span>
            </div>
              {renderOrders()}
            </div>
            <div className="user-dtl-container-pagination">
              <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handleChange} />
            </div>
          </>
          :
          <div className="no-orders-data">
            <img src="/assets/images/errors/nodata.png" alt="" />
            No Transactions Found
          </div>
        }
      </div>
    );
  }

  return (
    <div className="user-dtl-container">
      {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
    </div>
  );
}

export default UserTransaction;