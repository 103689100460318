import { Button, FormControl, Grid, MenuItem, TextField } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import HistoryIcon from '@material-ui/icons/History';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../app-context';
import { approveGameQuestions, findAllGames, findAllSelectGameLevels, getSubjects, getTopics, saveSelectGameQuestion } from '../../../services/game-service';
import { isEmpty, isNotEmpty } from '../../../services/util-service';
import { EMPTY_STRING, ROLE_ADMIN, ROLE_USER } from '../../constants/constants';
import { GRADES, OTHER } from '../../games/game-constants';
import Loader from '../../loader/loader.component';
import YesNoDialog from '../../message-dialog/yes-no-dialog.component';
import QuestionHistory from '../admin-question-bank/admin-question-history/admin-question-history.component';
import ImageTextfield from '../admin-question-bank/image-textfield.component';
import './select-game-question.styles.scss';
const SelectGameQuestion = () => {

  const [disableHeader, setDisableHeader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const appContext = useContext(AppContext);
  const [levelData, setLevelData] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [showMessageDialog, setShowMsgDialog] = useState(false);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [game, setGame] = useState(EMPTY_STRING);
  const initFormData = {
    grade: 1,
    subject: EMPTY_STRING,
    otherSubject: EMPTY_STRING,
    topic: EMPTY_STRING,
    otherTopic: EMPTY_STRING,
    name: EMPTY_STRING
  };
  const initQuestionFormData = {
    rowId: null,
    grade: 1,
    subject: EMPTY_STRING,
    topic: EMPTY_STRING,
    level: 1,
    isReviewed: 0,
    number: 0,
    name: EMPTY_STRING,
    questionText: EMPTY_STRING,
    questionImagePath: null,
    questionImage: [],
    option1Text: EMPTY_STRING,
    option1ImagePath: null,
    option1Image: [],
    isOption1Correct: 0,
    option2Text: EMPTY_STRING,
    option2ImagePath: null,
    option2Image: [],
    isOption2Correct: 0,
    option3Text: EMPTY_STRING,
    option3ImagePath: null,
    option3Image: [],
    isOption3Correct: 0,
    option4Text: EMPTY_STRING,
    option4ImagePath: null,
    option4Image: [],
    isOption4Correct: 0,
    option5Text: EMPTY_STRING,
    option5ImagePath: null,
    option5Image: [],
    isOption5Correct: 0,
    option6Text: EMPTY_STRING,
    option6ImagePath: null,
    option6Image: [],
    isOption6Correct: 0,
    correctOptionExplanation: EMPTY_STRING
  };
  const [userRole, setRole] = useState(ROLE_USER);
  const [formData, updateFormData] = useState(initFormData);
  const [questionFormData, updateQuestionFormData] = useState(initQuestionFormData);

  useEffect(() => {
    if (gameList.length === 0) {
      loadGameList();
    }
  }, []);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (appContext.userData) {
      setRole(appContext.userData.role);
    }
  }, [appContext]);

  useEffect(() => {
    loadSubjects();
  }, [formData.name, formData.grade]);

  useEffect(() => {
    loadTopics();
  }, [formData.name, formData.grade, formData.subject]);

  const loadGameList = () => {
    setShowLoader(true);
    findAllGames().subscribe({
      next: (response) => {
        setGameList(response.data);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const loadSubjects = () => {
    if (formData.name !== EMPTY_STRING && formData.grade !== EMPTY_STRING) {
      setShowLoader(true);
      getSubjects(formData.name, formData.grade).subscribe({
        next: (response) => {
          setSubjectList([...response.data, OTHER]);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
        }
      });
    }
  }

  const loadTopics = () => {
    if (formData.name !== EMPTY_STRING && formData.grade !== EMPTY_STRING && formData.subject !== EMPTY_STRING) {
      setShowLoader(true);
      getTopics(formData.name, formData.grade, formData.subject).subscribe({
        next: (response) => {
          setTopicList([...response.data, OTHER]);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
        }
      });
    }
  }

  const onSubmit = (event) => {
    findAllQuestions();
  }

  const onReset = () => {
    updateQuestionFormData(initQuestionFormData);
  }

  const findAllQuestions = () => {
    setShowLoader(true);
    const subject = formData.subject === OTHER ? formData.otherSubject : formData.subject;
    const topic = formData.topic === OTHER ? formData.otherTopic : formData.topic;
    findAllSelectGameLevels(formData.name, formData.grade, subject, topic).subscribe({
      next: (response) => {
        setLevelData(response.data);
        setDisableHeader(true);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const onEditHeader = (event) => {
    setDisableHeader(false);
  }

  const onAnswerSelection = (ansSelected) => {
    const tempAns = { ...questionFormData };
    const newData = {
      ...tempAns,
      isOption1Correct: ansSelected === 'isOption1Correct' ? 1 : 0,
      isOption2Correct: ansSelected === 'isOption2Correct' ? 1 : 0,
      isOption3Correct: ansSelected === 'isOption3Correct' ? 1 : 0,
      isOption4Correct: ansSelected === 'isOption4Correct' ? 1 : 0,
      isOption5Correct: ansSelected === 'isOption5Correct' ? 1 : 0,
      isOption6Correct: ansSelected === 'isOption6Correct' ? 1 : 0,
    };
    updateQuestionFormData(newData);
  }

  const handleTextChange = (e) => {
    updateQuestionFormData({
      ...questionFormData,
      [e.target.name]: e.target.value,
      grade: formData.grade,
      subject: formData.subject === OTHER ? formData.otherSubject : formData.subject,
      topic: formData.topic === OTHER ? formData.otherTopic : formData.topic,
      name: formData.name,
      gameId: formData.gameId
    });
  };

  const handleImageChange = (e, binaryImage) => {
    updateQuestionFormData({
      ...questionFormData,
      [e.target.name]: binaryImage,
    });
  };

  const handleDeleteImage = (imageFieldName) => {
    const imageFieldNamePath = imageFieldName + 'Path';
    updateQuestionFormData({
      ...questionFormData,
      [imageFieldName]: null,
      [imageFieldNamePath]: null
    });
  };

  const isValidQuestion = () => {
    let valid = false;
    if (isEmpty(questionFormData.questionText)) {
      appContext.setDialogMessage('Please add \'Question Description\' to add the question');
      valid = false;
    } else {
      valid = true;
    }
    return valid;
  }

  const onQuestionSubmit = (event) => {
    event.preventDefault();
    if (isValidQuestion()) {
      saveSelectGameQuestion(game.type, questionFormData).subscribe({
        next: (response) => {
          onReset();
          findAllQuestions();
          appContext.setSuccessMessage(response.message);

        }, error: (error) => {
          appContext.setErrorMessage(error.message);
        }
      });
    }
  }

  const onEditQuestion = (event) => {
    updateFormData({
      grade: event.grade,
      subject: event.subject,
      topic: event.topic,
      name: event.name,
      gameId: event.gameId
    });
    updateQuestionFormData(event);
    const game = getGameFromList(event.gameId);
    setGame(game);
  }

  const getGameFromList = (gameId) => {
    return gameList.find(element => element.rowId === gameId);
  }

  const onApprove = () => {
    setMessage('Do you want to approve this level ?');
    setShowMsgDialog(true);
  }

  const onOkClick = () => {
    const subject = formData.subject === OTHER ? formData.otherSubject : formData.subject;
    const topic = formData.topic === OTHER ? formData.otherTopic : formData.topic;
    approveGameQuestions(formData.name, formData.grade, subject, topic).subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
      }
    });
  }

  const showQuestionHistory = (question) => {
    setCurrentQuestion(question);
    setShowHistory(true);
  }

  const handleGameChange = (e) => {
    setGame(e.target.value);
    updateFormData({
      ...formData,
      name: e.target.value.name,
      gameId: e.target.value.rowId
    });
  };

  const headerFormPanel = () => {
    return <div className="header-panel">
      <div className="header-field">
        <TextField name="name" value={game || EMPTY_STRING} select label="Name" fullWidth
          disabled={disableHeader} required onChange={handleGameChange} variant="outlined" size="small">
          {gameList.map((game) => (
            <MenuItem key={game.name} value={game}>
              {game.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="header-field"><TextField name="grade" value={formData.grade} select label="Class"
        disabled={disableHeader} required onChange={handleChange} variant="outlined" fullWidth size="small">
        {GRADES.map((grade) => (
          <MenuItem key={grade.value} value={grade.value}>
            {grade.name}
          </MenuItem>
        ))}
      </TextField>
      </div>
      <div className="header-field">
        <TextField name="subject" value={formData.subject} select label="Subject" fullWidth
          disabled={disableHeader} required onChange={handleChange} variant="outlined" size="small">
          {subjectList.map((subject) => (
            <MenuItem key={subject} value={subject}>
              {subject}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {formData.subject === OTHER ? <div className="header-field">
        <TextField name="otherSubject" value={formData.otherSubject} label="Other Subject" fullWidth
          disabled={disableHeader} required onChange={handleChange} variant="outlined" size="small" />
      </div> : null}
      <div className="header-field">
        <TextField name="topic" value={formData.topic} select label="Topic" fullWidth
          disabled={disableHeader} required onChange={handleChange} variant="outlined" size="small">
          {topicList.map((topic) => (
            <MenuItem key={topic} value={topic}>
              {topic}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {formData.topic === OTHER ? <div className="header-field">
        <TextField name="otherTopic" value={formData.otherTopic} label="Other Topic" fullWidth
          disabled={disableHeader} required onChange={handleChange} variant="outlined" size="small" />
      </div> : null}
      <div className="header-field">
        <Button variant="contained" color="secondary" onClick={onSubmit}> Submit and Search </Button>
      </div>
      <div className="header-field">
        <Button variant="contained" color="primary" onClick={onEditHeader}> Edit Header </Button>
      </div>
    </div>
  }

  const questionPanel = () => {
    return <div className="newQuestionForm">
      <div className="header-field inline-field">
        <TextField name="level" value={questionFormData.level || EMPTY_STRING} label="Level" fullWidth
          required onChange={handleTextChange} variant="outlined" size="small" />
      </div>
      <div className="header-field inline-field">
        <TextField name="number" value={questionFormData.number || EMPTY_STRING} label="number" fullWidth
          required onChange={handleTextChange} variant="outlined" size="small" />
      </div>
      <ImageTextfield id="question-description" label="Question Description" value={questionFormData.questionText}
        image={questionFormData.questionImagePath} multiline={true} rows={3} textFieldName="questionText" imageFieldName="questionImage"
        handleTextChange={handleTextChange} handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }}
        onDeleteImage={handleDeleteImage} imagePath="questionImagePath"></ImageTextfield>
      <ImageTextfield id="answer-a-details" label="Answer (a)" value={questionFormData.option1Text} image={questionFormData.option1ImagePath}
        checked={questionFormData.isOption1Correct === 1} onSelection={() => { onAnswerSelection('isOption1Correct') }}
        textFieldName="option1Text" imageFieldName="option1Image" checkName="checkedA" handleTextChange={handleTextChange}
        handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage}
        imagePath="option1ImagePath"></ImageTextfield>
      <ImageTextfield id="answer-b-details" label="Answer (b)" value={questionFormData.option2Text} image={questionFormData.option2ImagePath}
        checked={questionFormData.isOption2Correct === 1} onSelection={() => { onAnswerSelection('isOption2Correct') }}
        textFieldName="option2Text" imageFieldName="option2Image" checkName="checkedB" handleTextChange={handleTextChange}
        handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage}
        imagePath="option2ImagePath"></ImageTextfield>
      <ImageTextfield id="answer-c-details" label="Answer (c)" value={questionFormData.option3Text} image={questionFormData.option3ImagePath}
        checked={questionFormData.isOption3Correct === 1} onSelection={() => { onAnswerSelection('isOption3Correct') }}
        textFieldName="option3Text" imageFieldName="option3Image" checkName="checkedC" handleTextChange={handleTextChange}
        handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage}
        imagePath="option3ImagePath"></ImageTextfield>
      <ImageTextfield id="answer-d-details" label="Answer (d)" value={questionFormData.option4Text} image={questionFormData.option4ImagePath}
        checked={questionFormData.isOption4Correct === 1} onSelection={() => { onAnswerSelection('isOption4Correct') }}
        textFieldName="option4Text" imageFieldName="option4Image" checkName="checkedD" handleTextChange={handleTextChange}
        handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage}
        imagePath="option4ImagePath"></ImageTextfield>
      <ImageTextfield id="answer-d-details" label="Answer (e)" value={questionFormData.option5Text} image={questionFormData.option5ImagePath}
        checked={questionFormData.isOption5Correct === 1} onSelection={() => { onAnswerSelection('isOption5Correct') }}
        textFieldName="option5Text" imageFieldName="option5Image" checkName="checkedD" handleTextChange={handleTextChange}
        handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage}
        imagePath="option5ImagePath"></ImageTextfield>
      <ImageTextfield id="answer-d-details" label="Answer (f)" value={questionFormData.option6Text} image={questionFormData.option6ImagePath}
        checked={questionFormData.isOption6Correct === 1} onSelection={() => { onAnswerSelection('isOption6Correct') }}
        textFieldName="option6Text" imageFieldName="option6Image" checkName="checkedD" handleTextChange={handleTextChange}
        handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage}
        imagePath="option6ImagePath"></ImageTextfield>
      <div className="header-field">
        <TextField name="correctOptionExplanation" value={questionFormData.correctOptionExplanation} label="Correct Option Explanation"
          fullWidth multiline rows={4} onChange={handleTextChange} variant="outlined" size="small" />
      </div>
      <FormControl className="header-field">
        <Button variant="contained" color="primary" onClick={onQuestionSubmit}> {questionFormData.rowId === null ? 'Add Question' : 'Update Question'} </Button>
      </FormControl>
      <FormControl className="header-field">
        <Button variant="contained" color="secondary" onClick={onReset}> Reset Question </Button>
      </FormControl>
      <FormControl className="header-field">
        {userRole === ROLE_ADMIN ? <Button variant="contained" color="primary" onClick={onApprove} > Approve Question Set </Button> : null}
      </FormControl>
      <YesNoDialog open={showMessageDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
    </div>
  }

  const getQuestionList = () => {
    return <div className="game-question-list"><div className="game-answers-details">
      {levelData.map((question, index) => (
        <div className="game-answers-row">
          <div className="game-answers-label">Level: {question.level} - Question {question.number}
            <span className="questionListIcon">
              <EditOutlinedIcon className="icon" onClick={() => { onEditQuestion(question) }} />
              {userRole === ROLE_ADMIN ? <HistoryIcon className="icon" onClick={() => { showQuestionHistory(question) }} /> : null}





            </span>
          </div>
          <div className="game-answers-question">{question.questionText}
            {isEmpty(question.questionImagePath) ? null : <div><img src={question.questionImagePath} alt="" /></div>}
          </div>
          <Grid container xs={12}>
            {getOption('a', question.option1Text, question.option1ImagePath, question.isOption1Marked, question.isOption1Correct)}
            {getOption('b', question.option2Text, question.option2ImagePath, question.isOption2Marked, question.isOption2Correct)}
            {getOption('c', question.option3Text, question.option3ImagePath, question.isOption3Marked, question.isOption3Correct)}
            {getOption('d', question.option4Text, question.option4ImagePath, question.isOption4Marked, question.isOption4Correct)}
            {getOption('e', question.option5Text, question.option5ImagePath, question.isOption5Marked, question.isOption5Correct)}
            {getOption('f', question.option6Text, question.option6ImagePath, question.isOption6Marked, question.isOption6Correct)}
          </Grid>
          <div className="game-answers-question">
            {question.correctOptionExplanation === EMPTY_STRING ? null : question.correctOptionExplanation}
          </div>
        </div>
      ))}
    </div>
    </div>
  }

  const getOption = (optionNum, optionText, optionImage, isOptionMarked, isOptionCorrect) => {
    let answerClass = 'game-answers-option-text';
    if (isOptionMarked === 1) {
      answerClass += ' selected-answer';
    }
    if (isOptionCorrect === 1) {
      answerClass += ' correct-answer';
    }
    return (
      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
        {isNotEmpty(optionText) ? <div className={answerClass}>{`${optionNum})`} {optionText}</div>
          :
          isNotEmpty(optionImage) ? <div className={answerClass} >{`${optionNum})`} <img src={optionImage} /> </div> : null
        }
      </Grid>
    )
  }

  const renderMain = () => {
    return <div className="game-question-container">
      {headerFormPanel()}
      <div className='bottom-panel'>
        <div className="game-request-title">Game Question</div>
        <Grid justify="center" container spacing={3}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5} >
            {questionPanel()}
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            {levelData.length > 0 ? getQuestionList() : null}
          </Grid>
        </Grid>
      </div>
      <QuestionHistory open={showHistory} onClose={() => { setShowHistory(false) }} questionId={currentQuestion.rowId} isGameQuestion={true} />
    </div>
  }

  return (
    <>
      {showLoader ? <Loader /> :
        renderMain()
      }
    </>
  );
}

export default SelectGameQuestion;
