import React from 'react'
import { useState, useEffect } from 'react';
import './timer.styles.scss';

const Timer = (props) => {
  const { initialMinute = 0, initialSeconds = 23 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          props.timeOut();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div className="game-timer">
      <div className="timer-time"> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>
    </div>
  )
}

export default Timer;