import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../app-context';
import { EMPTY_STRING } from '../../../components/constants/constants';
import GameFilterDialog from '../../../components/games/filter-dialog/filter-dialog.component';
import { CHICKEN_EGGS, CHICKEN_EGGS_PATH, COUNTRY_FLAG, COUNTRY_FLAG_PATH, DRACULA, DRACULA_PATH, LOST_AND_FOUND, LOST_AND_FOUND_PATH, MISSING_TREASURE, MISSING_TREASURE_PATH, MONUMENTS, MONUMENTS_PATH, SAVE_THE_EARTH, SAVE_THE_EARTH_PATH, WINDOW_BLINDS, WINDOW_BLINDS_PATH } from '../../../components/games/game-constants';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './game-section.styles.scss';
import { findAllGame } from '../../../services/game-service';


const GameSection = (props) => {
  const responsiveGames = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisibilityGutter: 40
    }
  };
  const [gameList, setGameList] = useState([]);


  useEffect(() => {
    if (gameList.length === 0) {
      loadGameList();
    }
  }, []);


  const loadGameList = () => {
    findAllGame(props.classType, 0).subscribe({
      next: (response) => {
        setGameList(response.data.content);
      }, error: (error) => {
      }
    });
  }

  const getCardImagePath = (gameImagePath) => {
    return `https://www.cudents.com/prod/assets/images/games/${gameImagePath}/main_card.webp`;
  }


  return (
    <div className="games-listing">
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsiveGames}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        keyBoardControl={true}
        transitionDuration={500}
        renderButtonGroupOutside={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        centerMode={true}
      >
        {gameList.map((game) => (
          <div className="game-card">
            <div className="game-image">
            <a href={process.env.REACT_APP_PLAYSTORE_URL}>
            <img src={getCardImagePath(game.gameFolderName)} alt="" /></a>
             </div>
            <div className="game-name">{game.name}</div>
            <div className="game-tag">{game.tagLine}</div>
          </div>
        ))}
      </Carousel>
    </div>
  );

}

export default GameSection;