import { Dialog, Grid, Hidden } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getSelectGameHistory } from '../../../../services/game-service';
import { getQuestionHistory } from '../../../../services/question-service';
import { isEmpty, isNotEmpty } from '../../../../services/util-service';
import { EMPTY_STRING } from '../../../constants/constants';
import Loader from '../../../loader/loader.component';
import './admin-question-history.styles.scss';
const QuestionHistory = (props) => {

  const [showLoader, setShowLoader] = useState(false);
  const [questionHistory, setQuestionHistory] = useState([]);

  useEffect(() => {
    if (props.questionId) {
      findHistory();
    }
  },[props.questionId]);

  const findHistory = () => {
    setShowLoader(true);
    if(props.isGameQuestion){
      getSelectGameHistory(props.questionId).subscribe({
        next: (response) => {
          setQuestionHistory(response.data);
          setShowLoader(false);
        },error: (error) => {
          setShowLoader(false);
        }});
    } else {
      getQuestionHistory(props.questionId).subscribe({
        next: (response) => {
          setQuestionHistory(response.data);
          setShowLoader(false);
        },error: (error) => {
          setShowLoader(false);
        }});
    }
 
  }

  const getQuestionList = () => {
    return <div className="history-answers-details">
      {questionHistory.map((question, index) => (
        <div className="history-answers-row">
          <div className="history-answers-label">
            <span>Level: {question.level} - Question {question.number}</span>
            <span className="creator">Created By: {question.creator}</span>
            <span className="updator">Updated By: {question.updater}</span>
          </div>
          <div className="history-answers-question">{question.questionText}
            {isEmpty(question.questionImagePath) ? null : <div><img src={question.questionImagePath} alt="" /></div>}
          </div>
          <Grid container xs={12}>
            {getOption('a', question.option1Text, question.option1ImagePath, question.isOption1Marked, question.isOption1Correct)}
            {getOption('b', question.option2Text, question.option2ImagePath, question.isOption2Marked, question.isOption2Correct)}
            {getOption('c', question.option3Text, question.option3ImagePath, question.isOption3Marked, question.isOption3Correct)}
            {getOption('d', question.option4Text, question.option4ImagePath, question.isOption4Marked, question.isOption4Correct)}
            {getOption('e', question.option5Text, question.option5ImagePath, question.isOption5Marked, question.isOption5Correct)}
            {getOption('f', question.option6Text, question.option6ImagePath, question.isOption6Marked, question.isOption6Correct)}
          </Grid>
          <div className="history-answers-question">
            {question.correctOptionExplanation === EMPTY_STRING ? null : question.correctOptionExplanation}
          </div>
        </div>
      ))}
    </div>
  }

  const getOption = (optionNum, optionText, optionImage, isOptionMarked, isOptionCorrect) => {
    let answerClass = 'history-answers-option-text';
    if (isOptionMarked === 1) {
      answerClass += ' selected-answer';
    }
    if (isOptionCorrect === 1) {
      answerClass += ' correct-answer';
    }
    return (
      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
        {isNotEmpty(optionText) ? <div className={answerClass}>{`${optionNum})`} {optionText}</div>
          :
          isNotEmpty(optionImage) ? <div className={answerClass} >{`${optionNum})`} <img src={optionImage} /> </div> : null
        }
      </Grid>
    )
  }

  const renderMain = () => {
    return <div className="question-history-container">
      <div className='bottom-panel'>
        {questionHistory.length > 0 ? getQuestionList() : null}
      </div>
    </div>
  }

  return (
    <Dialog maxWidth={false} {...props}>
      <Hidden only={['xs']}>
        <div className="result-container">
          {showLoader ? <Loader /> :
            renderMain()
          }
        </div>
      </Hidden>
      <Hidden only={['xl', 'lg', 'md', 'sm']}>
        <div className="result-container result-containerMobile">
          {showLoader ? <Loader /> :
            renderMain()
          }
        </div>
      </Hidden>
    </Dialog>
  );
}

export default QuestionHistory;
