import { PAGE_SIZE } from '../components/constants/constants';
import { get, remove, whitelistPost } from './http-services';


export function findAllRequestContest(pageNumber) {
    return get('/contest/request/get?page=' + pageNumber+ '&size=' + PAGE_SIZE +'&sort=creationDate,desc');
}

export function saveRequestContest(feedback) {
    return whitelistPost('/contest/request/save', feedback);
}

export function deleteRequestContest(contestRequestId) {
    return remove(`/contest/request/delete?contestRequestId=${contestRequestId}`);
}

