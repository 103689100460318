import React from 'react';
import './feature-card.styles.scss';
const FeatureCard = (props) => {

  return (
    <div className="infoArea">
      <div className="infoIcon">
        <img src={props.image} alt="" />
      </div>
      <div className="infoTitle">{props.title}</div>
      <div className="infoData">
        {props.info}
      </div>
    </div>
  );
}

export default FeatureCard;