import React, { useEffect, useState } from "react";
import { isNotEmpty } from '../../../../services/util-service';
import Timer from "../../timer/timer.component";
import ClockComponent from '../clock/clock.component';
import './watch-it.styles.scss';
const WatchItComponent = (props) => {

  const [optionsList, setOptionsList] = useState(props.questionDetails.options);
  const [audioExplode, setAudioExplode] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/savetheearth/wrong_answer.mp3'));
  const [audioRight, setAudioRight] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/savetheearth/right_answer.mp3'));
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [timeOver, setTimeOver] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);


  const resetGame = () => {
    let emptyArray = [];
    setShowQuestion(false);
    setCorrectAnswer(false);
    setWrongAnswer(false);
    setTimeOver(false);
    setTimeout(() => {
      setOptionsList(props.questionDetails.options);
      setShowQuestion(true);
    }, 500);
  }

  useEffect(() => {
    resetGame();
  }, [props.questionDetails]);



  const wrongAnswerSelected = () => {
    setWrongAnswer(true);
    if (props.music) {
      audioExplode.play();
    }
  }



  const timeOut = () => {
    setShowQuestion(false);
    setOptionsList(null);
    setShowQuestion(false);
    setTimeOver(true);
    if (props.music) {
      audioExplode.play();
    }
  }

  const correctAnswerSelected = () => {
    setCorrectAnswer(true);
    if (props.music) {
      audioRight.play();
    }
    props.correctAnswer();
  }

  const answerSelected = (ans) => {
    setShowQuestion(false);
    setOptionsList(null);
    // Verify the answer
    if (ans.isCorrect === 1) {
      correctAnswerSelected();
    } else {
      wrongAnswerSelected();
    }
  }



  const playLevelAgain = () => {
    if (props.currentQuestion === 1) {
      resetGame();
    } else {
      props.playAgain();
    }
  }

  const renderView = () => {
    return (
      <div className="watch-it-game-box">
        {/* <div className="watch-it-game-background">
          <img src="https://www.cudents.com/prod/assets/images/games/chickeneggs/bg_chickeneggs.webp" alt="" />
        </div> */}
          <>
            {wrongAnswer ? <div className="answer-out"><img src="https://www.cudents.com/prod/assets/images/games/watchit/wa.webp" alt="" /><div><div className="game-button" onClick={playLevelAgain}>Play Again</div></div></div> : null}
            {correctAnswer ?
            <div className="answer-out">
              <img src="https://www.cudents.com/prod/assets/images/games/watchit/ca.webp" alt="" />
              {props.nextLevelButton ? <div><div className="game-button" onClick={props.playNextLevel}>Play Level {props.currentLevel + 1}</div></div> : null}
              {props.serverMessage ? <div className="bonus-message">{props.serverMessage}</div> : null}
            </div> : null}
            {timeOver ? <div className="answer-out"><img src="https://www.cudents.com/prod/assets/images/games/watchit/timeover.webp" alt="" /><div><div className="game-button" onClick={playLevelAgain}>Play Again</div></div></div> : null}
            {showQuestion ?
              <div className="game-level-number">Level - {props.currentLevel} ({props.currentQuestion}/{props.totalQuestions})</div>
              : null}

            {showQuestion ?
              <div className="clock-container">
                {optionsList.map(option => (
                  <>
                    {isNotEmpty(option.text) ?
                      <div className="analog-clock" onClick={() => { answerSelected(option) }} >
                        <ClockComponent hours={parseInt(option.text.split(':')[0])} minutes={parseInt(option.text.split(':')[1])} seconds={parseInt(option.text.split(':')[2])}></ClockComponent>
                      </div>
                      : null}
                  </>
                ))}

              </div>
              : null}
            {showQuestion ?
              <div className="question-alphabets">
                {showQuestion && !(wrongAnswer || correctAnswer || timeOver) ?
                  <>
                    <div className="game-timer-section">
                      <Timer timeOut={timeOut} initialMinute={0} initialSeconds={30}></Timer>
                    </div>
                    <div className="game-question-section">
                      <span className="game-question">{props.questionDetails.questionText}</span>
                    </div>
                  </> : null
                }
              </div> : null}
          </>
      </div>
    );
  }

  return (
    <>
      {renderView()}
    </>


  );
}

export default WatchItComponent;