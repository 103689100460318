import Grid from '@material-ui/core/Grid';
import React from 'react';
import './quiz-listing.styles.scss';

const QuizListing = () => {

  return (
    <div className="quiz-grade-list">
      <div className="grade-label">Class - 1</div>
      <div className="grade-title">
        <img src="/assets/images/homepage/t_sweety.png" alt="" />
        Mathematics
      </div>
      <div className="quiz-listing">
        <Grid container>
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
            <div className="quiz-card">
              <div className="quiz-image"><img src="/assets/images/homepage/quiz/1.png" alt="" /></div>
              <div className="quiz-name">Addition & Subtraction</div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
            <div className="quiz-card">
              <div className="quiz-image"><img src="/assets/images/homepage/quiz/2.png" alt="" /></div>
              <div className="quiz-name">Class 2</div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
            <div className="quiz-card">
              <div className="quiz-image"><img src="/assets/images/homepage/quiz/3.png" alt="" /></div>
              <div className="quiz-name">Class 3</div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
            <div className="quiz-card">
              <div className="quiz-image"><img src="/assets/images/homepage/quiz/4.png" alt="" /></div>
              <div className="quiz-name">Class 4</div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
            <div className="quiz-card">
              <div className="quiz-image"><img src="/assets/images/homepage/quiz/5.png" alt="" /></div>
              <div className="quiz-name">Class 5</div>
            </div>
          </Grid>

        </Grid>
      </div>


      <div className="grade-title">
        <img src="/assets/images/homepage/t_sweety.png" alt="" />
        Science
      </div>
      <div className="quiz-listing">
        <Grid container>
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
            <div className="quiz-card">
              <div className="quiz-image"><img src="/assets/images/homepage/quiz/1.png" alt="" /></div>
              <div className="quiz-name">Human Body</div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
            <div className="quiz-card">
              <div className="quiz-image"><img src="/assets/images/homepage/quiz/2.png" alt="" /></div>
              <div className="quiz-name">Class 2</div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
            <div className="quiz-card">
              <div className="quiz-image"><img src="/assets/images/homepage/quiz/3.png" alt="" /></div>
              <div className="quiz-name">Class 3</div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
            <div className="quiz-card">
              <div className="quiz-image"><img src="/assets/images/homepage/quiz/4.png" alt="" /></div>
              <div className="quiz-name">Class 4</div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
            <div className="quiz-card">
              <div className="quiz-image"><img src="/assets/images/homepage/quiz/5.png" alt="" /></div>
              <div className="quiz-name">Class 5</div>
            </div>
          </Grid>

        </Grid>
      </div>

    </div>
  );
}

export default QuizListing;