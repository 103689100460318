import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import useInterval from 'react-useinterval';
import blast from "../animations/blast.json";
import flyingBird from "../animations/flyingBird.json";
import heart from "../animations/heart.json";
import studentFlying from "../animations/studentFlying.json";
import './game-console-catch.styles.scss';

function GameConsoleCatch(props) {

  const [runnerJumping, setRunnerJumping] = useState(false);
  const [hideObstacle, setHideObstacle] = useState(false);
  const [correctOptions, setCorrectOptions] = useState(null);
  const [wrongOptions, setWrongOptions] = useState(null);
  const [currentOption, setCurrentOption] = useState(null);
  const [currentScore, setCurrentScore] = useState(0);
  const [showBlast, setShowBlast] = useState(false);
  const [showPoint, setShowPoint] = useState(false);
  const [gameLives, setGameLives] = useState([1, 1, 1]);
  const [infiniteTimer, setInfiniteTimer] = useState(null);
  const [RightOrWrong, setRightOrWrong] = useState(1);
  const [changeOption, setChangeOption] = useState(true);
  const [previousOptions, setPreviousOptions] = useState([null,null,null])
  // const [livesLeft, setLivesLeft] = useState(3);
  // const [lifeCountedOnce, setLifeCountedOnce] = useState(false);
  let counter;
  let scoreCountedOnce = false;
  // let livesLeft = 3;


  useEffect(() => {
    setWrongOptions(props.gameData["wrongOptions"]);
    setCorrectOptions(props.gameData["correctOptions"]);
    setCurrentOption(props.gameData["wrongOptions"][0]);
  }, []);


  useEffect(() => {
    if (props.gameStart) {
      setCurrentScore(0);
      setInfiniteTimer(10);
      setHideObstacle(false);
      setTimeout(() => {
        counter = 0;
      }, 1000);
    }
  }, [props.gameStart])



  const randomOption = () => {

    let temp = Math.round(Math.random());
    if (temp === previousOptions[0] && temp === previousOptions[1] && temp === previousOptions[2]) {
      if (temp === 0) {
        temp = 1;
      } else {
        temp = 0;
      }
    } else {
      let newArr = [...previousOptions];
      newArr.push(temp);
      newArr = newArr.slice(1);
      setPreviousOptions([...newArr]);
    }

    if (temp === 0) {
      setCurrentOption(correctOptions[Math.floor(Math.random() * correctOptions.length)]);
    } else {
      setCurrentOption(wrongOptions[Math.floor(Math.random() * wrongOptions.length)]);
    }
    setRightOrWrong(temp);
  }

  const jump = () => {
    if (!runnerJumping) {
      setRunnerJumping(true);
      setTimeout(() => {
        setRunnerJumping(false);
      }, 900);
    }
  }

  const getRunner = (val) => {
    switch (val) {
      case 'plane':
        return <Lottie className="game-console-runner-props" animationData={studentFlying} loop={true} />;
        break;

      case 'bird':
        return <Lottie className="game-console-runner-props" animationData={flyingBird} loop={true} />;
        break;

      default:
        return <Lottie className="game-console-runner-props" animationData={studentFlying} loop={true} />;
        break;
    }
  }


  const checkDead = () => {
    let character = document.getElementById("character");
    let block = document.getElementById("block");
    let characterBottom = parseInt(window.getComputedStyle(character).getPropertyValue("bottom"));
    let blockLeft = parseInt(window.getComputedStyle(block).getPropertyValue("left"));

    if (blockLeft < 270 && blockLeft > 30 && characterBottom > 15) {
      if (RightOrWrong) {
        setShowBlast(true);
        setTimeout(() => {
          setShowBlast(false);
        }, 500);
        setHideObstacle(true);
        props.playMusic('lost');
        setShowBlast(true);
        setInfiniteTimer(null);
        character = null;
        block = null;
        setRunnerJumping(false);
        counter = 0;
        // setLivesLeft(3);
        // livesLeft = 3;
        // setGameLives([1, 1, 1]);
        props.stopGame(currentScore);
      }
      else {
        if (!scoreCountedOnce) {
          scoreCountedOnce = true;
          props.playMusic('won');
          setShowPoint(true);
          setTimeout(() => {
            setShowPoint(false);
            setCurrentScore(currentScore + 1);
            props.updateCurrentScore(currentScore + 1);
          }, 1000);
          counter = counter + 1;
          props.applaudPlayer();
        }
        setHideObstacle(true);
      }
    }

    // if (blockLeft < 30 && blockLeft > -100 && !RightOrWrong) {
    //   console.log(scoreCountedOnce);
    //   if (!scoreCountedOnce && !lifeCountedOnce) {
    //     console.log("Missed Correct Answer");

    //     console.log("Lives: ", livesLeft);
    //     livesLeft = livesLeft - 1;
    //     setLifeCountedOnce(true);
    //       setLives();
    //       if (livesLeft == 0) {
    //         props.playMusic('lost');
    //         setShowBlast(true);
    //         setInfiniteTimer(null);
    //         character = null;
    //         block = null;
    //         setRunnerJumping(false);
    //         counter = 0;
    //         // setLivesLeft(3);
    //         livesLeft = 3;
    //         setGameLives([1, 1, 1]);
    //         props.stopGame(currentScore);
    //         setTimeout(() => {
    //           setShowBlast(false);
    //         }, 500);
    //       }

    //   }
    // }

    if (blockLeft < -120 && changeOption) {
      setHideObstacle(false);
      setChangeOption(false);
      randomOption();

    }
    if (blockLeft > 300 && !changeOption) {
      setChangeOption(true);
      scoreCountedOnce = false;
    }

  }


  useInterval(() => {
    checkDead();
  }, infiniteTimer);

  // const setLives = () => {
  //   let val = [0, 0, 0];
  //   for (let i = 1; i <= 3; i++) {
  //     if (i <= livesLeft) {
  //       val[i - 1] = 1;
  //     }
  //   }
  //   setGameLives([...val]);
  // }

  return (
    <>
      <div className="game-console" onClick={jump}>
        <div className="game-console-background">
          <img className="background-animation" src={props.gameData["backgroundImage"]} alt="" />
        </div>
        {/* <div className="game-console-path">
          &nbsp;
        </div> */}
        {props.gameStart ? <>
          <div className="game-console-current-score">
            <img src={props.gameData.pointImage} alt="" /> {currentScore}
          </div>
          {/* <div className="game-console-game-lives">
            {
              gameLives.map((val) => {
                return (val == 1 ? <Lottie className="game-console-game-lives-image" animationData={heart} loop={true} /> : <div className="game-console-game-lives-image">&nbsp;</div>)
              })
            }
          </div> */}
          <div className={!runnerJumping ? "game-console-runner" : "game-console-runner runner-jumping"} id="character">
            {getRunner(props.gameData.runnerImage)}
            {/* <img src={props.gameData["runnerImage"]} alt="" /> */}
          </div>
          {showPoint ?
            <div className="game-console-runner bonus-point">
              <img src={props.gameData.pointImage} alt="" />
            </div> : null
          }
          <div className={!hideObstacle ? "game-console-obstacles obstacle-animation" : "game-console-obstacles obstacle-animation hide-obstacle"} id="block">
            {currentOption !== null ? <img src={currentOption} alt="" /> : null}
          </div></> :
          showBlast ? <div className="game-console-runner"><Lottie className="game-console-runner-props" animationData={blast} loop={false} /></div> : null
        }
        {!props.gameStart ? <div className="game-console-start-game-container">
          <div className="game-console-scoreboard-image">
            <img src={props.gameData.scoreboard.image} alt="" />
            <div className="game-console-scorecard-info">
              {/* <div><img src={props.gameData.runnerImage} alt="" /></div> */}
              {/* <div className="game-console-scorecard-title">{props.gameData.gameName}</div> */}
              <div className="game-console-game-info">
                <img src={props.gameData.infoImage} alt="" />
              </div>
              <div className="game-console-scores">
                <div>
                  <div>Highest-Score</div>
                  <div>{props.gameData.highScore}</div>
                </div>
                <div>
                  <div>Score</div>
                  <div>{currentScore}</div>
                </div>
              </div>
            </div>
            <div className="game-console-play-button" onClick={props.restartGame}>
              <img src={props.gameData.scoreboard.playButton} alt="" />
            </div>
          </div>
        </div> : null}
      </div>
    </>
  );
}

export default GameConsoleCatch;