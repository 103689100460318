import React, { useEffect, useState } from "react";
import { isNotEmpty } from '../../../../services/util-service';
import Timer from "../../timer/timer.component";
import './monuments.styles.scss';
const MonumentsComponent = (props) => {

  const [optionsList, setOptionsList] = useState(props.questionDetails.options);
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [timeOver, setTimeOver] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [audioWrong, setAudioWrong] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/countryflag/wrong_answer.mp3'));

  const resetGame = () => {
    setShowQuestion(false);
    setOptionsList(null);
    setCorrectAnswer(false);
    setWrongAnswer(false);
    setTimeOver(false);
    setTimeout(() => {
      setOptionsList(props.questionDetails.options);
      setShowQuestion(true);
    }, 500);
  }

  useEffect(() => {
    resetGame();
  }, [props.questionDetails]);

  const wrongAnswerSelected = () => {
    setWrongAnswer(true);
    if (props.music) {
      audioWrong.play();
    }
  }

  const timeOut = () => {
    setOptionsList(null);
    setShowQuestion(false);
    setTimeOver(true);
    console.log("Timed OUt");
  }

  const correctAnswerSelected = () => {
    setCorrectAnswer(true);
    props.correctAnswer();
  }

  const answerSelected = (ans) => {
    console.log("Answer selected is: ", ans);
    setShowQuestion(false);
    setOptionsList(null);
    // Verify the answer
    if (ans.isCorrect === 1) {
      correctAnswerSelected();
    } else {
      wrongAnswerSelected();
    }
  }

  const playLevelAgain = () => {
    if (props.currentQuestion === 1) {
      resetGame();
    } else {
      props.playAgain();
    }
  }

  const renderView = () => {
    return (
      <div className="monuments-box">
        <div className="monuments-background">
          <img src="https://www.cudents.com/prod/assets/images/games/monuments/game_bg.webp" alt="" />
        </div>

        {wrongAnswer ? <div className="answer-out"><img src="https://www.cudents.com/prod/assets/images/games/monuments/wa.webp" alt="" /><div><div className="game-button" onClick={playLevelAgain}>Play Again</div></div></div> : null}
        {correctAnswer ?
          <div className="answer-out">
            <img src="https://www.cudents.com/prod/assets/images/games/monuments/ca.webp" alt="" />
            {props.nextLevelButton ? <div><div className="game-button" onClick={props.playNextLevel}>Play Level {props.currentLevel + 1}</div></div> : null}
            {props.serverMessage ? <div className="bonus-message">{props.serverMessage}</div> : null}
          </div> : null}
        {timeOver ? <div className="answer-out"><img src="https://www.cudents.com/prod/assets/images/games/monuments/timeover.webp" alt="" /><div><div className="game-button" onClick={playLevelAgain}>Play Again</div></div></div> : null}
        <>
          {showQuestion && !(wrongAnswer || correctAnswer || timeOver) ?
            <div className="game-timer-section">
              <Timer timeOut={timeOut}></Timer>
            </div> : null
          }
          {showQuestion ?
            <div className="game-question-section">
              <span className="game-question">{props.questionDetails.questionText}</span>
              {props.questionDetails.correctOptionExplanation ? <div className="game-question-description">{props.questionDetails.correctOptionExplanation}</div> : null}
            </div> : null}

          {showQuestion ?
            <div className="game-level-number">Level - {props.currentLevel} ({props.currentQuestion}/{props.totalQuestions})</div>
            : null}

          <div className="water-bubble-container">

            {optionsList ?
              <div className="monuments-options-list">
                {
                  optionsList.map((option, index) => (
                    <>
                      {isNotEmpty(option) && index < 4 ?
                        <>
                          <div className={"monuments-option monument-" + (index + 1)} onClick={() => { answerSelected(option) }}><img src={option.imagePath} alt="" /></div>
                        </>
                        : null}
                    </>
                  ))
                }

              </div>
              : null
            }
          </div>
        </>
      </div>
    );
  }

  return (
    <>
      {renderView()}
    </>


  );
}

export default MonumentsComponent;