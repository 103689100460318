import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../app-context';
import { exportPartnerReferrals, getPartnerReferral } from '../../../services/coupon-service';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../services/grid-style';
import Loader from '../../loader/loader.component';
import './partner-referral.styles.scss';

const PartnerReferral = (props) => {
  const [orderList, setReportList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const appContext = useContext(AppContext);

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    getPartnerReferral(selectedPage - 1).subscribe({
      next: (response) => {
        setReportList(response.data.data);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const exportToExcel = (event) => {
    setShowLoader(true);
    exportPartnerReferrals().subscribe({
      next: (response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Referral.xlsx');
        document.body.appendChild(link);
        link.click();
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }});
  }

  const getFormPanel = () => {
    return (
      <form noValidate autoComplete="off">
        <div className="controlBox">
          <div className="ref-Control  sendButton">
            <Button variant="contained" color="primary" onClick={exportToExcel}>
              <GetAppOutlinedIcon /> Export To Excel
            </Button>
          </div>
          <div className="ref-Control "><span className="userLabel">Total Users: </span><span className="userValue">&nbsp;{totalCount}</span></div>
        </div>
      </form>

    );
  }

  const getScoreReportGrid = () => {
    return (
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Display Name</StyledTableCell>
                <StyledTableCell>Class</StyledTableCell>
                <StyledTableCell>User Id</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Validity</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderList.map((referral) => (
                <StyledTableRow key={referral.name}>
                  <StyledTableCell>{referral.name}</StyledTableCell>
                  <StyledTableCell>{referral.displayName}</StyledTableCell>
                  <StyledTableCell>{referral.grade}</StyledTableCell>
                  <StyledTableCell>{referral.userId}</StyledTableCell>
                  <StyledTableCell>{moment(referral.date).format('lll')}</StyledTableCell>
                  <StyledTableCell>{referral.validity}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }

  return (
    <div className="user-list">
      <div className="user-title">Partner Referrals</div>
      {getFormPanel()}
      {showLoader ? <div className="showLoading"><Loader /></div> : getScoreReportGrid()}
    </div>
  );
}

export default PartnerReferral;