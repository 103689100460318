import React from 'react';
import './dashboard-widget.styles.scss';
const DashboardWidget = (props) => {
  return (
    <div className={"user-dashbaord-top-widget-card " + props.color}>
      <div className="top-widget-value">
        {props.value}
      </div>
      <div className="top-widget-bottom">
        <div className="top-widget-label">
          {props.label}
        </div>
        <div className="top-widget-image">
          <img src={props.image} alt="" />
        </div>
      </div>
    </div>
  );
}

export default DashboardWidget;