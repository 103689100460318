import Pagination from '@material-ui/lab/Pagination';
import Lottie from "lottie-react";
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { EMPTY_STRING, NA } from '../../components/constants/constants';
import { getGrades } from '../../services/category-service';
import { getLeaderBoard, getRank } from '../../services/score-service';
import star from "./animations/star.json";
import './leaderboard.styles.scss';

const Leaderboard = () => {

  const [myRank, setMyRank] = useState(null);
  const [leaderboardInfo, setLeaderboardInfo] = useState(null);
  const [toppersInfo, setToppersInfo] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [currentGrade, setCurrentGrade] = useState(null);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const appContext = useContext(AppContext);
  const [showAds, setShowAds] = useState(false);

  useEffect(() => {
    if (gradeList.length === 0) {
      loadGrade();
    }
    if (appContext.userData !== null && currentGrade === null) {
      setCurrentGrade(appContext.userData.grade);
    }
  }, []);

  useEffect(() => {
    if (currentGrade !== null) {
      setToppersInfo([]);
      searchLeaderboard();
    }
  }, [currentGrade]);

  useEffect(() => {
    if (currentGrade !== null) {
      loadLeaderBoard();
    }
  }, [page]);

  const loadGrade = () => {
    getGrades(0).subscribe({
      next: (response) => {
        setGradeList(response.data);
      }
    });
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  const searchLeaderboard = () => {
    loadLeaderBoard();
    loadRank();
  }

  const selectGrade = (grade) => {
    setPage(1);
    setPageCount(1);
    setCurrentGrade(grade);
  }

  const loadLeaderBoard = () => {
    getLeaderBoard(currentGrade, EMPTY_STRING, page - 1).subscribe({
      next: (response) => {
        setLeaderboardInfo(response.data.data);
        // Extract toppers info in the first page
        if (page === 1 && response.data.data.length > 0) {
          let tempToppersInfo = [];
          for (let i = 0; i < 3; i++) {
            if (response.data.data[i]) {
              tempToppersInfo.push(response.data.data[i]);
            }
          }
          setToppersInfo([...tempToppersInfo])
        }
        setPageCount(response.data.totalPages);
      }
    });
  }

  const loadRank = () => {
    getRank(currentGrade, EMPTY_STRING).subscribe({
      next: (response) => {
        setMyRank(response.data);
      }, error: (error) => {
        const tempRank = { ...myRank };
        tempRank.rank = NA;
        tempRank.score = NA;
        setMyRank(tempRank);
      }
    });
  }

  const renderMedal = (rank) => {
    if (rank === 1) {
      return (<img src="/assets/images/leaderboard/gold.png" alt="" />);
    } else if (rank === 2) {
      return (<img src="/assets/images/leaderboard/silver.png" alt="" />);
    } else if (rank === 3) {
      return (<img src="/assets/images/leaderboard/bronze.png" alt="" />);
    } else {
      return (<img src="/assets/images/leaderboard/noMedal.png" alt="" />);
    }

  }

  const rankingFilters = () => {
    if (gradeList.length === 0) {
      return (
        <div className="leaderboard-filters">
          <div className="leaderboard-loading">Loading...</div>
        </div>
      )
    } else {
      return (
        <div className="leaderboard-filters">
          {
            gradeList.map((grade, idx) => (
              <div key={idx} className={grade.grade === currentGrade ? "leaderboard-filter-button active-grade" : "leaderboard-filter-button"} onClick={() => { selectGrade(grade.grade) }}>Class-{grade.grade}</div>
            ))
          }
        </div>
      )
    }
  }

  const rankingBoard = () => {
    if (leaderboardInfo === null) {
      return (
        <div className="leaderboard-toppers">
          {currentGrade === null ? <div className="leaderboard-loading"> <img src="/assets/images/leaderboard/point.png" alt="" />  <div>Select a class to view the leaderboard</div></div>
            :
            <div className="leaderboard-loading">Loading...</div>
          }
        </div>
      )
    } else {
      if (leaderboardInfo.length === 0) {
        return (<div className="leaderboard-no-ranking">No rankings in this class as of now.</div>)
      } else {
        return (
          <>
            {rankingToppers()}
            {rankingPersonal()}
            {rankingList()}
          </>
        )
      }
    }
  }

  const rankingToppers = () => {
    if (toppersInfo.length === 0) {
      return (
        <div className="leaderboard-toppers">
          <div className="leaderboard-loading">Loading...</div>
        </div>
      )
    } else {
      return (
        <div className="leaderboard-toppers">
          {
            toppersInfo.map((rankInfo, idx) => {
              return (
                <div key={idx} className="leaderboard-toppers-card">
                  <div className="toppers-medal">
                    {renderMedal(rankInfo.rank)}
                  </div>
                  <div className="toppers-image">
                    <img src={rankInfo.imgPath} alt="" />
                  </div>

                  {/* <div className="toppers-rank">
                    <span># </span>
                    {rankInfo.rank}
                  </div> */}
                  <div className="toppers-name">
                    {rankInfo.name}
                  </div>
                  {/* <div className="toppers-info">
                    <div className="toppers-quiz"><span>Quizzes </span>{rankInfo.quizCount}</div>
                    <div className="toppers-score"><span>Score </span>{rankInfo.score}</div>
                  </div> */}
                </div>
              )
            }
            )
          }
        </div>
      )
    }
  }

  const rankRow = (rankInfo, showBorder = false, idx) => {
    return (
      <div key={idx} className="leaderboard-rankRow" style={showBorder ? { borderBottom: "1px solid #ccc" } : null}>
        <div className="rankRow-left">
          <div className="leaderboard-rankRow-rank">
            {rankInfo.rank}
          </div>
          <div className="leaderboard-rankRow-image">
            <img src={rankInfo.imgPath} alt="" />
          </div>
          <div className="leaderboard-rankRow-info">
            <div>{rankInfo.name}</div>
            <div className="leaderboard-rankRow-info-details">
              Quizzes: {rankInfo.quizCount}  Score: {rankInfo.score}
            </div>
          </div>
        </div>
        <div className="rankRow-right">
          <div className="leaderboard-rankRow-medal">
            {renderMedal(rankInfo.rank)}
          </div>
        </div>
      </div>
    )
  }
  const rankingPersonal = () => {
    if (myRank === null || myRank.rank === 'NA' || myRank.rank === 'N/A') {
      return null;
    } else {
      return (
        <div className="leaderboard-my-rank">
          <div className="my-rank-image">
            <img src={myRank.imgPath} alt="" />
          </div>
          <div className="my-rank-info">
            <div className="my-rank-name"><span>{myRank.rank}</span>Your Rank</div>
            <div className="my-rank-score"><span>{myRank.score}</span>Your Score</div>
          </div>
        </div>
      )
    }
  }

  const rankingList = () => {
    return (
      <div className="leaderboard-rank-list">
        {leaderboardInfo.map((rankDetails, idx) => rankRow(rankDetails, true, idx))}
        <div className="leaderboard-rank-list-pagination"><Pagination count={pageCount} page={page} onChange={handleChange} variant="outlined" color="secondary" /></div>
      </div>
    )
  }

  return (
    <div className="general-leaderboard-container">
      {/* <Hidden only={['xs', 'sm']}>
          <Grid item xs={0} sm={0} md={4} lg={5} xl={5}>
            <div className="leaderboard-image">
              <img src="/assets/images/leaderboard/leaderboard.png" alt="Leaderboard Banner Image"></img>
            </div>
          </Grid>
        </Hidden> */}
      <div className="leaderboard-page-top">
        <img src="/assets/images/homepage/newhome/newdesign/curve9.png" alt="" />
      </div>
      <div className="leaderboard-page-top-section">
        <Lottie className="leaderboard-page-top-section-animation" animationData={star} loop={true} />
      </div>
      <div className="leaderboard-list-container">
        {rankingFilters()}
        {rankingBoard()}
      </div>

      {/* {showAds ? <><AdComponent></AdComponent></> : null} */}
    </div>
  );

}

export default Leaderboard;