import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../app-context';
import AdminContestPosters from '../../../components/admin/admin-contest-posters/admin-contest-posters.component';
import { getAllContestsAdmin, saveSubscription } from '../../../services/contest-service';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../services/grid-style';
import { completeContestAdmin } from '../../../services/quiz-contest-service';
import { ALL } from '../../constants/constants';
import Loader from '../../loader/loader.component';
import './admin-contest-status.styles.scss';

const AdminContestStatus = (props) => {

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [contestList, setContestList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showPosters, setShowPosters] = useState(false);
  const [contestCode, setContestCode] = useState(false);
  const history = useHistory();
  const appContext = useContext(AppContext);
  const classes = useStyles();

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = () => {
    setShowLoader(true);
    getAllContestsAdmin(ALL, page-1).subscribe({
      next: (result) => {
        setContestList(result.data.content);
        setPageCount(result.data.totalPages);
        setTotalCount(result.data.totalElements);
        setShowLoader(false);
      }});
  }

  const saveSubcriptions = (contestId) => {
    setShowLoader(true);
    saveSubscription(contestId).subscribe({
      next: (response) => {
        setShowLoader(false);
        appContext.setDialogMessage(response.message);
        search();
      }, error: (error) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }});
  }

  const completeContest = (contestId) => {
    setShowLoader(true);
    completeContestAdmin(contestId).subscribe({
      next: (response) => {
        setShowLoader(false);
        appContext.setSuccessMessage(response.message);
        search();
      }, error: (error) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }});
  }

  const getContestsGrid = () => {
    return (
      <div>
        <span className='search-total'>Total : {totalCount}</span>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Contest Id</StyledTableCell>
                <StyledTableCell>Contest Code</StyledTableCell>
                <StyledTableCell>Institution Name</StyledTableCell>
                <StyledTableCell>Start Date</StyledTableCell>
                <StyledTableCell>End Date</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
                <StyledTableCell>Poster</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contestList.map((contest) => (
                <StyledTableRow key={contest.contestId}>
                  <StyledTableCell>{contest.contestId}</StyledTableCell>
                  <StyledTableCell>{contest.contestCode}</StyledTableCell>
                  <StyledTableCell>{contest.institutionName}</StyledTableCell>
                  <StyledTableCell>{moment(contest.startDate).format('lll')}</StyledTableCell>
                  <StyledTableCell>{moment(contest.endDate).format('lll')}</StyledTableCell>
                  <StyledTableCell>{contest.status}</StyledTableCell>
                  <StyledTableCell>{contest.status === 'Ended' ?
                    <Button onClick={() => { completeContest(contest.contestId) }} variant="contained" color="primary">
                      Complete
                    </Button>
                    : null}
                    {contest.status === 'Completed' && contest.isSubscriptionAdded !== 1 ?
                      <div className='contest-status-buttons'>
                        <Button onClick={() => { saveSubcriptions(contest.contestId) }} variant="contained" color="secondary">
                          Save Subscription
                        </Button>
                      </div>
                      : null}</StyledTableCell>
                  <StyledTableCell>
                    {contest.status === 'Completed' ? <Button onClick={() => { setContestCode(contest.contestCode); setShowPosters(true) }} variant="contained" color="primary">
                      Posters
                    </Button>
                      : null}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }

  return (
    <div className="contest-status-main">
      <div className="contest-status-title">Contest Status</div>
      {showLoader ? <div className="showLoading"><Loader /></div> : showPosters ? <AdminContestPosters back={() => {setShowPosters(false)}} contestCode={contestCode}></AdminContestPosters> : getContestsGrid()}
    </div>
  );
}

export default AdminContestStatus;