import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { isNull } from '../../../services/util-service';
import { GAME_TYPE_MATCH, GAME_TYPE_SEARCH, GAME_TYPE_SELECT, GAME_TYPE_SEQUENCE } from '../game-constants';
import './filter-dialog.styles.scss';
import InfiniteGameFilter from './infinite-game-filter.component';
import SelectGameFilter from './select-game-filter.component';

export const GameFilterDialog = (props) => {
  
  const game = props.game;
  const grade = props.grade;

  return (
    <Dialog maxWidth={false} {...props}
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }}>
      {isNull(game) ? <></> : (game.type === GAME_TYPE_SELECT || game.type === GAME_TYPE_SEQUENCE|| game.type === GAME_TYPE_SEARCH || game.type === GAME_TYPE_MATCH) ? 
      <SelectGameFilter onClose={() => { props.onClose() }} game={game} grade={grade} goToGame={props.goToGame}></SelectGameFilter> :
        <InfiniteGameFilter onClose={() => { props.onClose() }} game={game} grade={grade} goToGame={props.goToGame}></InfiniteGameFilter>}
    </Dialog>
  );
}

export default GameFilterDialog;