import * as gameList from "../../../components/games/game-constants";
import ChickenEggsComponent from "../../../components/games/chicken-eggs/chicken-eggs-component/chicken-eggs.component";
import MultiplicationGameComponent from "../../../components/games/multiplication/multiplication-game-component/multiplication-game.component";
import CountryFlagComponent from "../../../components/games/country-flag/country-flag-component/country-flag.component";
import DraculaComponent from "../../../components/games/dracula/dracula-component/dracula.component";
import SaveTheEarthComponent from "../../../components/games/save-the-earth/save-the-earth-component/save-the-earth.component";
import MonumentsComponent from "../../../components/games/monuments/monuments-component/monuments.component";
import WindowBlindsComponent from "../../../components/games/window-blinds/window-blinds/window-blinds.component";
import WatchItComponent from "../../../components/games/watch-it/watch-it-component/watch-it.component";
import CorrectSequenceGame from "../../../kg-games/the-correct-sequence/correct-sequence-game.component";
import SearchMaster from "../../../kg-games/search-master/search-master.component";
import MatchGame from "../../../kg-games/match-game/match-game.component";


const getGameComponent = (props) => {
  if(gameList.GAME_TYPE_SEQUENCE === props.type){
    return (<CorrectSequenceGame {...props}></CorrectSequenceGame>);
  }else  if(gameList.GAME_TYPE_SEARCH === props.type){
    return (<SearchMaster {...props}></SearchMaster>);
  }else  if(gameList.GAME_TYPE_MATCH === props.type){
    return (<MatchGame {...props}></MatchGame>);
  }else{
    switch (props.name) {
      case gameList.CHICKEN_EGGS:
        return (<ChickenEggsComponent {...props}></ChickenEggsComponent>);
      case gameList.MISSING_TREASURE:
        return (<MultiplicationGameComponent {...props}></MultiplicationGameComponent>);
      case gameList.COUNTRY_FLAG:
        return (<CountryFlagComponent {...props}></CountryFlagComponent>);
      case gameList.DRACULA:
        return (<DraculaComponent {...props}></DraculaComponent>);
      case gameList.SAVE_THE_EARTH:
        return (<SaveTheEarthComponent {...props}></SaveTheEarthComponent>);
      case gameList.MONUMENTS:
        return (<MonumentsComponent {...props}></MonumentsComponent>);
      case gameList.WINDOW_BLINDS:
        return (<WindowBlindsComponent {...props}></WindowBlindsComponent>);
      case gameList.LOST_AND_FOUND:
        return (<WatchItComponent {...props}></WatchItComponent>);
      default:
        break;
    }
  }
  
}


export default getGameComponent;