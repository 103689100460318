import { Button, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../app-context';
import { saveSubscriptionAdmin } from '../../../services/subscription-service';
import { EMPTY_STRING, MANDATORY_FIELD_MSG } from '../../constants/constants';
import Loader from '../../loader/loader.component';
import './subscription-payment.styles.scss';

const FreeSubscriptionComponent = (props) => {
  
  const initForm = {userId: EMPTY_STRING, packageId: EMPTY_STRING, isRedeemable: EMPTY_STRING};
  const [formData, updateFormData] = useState(initForm);
  const [showLoader, setShowLoader] = useState(false);
  const appContext = useContext(AppContext);
  const [formError, setFormError] = useState({});

  const subscribe = () => {
    if (handleValidation()) {
      setShowLoader(true);
      saveSubscriptionAdmin(formData.userId, formData.packageId, formData.isRedeemable).subscribe({
        next: (response) => {
          appContext.setDialogMessage(response.message);
          setShowLoader(false);
        }, error : (error ) => {
          setShowLoader(false);
          appContext.setDialogMessage(error.message);
        }});
    }
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["userId"]) {
      formIsValid = false;
      errors["userId"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["packageId"]) {
      formIsValid = false;
      errors["packageId"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["isRedeemable"]) {
      formIsValid = false;
      errors["isRedeemable"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const getFreeSubscriptionGrid = () => {
    return (
      <div>
        <div className="formField">
        <div className="formField"><TextField type="string" name="userId" value={formData.userId} onChange={handleChange} fullWidth label="User Identification" variant="outlined" size="small" />
        <div className="error-text">{formError['userId']}</div></div>
        <div className="formField"><TextField type="number" name="packageId" value={formData.packageId} onChange={handleChange} fullWidth label="Package Id" variant="outlined" size="small" />
        <div className="error-text">{formError['packageId']}</div></div>
        <div className="formField"><TextField type="number" name="isRedeemable" value={formData.isRedeemable} onChange={handleChange} fullWidth label="Redeemable?" variant="outlined" size="small" />
        <div className="error-text">{formError['isRedeemable']}</div></div>
          <Button variant="contained" onClick={subscribe} color="secondary">
            Submit
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="referral">
      {showLoader ? <div className="showLoading"><Loader /></div> : getFreeSubscriptionGrid()}
    </div>
  );
}

export default FreeSubscriptionComponent;