import { Dialog, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../app-context';
import { EMPTY_STRING, MANDATORY_FIELD_MSG, STATES } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import { saveRequestContest } from '../../services/request-contest-service';
import './request-contest.styles.scss';

const RequestContest = (props) => {
  const initData = {
    requestorName: EMPTY_STRING, schoolName: EMPTY_STRING, email: EMPTY_STRING, phone: EMPTY_STRING,
    state: EMPTY_STRING, city: EMPTY_STRING
  };
  const [formData, updateFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const appContext = useContext(AppContext);
  const [showLoader, setShowLoader] = useState(false);

  const savedata = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setShowLoader(true);
      saveRequestContest(formData).subscribe({
        next: (response) => {
          updateFormData(initData);
          appContext.setSuccessMessage(response.message);
          setShowLoader(false);
          props.onClose();
        }, error : (error ) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }});
    }
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["requestorName"]) {
      formIsValid = false;
      errors["requestorName"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["schoolName"]) {
      formIsValid = false;
      errors["schoolName"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["email"]) {
      formIsValid = false;
      errors["email"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["phone"]) {
      formIsValid = false;
      errors["phone"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["city"]) {
      formIsValid = false;
      errors["city"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["state"]) {
      formIsValid = false;
      errors["state"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const renderContact = () => {
    return (
      <div className="requestFormContainer">
        <div className="requestTitle">Request For Free Contest</div>
        <div className="requestForm">
          <form noValidate autoComplete="off" onSubmit={savedata}>
            <div className="formField">
              <TextField name="schoolName" value={formData.schoolName} onChange={handleChange} required fullWidth label="School/Institution's Name" size="small" variant="outlined" />
              <span className="error-text">{formError["schoolName"]}</span>
            </div>
            <div className="formField">
              <TextField name="requestorName" value={formData.requestorName} onChange={handleChange} required fullWidth label="Contact Person's Name" size="small" variant="outlined" />
              <span className="error-text">{formError["requestorName"]}</span>
            </div>
            <div className="formField">
              <TextField type="email" name="email" value={formData.email} onChange={handleChange} required fullWidth label="Email Id" variant="outlined" size="small" />
              <span className="error-text">{formError["email"]}</span>
            </div>
            <div className="formField">
              <TextField name="phone" label="Mobile" fullWidth required value={formData.phone} onChange={handleChange}  variant="outlined" size="small" />
              <span className="error-text">{formError["phone"]}</span>
            </div>
            <div className="formField">
              <TextField name="city" value={formData.city || EMPTY_STRING} onChange={handleChange} required fullWidth label="City" variant="outlined" size="small" />
              <span className="error-text">{formError["city"]}</span>
            </div>
            <div className="formField">
              <TextField name="state" value={formData.state || EMPTY_STRING} select label="State" onChange={handleChange} required fullWidth variant="outlined" size="small">
                {STATES.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
              <span className="error-text">{formError["state"]}</span>
            </div>
            <div className="formField sendButton">
              {showLoader ? <div className="showLoading"><Loader /></div> : <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>}

            </div>
          </form>
        </div>
      </div>
    );
  }
  return (
    <Dialog maxWidth={false} {...props}>
      <div className="requestContainer">
        {renderContact()}
      </div>
    </Dialog>
  );
}

export default RequestContest;