import React from 'react';

const AdminDashboardChart = (props) => {
  const canvasRef = React.useRef();
  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        ticks: {
          min: 10,
          max: 100,
          stepSize: 20,
          callback: function (value) {
            return value; 
          },
        },
      },
    },
  };

  return (
    <>
      {/* <Bar data={props.data} options={options} ref={canvasRef} /> */}
    </>
  );

};

export default AdminDashboardChart;