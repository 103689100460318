import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import Lottie from "lottie-react";
import moment from 'moment';
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../app-context";
import { GRADE_LIST } from "../../components/constants/constants";
import Loader from "../../components/loader/loader.component";
import { findGKByDate } from "../../services/gk-service";
import { logService } from "../../services/logging-service";
import { isEmpty, isNotEmpty, isNull, replaceUnderscore, Rif, speakTheText } from '../../services/util-service';
import brainbulb from "./animations/brainbulb.json";
import './daily-gk.styles.scss';
function DailyGk(props) {

  const grade = props.match.params.grade;
  const gkDate = props.match.params.gkDate;
  const history = useHistory();
  const [showAnswer, setShowAnswer] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [quizDetails, setQuizDetails] = useState(null);
  const [ansDetails, setAnsDetails] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(0);
  const appContext = useContext(AppContext);
  const currUser = appContext.userData;
  const currGrade = isNull(grade) ? currUser.grade: grade;
  const [currentGrade, setCurrentGrade] = useState(currGrade);

  useEffect(() => {
    if (currentGrade != null) {
      loadGK();
    }
  }, [currentGrade]);

  const loadGK = () => {
    setShowLoader(true)
    let date = gkDate ? gkDate : new Date();
    date = moment(date).format('DD/MM/yyyy')
    const grade = currentGrade < 0 ? -1 : currentGrade
    findGKByDate(grade, date).subscribe({
      next: (response) => {
        setQuizData(response.data);
        setQuizDetails(response.data.questions);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
        history.goBack();
      }
    });
    logService("GK", date).subscribe({
      next: (response) => {
      }, error: (error) => {
        console.log(error.message);
      }
    });
  }


  useEffect(() => {
    if (quizDetails !== null) {
      initializeAnswerList(quizDetails);
    }
  }, [quizDetails]);

  const initializeAnswerList = (qData) => {
    const tempAns = [];
    qData.map(ques => {
      tempAns.push({
        questionId: ques.rowId,
        isOption1Marked: 0,
        isOption2Marked: 0,
        isOption3Marked: 0,
        isOption4Marked: 0,
        isOption5Marked: 0,
        answerSelected: false
      });
    });
    setAnsDetails(tempAns);
  }

  const onSubmit = (ansDetails) => {
    setShowAnswer(true);
    // send the quiz answers to server and get the result back.
    // clear the state once result has come back.
    // show the score card and on close take back to quizzes list page.
    // const data = { models: ansDetails }
    // setShowLoader(true);
    // saveAllAttempts(data).subscribe({
    //   next: (response) => {
    //     setShowLoader(false);
    //   }, error : (error ) => {
    //     setShowLoader(false);
    //   }});
  }

  const handleChange = (event) => {
    setShowAnswer(false);
    setCurrentGrade(event.target.value);
  };

  const onPrevious = () => {
    setCurrentPosition(currentPosition - 1);
  }

  const onNext = () => {
    setCurrentPosition(currentPosition + 1);
  }


  const onAnswerSelection = (ansSelected) => {
    const tempAns = [...ansDetails];
    const currUser = appContext.userData;
    const newData = {
      ...tempAns[currentPosition],
      userId: currUser.rowId,
      isOption1Marked: ansSelected === 'isOption1Marked' ? 1 : 0,
      isOption2Marked: ansSelected === 'isOption2Marked' ? 1 : 0,
      isOption3Marked: ansSelected === 'isOption3Marked' ? 1 : 0,
      isOption4Marked: ansSelected === 'isOption4Marked' ? 1 : 0,
      isOption5Marked: ansSelected === 'isOption5Marked' ? 1 : 0,
      answerSelected: true
    };
    tempAns[currentPosition] = newData;
    setAnsDetails(tempAns);
    setQuizDetails(quizDetails);
  }

  const previousButton = () => {
    if ((currentPosition) !== 0) {
      return (
        <span className="next-prev-button">
          <img src="/assets/images/quiz/previous.png" onClick={onPrevious} alt="" />
        </span>
      );
    }
  }

  const submitNextButton = () => {
    if ((currentPosition + 1) !== ansDetails.length) {
      return (
        <span className="next-prev-button">
          {ansDetails[currentPosition].answerSelected ?
            <img src="/assets/images/quiz/next.png" onClick={onNext} alt="" />
            :
            <img src="/assets/images/quiz/next_disabled.png" alt="" />
          }
        </span>
      );
    } else {
      return (
        <span className="next-prev-button">
          {ansDetails[currentPosition].answerSelected ?
            <img src="/assets/images/quiz/submit.png" onClick={() => { onSubmit(ansDetails) }} alt="" />
            :
            <img src="/assets/images/quiz/submit_disabled.png" alt="" />
          }
        </span>
      );
    }
  }

  const gradeFilters = () => {
    if (GRADE_LIST.length === 0) {
      return (
        <div className="dailygk-filters">
          <div className="dailygk-loading">Loading...</div>
        </div>
      )
    } else {
      return (
        <div className="dailygk-filters">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-standard-label">Class/Grade</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={currentGrade}
              onChange={handleChange}
              label="Grade"
            >
              {
                GRADE_LIST.map((grade) => (
                  <MenuItem key={grade.value} value={grade.value}>{grade.name}</MenuItem>
                ))
              }

            </Select>
          </FormControl>
        </div>
      )
    }
  }

  const getAnswerClass = (answerOptionSelected, answerOption, left) => {
    // Return the color class for answer: Purple if selected, Green for correct
    let answerClass = left ? 'dailygk-option-value left-border' : 'dailygk-option-value right-border';
    if (ansDetails[currentPosition][answerOptionSelected] === 1) {
      answerClass = left ? 'dailygk-option-value left-border  dailygk-wrong-answer' : 'dailygk-option-value right-border  dailygk-wrong-answer';
    }
    if (showAnswer && quizDetails[currentPosition][answerOption] === 1) {
      answerClass = left ? 'dailygk-option-value left-border  dailygk-right-answer' : 'dailygk-option-value right-border  dailygk-right-answer';
    }
    return answerClass;
  }


  const renderPage = () => {
    return (<>
      {quizDetails === null && isNotEmpty(currentGrade) ?
        <div className="dailygk-searching">
          <div><img src="/assets/images/dailygk/searching.png" alt="" /></div>
          No question for the day.</div>
        : null}
        {quizDetails !== null ?
        <>
          {/* <div className="quiz-subject-details">
                <div>{quizData.topic}</div>
              </div> */}
          <div className="dailygk-number">{currentPosition + 1} / {quizDetails.length}</div>
          {quizData.grade < 3 ?
            <div className="dailygk-audio">
              <VolumeUpIcon className="icon" onClick={() => { speakTheText(replaceUnderscore(quizDetails[currentPosition].questionText)) }} />
            </div> : null}
            <div> {moment(quizDetails[currentPosition].gkDate).format('Do MMMM yyyy')}</div>
          <div className="dailygk-top-section">
            <div className="dailygk-question">{quizDetails[currentPosition].questionText}
            </div>
            <div className="dailygk-image">
              <Rif boolean={isNotEmpty(quizDetails[currentPosition].questionImagePath)}>
                <img src={quizDetails[currentPosition].questionImagePath} alt="Q" />
              </Rif>
            </div>
          </div>

          <div className="dailygk-options">
            <div className="dailygk-option-row">
              <Rif boolean={isNotEmpty(quizDetails[currentPosition].option1Text) || isNotEmpty(quizDetails[currentPosition].option1ImagePath)}>
                <div className={getAnswerClass('isOption1Marked', 'isOption1Correct', false)} onClick={() => { onAnswerSelection('isOption1Marked') }}> {quizDetails[currentPosition].option1Text}
                  <Rif boolean={isNotEmpty(quizDetails[currentPosition].option1ImagePath)}>
                    <img src={quizDetails[currentPosition].option1ImagePath} alt="O" />
                  </Rif>
                </div>
              </Rif>
              <Rif boolean={isNotEmpty(quizDetails[currentPosition].option2Text) || isNotEmpty(quizDetails[currentPosition].option2ImagePath)}>
                <div className={getAnswerClass('isOption2Marked', 'isOption2Correct', true)} onClick={() => { onAnswerSelection('isOption2Marked') }}> {quizDetails[currentPosition].option2Text}
                  <Rif boolean={isNotEmpty(quizDetails[currentPosition].option2ImagePath)}>
                    <img src={quizDetails[currentPosition].option2ImagePath} alt="O" />
                  </Rif>
                </div>
              </Rif>
            </div>
            <div className="dailygk-option-row">
              <Rif boolean={isNotEmpty(quizDetails[currentPosition].option3Text) || isNotEmpty(quizDetails[currentPosition].option3ImagePath)}>
                <div className={getAnswerClass('isOption3Marked', 'isOption3Correct', false)} onClick={() => { onAnswerSelection('isOption3Marked') }}> {quizDetails[currentPosition].option3Text}
                  <Rif boolean={isNotEmpty(quizDetails[currentPosition].option3ImagePath)}>
                    <img src={quizDetails[currentPosition].option3ImagePath} alt="O" />
                  </Rif>
                </div>
              </Rif>
              <Rif boolean={isNotEmpty(quizDetails[currentPosition].option4Text) || isNotEmpty(quizDetails[currentPosition].option4ImagePath)}>
                <div className={getAnswerClass('isOption4Marked', 'isOption4Correct', true)} onClick={() => { onAnswerSelection('isOption4Marked') }}> {quizDetails[currentPosition].option4Text}
                  <Rif boolean={isNotEmpty(quizDetails[currentPosition].option4ImagePath)}>
                    <img src={quizDetails[currentPosition].option4ImagePath} alt="O" />
                  </Rif>
                </div></Rif>
            </div>
          </div>
          <div className="dailygk-buttons">
            {previousButton()}{submitNextButton()}
          </div></> : null
      }
      {isNotEmpty(currentGrade) ?
        <div className="dailygk-history-button">
          <span onClick={() => { history.push(`/dailygkhistory/${currentGrade}`); }}>Previous Questions</span>
        </div>
        : null}
    </>);
  }
  const renderGK = () => {
    return (<>

      <div className="dailygk-page">
        <div className="dailygk-top-animation">
          <Lottie className="dailygk-page-top-animation" animationData={brainbulb} loop={true} />
        </div>
        {gradeFilters()}
        {isEmpty(currentGrade)? <div className="dailygk-loading">
          <img src="/assets/images/leaderboard/point.png" alt="" />
          <div>Select a class/grade</div>
        </div>
          : null}
        {showLoader ? <div className="showLoading">< Loader /></div> :
          <>{renderPage()}</>}

      </div>
    </>
    )
  }

  return (<>
      <div className="quiz-container-common">
        {/* {quizDetails !== null ? renderQuiz() : <div>Loading...</div>} */}
        {renderGK()}
      </div>
    </>
  );
}

export default DailyGk;