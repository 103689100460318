import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import Loader from "../../../components/loader/loader.component";
import { findAllGuessMaster, findGuessMasterSubjects } from "../../../services/guess-master-service";
import { logService } from "../../../services/logging-service";
import faq from "../animations/faq.json";
import './guess-master-main.styles.scss';
function GuessMasterMain(props) {

    const history = useHistory();
    const [showLoader, setShowLoader] = useState(true);
    const [guessData, setGuessData] = useState(null);

    useEffect(() => {
        if (guessData === null) {
            setShowLoader(true);
            findAllGuessMaster().subscribe({
                next: (response) => {
                    setGuessData(response.data);
                    setShowLoader(false);
                }, error: (error) => {
                    setShowLoader(false);
                }
            });
        }
    }, []);

    
  const logGameService = (topic) => {
    logService("Guess Master", `Topic-${topic}`).subscribe({
      next: (response) => {
      }, error: (error) => {
        console.log(error.message);
      }
    });
  }

  const openPlayer = (data) => {
    logGameService(data.topic);
    history.push(`/guessPlayer/${data.rowId}`);
  }

    return (<>{showLoader ? <div className="showLoading">< Loader /></div>
        :
        <div className="guess-master-main-page">
            <div className="guess-master-main-top-image">
                <img src="/assets/images/homepage/newhome/newdesign/curve4.png" alt="" />
            </div>
            <div className="guess-master-main-top-section">
                <div className="guess-master-main-animation"> <Lottie animationData={faq} loop={true} /></div>
            </div>
            <div className="guess-master-main-card-list">
                {
                    guessData.map((data, idx) => (
                        <div key={idx} className="guess-master-main-card" onClick={() => { openPlayer(data) }}> 
                            {data.isFree === 1 ? null :
                                <div className="premium-icon"><img src="/assets/images/homepage/newhome/icons/crown.png" alt="" /></div>
                            }
                            <div className="guess-master-main-card-image">
                                <img src={data.topicImagePath} alt="" />
                           
                            </div>
                            <div className="guess-master-main-card-title">
                                {data.topic}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    }
    </>);
}

export default GuessMasterMain;