import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../services/grid-style';
import { getPartnerSales } from '../../../services/payment-service';
import Loader from '../../loader/loader.component';
import './partner-sales.styles.scss';

const PartnerSales = (props) => {
  const [orderList, setReportList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    getPartnerSales(selectedPage - 1).subscribe({
      next: (response) => {
        setReportList(response.data.data);
          setPageCount(response.data.totalPages);
          setTotalCount(response.data.totalElements);
          setShowLoader(false);
        }, error : (error ) => {
          setShowLoader(false);
      }});
  }

  const getScoreReportGrid = () => {
    return (
      <div>
           <div className="partner-sales"><span className="userLabel">Total Users: </span><span className="userValue">&nbsp;{totalCount}</span></div>
      <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Display Name</StyledTableCell>
                <StyledTableCell>User Id</StyledTableCell>
                <StyledTableCell>Package Name</StyledTableCell>
                <StyledTableCell>Package Price</StyledTableCell>
                <StyledTableCell>Coupon Code</StyledTableCell>
                <StyledTableCell>Coupon Discount</StyledTableCell>
                <StyledTableCell>Discounted Price</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Coupon Primary Owner</StyledTableCell>
                <StyledTableCell>Coupon Secondary Owner</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderList.map((user) => (
                <StyledTableRow key={user.name}>
                  <StyledTableCell>{user.name}</StyledTableCell>
                  <StyledTableCell>{user.displayName}</StyledTableCell>
                  <StyledTableCell>{user.userId}</StyledTableCell>
                  <StyledTableCell>{user.packageName}</StyledTableCell>
                  <StyledTableCell>{user.packagePrice}</StyledTableCell>
                  <StyledTableCell>{user.couponCode}</StyledTableCell>
                  <StyledTableCell>{user.couponDiscount}</StyledTableCell>
                  <StyledTableCell>{user.discountedPrice}</StyledTableCell>
                  <StyledTableCell>{moment(user.date).format('lll')}</StyledTableCell>
                  <StyledTableCell>{user.couponPrimaryOwner}</StyledTableCell>
                  <StyledTableCell>{user.couponSecondaryOwner}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }


  return (
    <div className="sales-list">
      <div className="sales-title">Partner Sales</div>
      {showLoader ? <div className="showLoading"><Loader /></div> : getScoreReportGrid()}
    </div>
  );
}

export default PartnerSales;