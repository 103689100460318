
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SubtitlesOutlinedIcon from '@material-ui/icons/SubtitlesOutlined';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { VIDEO_TYPE_LEARNING, VIDEO_TYPE_RHYME } from '../../components/constants/constants';
import { CLASS_TYPE_PRESCHOOL, CLASS_TYPE_PRIMARY } from '../../components/games/game-constants';
import FeatureCard from '../../feature-card/feature-card.component';
import { getStats } from '../../services/dashboard-service';
import GameSection from '../home-page/game-section/game-section.component';
import GuessMasterSection from '../home-page/guess-master/guess-master';
import QuizSection from '../home-page/quiz-section/quiz-section.component';
import VideoSection from '../home-page/video-section/video-section.component';
import './web-home-page.styles.scss';

const WebHomePage = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };

  const features = [
    {
      image: 'https://www.cudents.com/assets/images/homepage/game.webp',
      title: 'Gamified Format',
      info: 'Progressive levels, Stickers, Badges, Leaderboard, Dynamic points and Adventure zones to keep the kids engaged while learning'
    },
    {
      image: 'https://www.cudents.com/assets/images/homepage/organized.webp',
      title: 'Organized Content',
      info: 'Class, Subject and Topic wise categorization helps search and attempt the Quizzes easily'
    },
    {
      image: 'https://www.cudents.com/assets/images/homepage/performance.webp',
      title: 'Student Dashboard',
      info: 'Graphical and insightful representation of scores to monitor learning development'
    },
    {
      image: 'https://www.cudents.com/assets/images/homepage/quiz.webp',
      title: 'Curated Quizzes',
      info: 'Meticulously designed questions by experienced teachers to cover entire gamut of learning'
    },
    {
      image: 'https://www.cudents.com/assets/images/homepage/flexible.webp',
      title: 'Flexible Packages',
      info: 'Options to choose from multiple duration based subscription packages'
    // },
    // {
    //   image: 'https://www.cudents.com/assets/images/homepage/redeem.webp',
    //   title: 'Points Redemption',
    //   info: 'Earn and redeem points through a variety of options which inculcate a sense of achievement in the kids'
    }
  ]
  const [stats, setStats] = useState(null);

  useEffect(() => {
    if (stats === null) {
      fetchStats();
    }
  }, []);

  const fetchStats = () => {
    getStats().subscribe({
      next: (response) => {
        setStats(response.data);
      }
    });
  }

  const homePage = () => {
    return <div className="home-page-content-area">
      <div className="home-page-logo"><img src="https://www.cudents.com/assets/images/site/logo.png" alt="" /></div>
      <div className="topMainBar">
        <div className="bottom-curve">
          <img src="/assets/images/homepage/bottomCurve.webp" alt="" />
        </div>
        <div className="middleArea">
          <div className="infoImage"><img src="https://www.cudents.com/assets/images/homepage/astro1.webp" alt="" /></div>
          <div className="title">A learning and Engagement Platform For Kids</div>
          <div className="tagLine">Educational Games | Quizzes | Rhymes | Stories | Riddles | GK</div>
          <div className="try-buttons">
            <a href={process.env.REACT_APP_PLAYSTORE_URL}><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
          </div>
        </div>
        <div className="rocket"><img src="https://www.cudents.com/assets/images/homepage/rocket.webp" alt="" /></div>
        <div className="mainBarBottomArea">
          <div><img className="leftImage" src="https://www.cudents.com/assets/images/homepage/leftpic.webp" alt="" /></div>
          <div className="mobileImage" ><img src="/assets/images/homepage/mobile.png" alt="" /></div>
        </div>
      </div>
      <div className="infoSection">
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          keyBoardControl={true}
          transitionDuration={500}
          renderButtonGroupOutside={true}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          slidesToSlide={1}
        >
          {
            features.map(feature => (
              <div><FeatureCard {...feature}></FeatureCard></div>
            ))
          }
        </Carousel>
      </div>
      {stats !== null ?
        <div className='site-stats'>
          <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className='stat-value'><div className='stats-icon'><PersonOutlineOutlinedIcon></PersonOutlineOutlinedIcon></div>{Math.floor(stats.students / 100) * 100}+ <div>Students</div></div>
          <div className='stat-value'><div className='stats-icon'><SubtitlesOutlinedIcon></SubtitlesOutlinedIcon></div>{Math.floor(stats.topics / 10) * 10}+ <div>Topics</div></div>
          <div className='stat-value'><div className='stats-icon'><LiveHelpOutlinedIcon></LiveHelpOutlinedIcon></div>{Math.floor(stats.questions / 100) * 100}+ <div>Questions</div></div>
          <div className='stat-value'><div className='stats-icon'><AssignmentTurnedInOutlinedIcon></AssignmentTurnedInOutlinedIcon></div>{Math.floor(stats.attempts / 100) * 100}+ <div>Attempts</div></div>
        </div>
        : null}
      {/* Listing of grades for Quizzes */}
      <div className="subject-list">
        <div className="grade-title">
          Quizzes
        </div>
        <QuizSection></QuizSection>
      </div>

      {/* Listing of pre-school class games */}
      <div className="subject-list">
        <div className="grade-title">
          Pre-School Games
        </div>
        <GameSection classType={CLASS_TYPE_PRESCHOOL}></GameSection>
      </div>


      {/* Listing of primary class games */}
      <div className="subject-list">
        <div className="grade-title">
          Primary Games
        </div>
        <GameSection classType={CLASS_TYPE_PRIMARY}></GameSection>
      </div>

      <div className="subject-list">
        <div className="grade-title">
          Rhymes
        </div>
        <VideoSection videoType={VIDEO_TYPE_RHYME}></VideoSection>
      </div>
      {/* {showAds ? <><AdComponent></AdComponent></> : null} */}
      <div className="subject-list">
        <div className="grade-title">
          Learning
        </div>
        <div>
          <VideoSection videoType={VIDEO_TYPE_LEARNING}></VideoSection>
        </div>
      </div>
      {/* {showAds ? <><AdComponent></AdComponent></> : null} */}
      {/* <div className="subject-list">
        <div className="grade-title">
          Stories
        </div>
        <div>
          <VideoSection videoType={VIDEO_TYPE_STORY}></VideoSection>
        </div>
      </div> */}

      <div className="subject-list">
        <div className="grade-title">
          Guess Master
        </div>
        <GuessMasterSection></GuessMasterSection>
      </div>

    </div>
  }

  return (
    <div className="App webhomePage">
      {homePage()}
      {/* <div className="coming-soon"><img src="/assets/images/site/back-soon.png" alt="" /></div> */}
    </div>
  );
}

export default WebHomePage;
