import { Button, FormControl, Grid, Switch, TextField } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../app-context';
import { approveRiddles, findAllRiddle, saveRiddle } from '../../../services/riddle-service';
import { isEmpty } from '../../../services/util-service';
import { EMPTY_STRING, ROLE_ADMIN, ROLE_USER } from '../../constants/constants';
import Loader from '../../loader/loader.component';
import YesNoDialog from '../../message-dialog/yes-no-dialog.component';
import DateComponent from '../date/date.component';
import './admin-riddle.styles.scss';
const AdminRiddle = () => {

  const [showLoader, setShowLoader] = useState(false);
  const appContext = useContext(AppContext);
  const [riddleList, setRiddleList] = useState([]);
  const [showMessageDialog, setShowMsgDialog] = useState(false);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [page, setPage] = useState(1);
  const [isReviewed, setIsReviewed] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const initFormData = {
    riddleDate: null,
    question: EMPTY_STRING,
    answer: EMPTY_STRING,
    description: EMPTY_STRING,
    language: 'English',
    isActive: 0,
    isFree: 1
  };
  const [userRole, setRole] = useState(ROLE_USER);
  const [formData, updateFormData] = useState(initFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (appContext.userData) {
      setRole(appContext.userData.role);
    }
  }, [appContext]);

  useEffect(() => {
    if (appContext.userData) {
      getAllRiddles(isReviewed, page);
    }
  }, [page]);

  const onSubmit = (event) => {
    setShowLoader(true);
    if (isValidQuestion()) {
      saveRiddle(formData).subscribe({
        next: (response) => {
          getAllRiddles(isReviewed, 1);
          updateFormData(initFormData);
          appContext.setMessage(response.message);
        }, error: (error) => {
          setShowLoader(false);
        }
      });
    }
  }

  const handleToggle = (e) => {
    setIsReviewed(e.target.checked);
    setPage(1);
    getAllRiddles(e.target.checked, 1)
  };

  const getAllRiddles = (isReviewed, pageNumber) => {
    const reviewed = isReviewed === true ? 1 : 0;
    setShowLoader(true);
    findAllRiddle(reviewed, pageNumber-1).subscribe({
      next: (response) => {
        setRiddleList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const isValidQuestion = () => {
    let valid = false;
    if (isEmpty(formData.question)) {
      appContext.setDialogMessage('Please add \'Question\' to add the Riddle');
      valid = false;
    } else {
      valid = true;
    }
    return valid;
  }

  const onApprove = () => {
    setMessage('Do you want to approve this level ?');
    setShowMsgDialog(true);
  }

  const onOkClick = () => {
    approveRiddles().subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
        getAllRiddles(true, 1);
        setIsReviewed(true);
      }});
  }

  const onEditQuestion = (event) => {
    updateFormData(event);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const headerFormPanel = () => {
    return <div className="header-panel">
      <div className="header-field">
        <DateComponent name="riddleDate" label="Riddle Date" onDateSelection={handleChange} value={formData.riddleDate || EMPTY_STRING}
          defaultDate={formData.riddleDate ? moment.utc(formData.riddleDate) : null} variant="outlined" size="small" />
      </div>
      <div className="header-field">
        <TextField name="question" value={formData.question} label="Question" fullWidth
          required onChange={handleChange} variant="outlined" size="small">
        </TextField>
      </div>
      <div className="header-field">
        <TextField name="answer" value={formData.answer} label="Answer" fullWidth
          required onChange={handleChange} variant="outlined" size="small" />
      </div>
      <div className="header-field">
        <TextField name="description" value={formData.description} label="Description" fullWidth rows="3" multiline="true"
          required onChange={handleChange} variant="outlined" size="small">
        </TextField>
      </div>
      <div className="header-field">
        <Button variant="contained" color="secondary" onClick={onSubmit}> Submit </Button>
      </div>
    </div>
  }

  const getQuestionList = () => {
    return <div className="riddle-list">
      <div className="riddle-answers-details">
        {riddleList.map((riddle, index) => (
          <div className="riddle-answers-row">
            <div className="riddle-answers-label">Riddle: {riddle.riddleDate}
            {userRole === ROLE_ADMIN || riddle.isReviewed === 0 ? <span className="questionListIcon">
                <EditOutlinedIcon className="icon" onClick={() => { onEditQuestion(riddle) }} />
              </span>: null}
            </div>
            <div className="riddle-answers-question">
              {riddle.question === EMPTY_STRING ? null : riddle.question}
            </div>
            <div className="riddle-answers-option-text">
              Answer: {riddle.answer === EMPTY_STRING ? null : riddle.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  }

  const renderMain = () => {
    return <div className="riddle-container">
      {headerFormPanel()}
      <div className='bottom-panel'>
        <div className="riddle-request-title">Riddles </div>
        <div>Is Reviewed <Switch checked={isReviewed} onChange={handleToggle}/>
        <span>
          <FormControl className="header-field">
             {userRole === ROLE_ADMIN && !isReviewed ? <Button variant="contained" color="primary" onClick={onApprove} > Approve All </Button> : null}
          </FormControl>
        </span>
        <span>Total =  {totalCount}</span>
        </div>
        <Grid justify="center" container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {riddleList.length > 0 ? getQuestionList() : null}
          </Grid>
        </Grid>
        <Pagination count={pageCount} page={page} onChange={handlePageChange} variant="outlined" color="secondary" />
      </div>
      <YesNoDialog open={showMessageDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
     </div>
  }

  return (
    <>
      {showLoader ? <Loader /> :
        renderMain()
      }
    </>
  );
}

export default AdminRiddle;
