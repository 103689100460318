import { TextField } from "@material-ui/core";
import firebase from 'firebase/compat/app';
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../app-context";
import { changePassword, createNewPassword } from "../../services/user-service";
import { EMPTY_STRING, MANDATORY_FIELD_MSG } from "../constants/constants";
import { doSignInWithPhoneNumber, doSignOut } from "../firebase/firebase.service";
import Timer from "../games/timer/timer.component";
import phoneNumber from "../phone-number/phone-number";
import PhoneInput from 'react-phone-number-input'
import './login.scss';
import { isValidPhoneNumber } from "react-phone-number-input";

const ChangePasswordComponent = (props) => {

  const initProfile = {
    confirmPassword: EMPTY_STRING, password: EMPTY_STRING, oldPassword: EMPTY_STRING, code: EMPTY_STRING
  };
  const [formError, setFormError] = useState({});
  const [formData, updateFormData] = useState(initProfile);
  const [errorMessage, setErrorMessage] = useState(EMPTY_STRING);
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [isOtpAvailable, setOtpAvailable] = useState(false);
  const [appVerifier, setAppVerifier] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [showTimer, setShowTimer] = useState(false);
  const [sendOtpText, setSendOtpText] = useState('Send OTP');
  const [phone, setPhone] = useState(props.phone);

  useEffect(() => {
    if (appVerifier === null) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recapcha-container', {
        'size': 'invisible',
        'callback': (response) => {
        }
      });
      setAppVerifier(window.recaptchaVerifier);
    }
  }, []);

  const onCreateNewPassword = () => {
    if (handleValidation()) {
      confirmationResult.confirm(formData.code).then((result) => {
        setShowTimer(false);
        const user = result.user['_delegate'];
        const userModel = {
          providerId: user.uid,
          userId: user.phoneNumber,
          password: formData.password,
          provider: result.additionalUserInfo.providerId,
          authToken: user.accessToken
        }
        createNewPassword(userModel).subscribe({
          next: (response) => {
            appContext.setSuccessMessage(response.message);
            props.firePasswordChanged(userModel);
          }});
      }).catch(error => {
        console.log(error.message);
        props.setErrorMessage('Invalid OTP');
      });
    }
  }

  const onChangePassword = () => {

    if (handleValidation()) {
      if (formData.password === formData.confirmPassword) {
        changePassword(formData.oldPassword, formData.password).subscribe({
          next: (response) => {
            appContext.setSuccessMessage(response.message);
            doSignOut();
            history.push('/');
            appContext.setUserData(null);
            localStorage.clear();
          }, error : (error ) => {
            console.log(error.message);
            setErrorMessage(error.message);
          }});
      } else {
        setErrorMessage('Password mismatch');
      }
    }
  }

  const sendOTP = () => {
    setOtpAvailable(false);
    if (handleValidation()) {
      if (formData.password === formData.confirmPassword) {
        setShowTimer(true);
        setSendOtpText('Resend OTP')
        doSignInWithPhoneNumber(phone, appVerifier)
          .then((result) => {
            window.confirmationResult = result;
            setConfirmationResult(result);
            setOtpAvailable(true);
            appContext.setSuccessMessage('Please enter the OTP sent to your mobile number');
          }).catch( error => {
            console.log(error.message);
            setShowTimer(false);
            props.setErrorMessage('Please enter a valid mobile number');
          });
      } else {
        props.setErrorMessage('Password mismatch');
      }
    }
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleValidation = () => {
    if (props.type === 'forgotpassword') {
      props.setErrorMessage(EMPTY_STRING);
    } else {
      setErrorMessage(EMPTY_STRING);
    }
    let errors = {};
    let formIsValid = true;
    if (props.type !== 'forgotpassword' && !formData["oldPassword"]) {
      formIsValid = false;
      errors["oldPassword"] = MANDATORY_FIELD_MSG;
    }

    if (props.type === 'forgotpassword' && !phone) {
      formIsValid = false;
      errors["phoneNumber"] = MANDATORY_FIELD_MSG;
    } else if (props.type === 'forgotpassword' && !isValidPhoneNumber(phone)) {
      formIsValid = false;
      errors["phoneNumber"] = 'Invalid phone number'
    }

    if (!formData["password"]) {
      formIsValid = false;
      errors["password"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["confirmPassword"]) {
      formIsValid = false;
      errors["confirmPassword"] = MANDATORY_FIELD_MSG;
    }
    if (props.type !== 'forgotpassword' && isOtpAvailable && !formData["code"]) {
      formIsValid = false;
      errors["code"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const timeOut = () => {
    setShowTimer(false);
  }

  const cudentsLogin = () => {
    return <form noValidate autoComplete="off">
      <div id="recapcha-container"></div>
      {props.type === 'forgotpassword' ?
        <div className="formField">
          <PhoneInput country="IN" defaultCountry="IN" value={phone || EMPTY_STRING} international countryCallingCodeEditable={false} disabled={isOtpAvailable} 
          required onChange={setPhone} limitMaxLength={10} inputComponent={phoneNumber}
            size="small" />
          <span className="error-text">{formError["phoneNumber"]}</span>
        </div>
        :
        <>
          <div className="login-title">Change Password</div>
          {errorMessage === EMPTY_STRING ? null : <div className="error-msg">{errorMessage}</div>}
          <div className="formField">
            <TextField name="oldPassword" value={formData.oldPassword || EMPTY_STRING} onChange={handleChange} required label="Old Password" type="password" minLength={6} variant="outlined"
              autoComplete='new-password' size="small" fullWidth />
            <span className="error-text">{formError["oldPassword"]}</span></div></>
      }
      <div className="formField">
        <TextField name="password" value={formData.password || EMPTY_STRING} onChange={handleChange}
          autoComplete='new-password' size="small" disabled={isOtpAvailable} required label="Password" type="password" minLength={6} variant="outlined" fullWidth />
        <span className="error-text">{formError["password"]}</span></div>
      <div className="formField">
        <TextField name="confirmPassword" value={formData.confirmPassword || EMPTY_STRING} onChange={handleChange}
          disabled={isOtpAvailable} required label="Confirm Password" type="password" variant="outlined" fullWidth
          autoComplete='new-password' size="small" />
        <span className="error-text">{formError["confirmPassword"]}</span></div>
      {props.type === 'forgotpassword' ? <>  {isOtpAvailable ?
        <div className="formField">
          <TextField name="code" value={formData.code || EMPTY_STRING} onChange={handleChange} required label="OTP" minLength={3} variant="outlined" fullWidth size="small" />
          <span className="error-text">{formError["code"]}</span>
          <div id="customBtn" className="login-submit-button" onClick={onCreateNewPassword}>
            {/* <span className="icon"><img src="/assets/images/login/logo192.png" alt="" /></span> */}
            <span className="buttonText">Submit</span></div>
        </div>
        : null}
        {showTimer ? <div className="otp-timer"><Timer timeOut={timeOut} initialSeconds={59}></Timer></div> : <div id="customBtn" className="login-submit-button" onClick={sendOTP}><span className="buttonText">{sendOtpText}</span></div>}
      </>
        :
        <>
        <div id="customBtn" className="login-submit-button" onClick={onChangePassword}>
          {/* <span className="icon"><img src="/assets/images/login/logo192.png" alt="" /></span> */}
          <span className="buttonText">Submit</span>
        </div>
        </>
      }
    </form>
  }


  return (
    <div className="change-password">
      {cudentsLogin()}
    </div>
  );
}

export default ChangePasswordComponent;

