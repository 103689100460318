import React, { useEffect, useState } from 'react';
import AdComponent from '../../../components/google-ads/google-ads.component';
import Loader from '../../../components/loader/loader.component';
import { getSubjects } from '../../../services/category-service';
import { TopicList } from '../topic-list/topic-list.component';
import './grade.styles.scss';

const Grade = (props) => {

  const grade = props.match.params.grade;
  const [subjects, setSubjects] = useState([]);
  const [showAds, setShowAds] = useState(false);


  useEffect(() => {
    if (subjects.length === 0) {
      loadSubjects();
    }
  }, []);

  const loadSubjects = () => {
    getSubjects(grade, 0).subscribe({
      next: (response) => {
        setSubjects(response.data);
      }});
  }


  const renderGrades = () => {
    return <div className="quizzes-page">
      <div className="subject-list">
        <div className="grade-page-title">Class {grade}          </div>
        {subjects.map(subject => (
          <TopicList grade={grade} subject={subject} isDashboard={1} />
        ))}
      </div>

      {showAds ? <><AdComponent></AdComponent></> : null}
    </div>
  }

  return (
    <>
      {subjects === null ? <Loader /> : <div>{renderGrades()}   </div>}
    </>
  );
}

export default Grade;