import { PAGE_SIZE } from "../components/constants/constants";
import { get, post } from "./http-services";

export function findDailyGK(grade) {
    return get(`/gk/finddailygk?grade=${grade}`);
}

export function findGKByDate(grade, date) {
    return get(`/gk/findgkbydate?grade=${grade}&date=${date}`);
}

export function findPreviousGK(pageNumber) {
    return get(`/gk/findpreviousgk?page=${pageNumber}&size=${PAGE_SIZE}&sort=gkDate,desc`);
}

export function approveGK() {
    return get(`/gk/approveall`);
}

export function saveGK(model) {
    return post(`/gk/save`, model);
}

export function findAllByIsReviewed(isReviewed, pageNumber) {
    return get(`/gk/findallbyisreviewed?isReviewed=${isReviewed}&page=${pageNumber}&size=${PAGE_SIZE}&sort=gkDate,desc`);
}

export function getGKSubjects(name, grade) {
    return get(`/gk/subject?name=${name}&grade=${grade}`);
}

export function getGKTopics(name, grade, subject) {
    return get(`/gk/topic?name=${name}&grade=${grade}&subject=${subject}`);
}