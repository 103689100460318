import Switch from '@material-ui/core/Switch';
import React, { useEffect, useState } from "react";
import { isNotEmpty } from '../../../../services/util-service';
import Timer from "../../timer/timer.component";
import BlindsComponent from '../blinds/blinds.component';
import './window-blinds.styles.scss';
const WindowBlindsComponent = (props) => {

  const [optionsList, setOptionsList] = useState(props.questionDetails.options);
  const [audioExplode, setAudioExplode] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/windowblinds/wrong_answer.mp3'));
  const [audioRight, setAudioRight] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/windowblinds/right_answer.mp3'));
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [timeOver, setTimeOver] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);


  const resetGame = () => {
    let emptyArray = [];
    setShowQuestion(false);
    setCorrectAnswer(false);
    setWrongAnswer(false);
    setTimeOver(false);
    setTimeout(() => {
      setOptionsList(props.questionDetails.options);
      setShowQuestion(true);
    }, 500);
  }

  useEffect(() => {
    resetGame();
  }, [props.questionDetails]);



  const wrongAnswerSelected = () => {
    setWrongAnswer(true);
    if (props.music) {
      audioExplode.play();
    }
  }



  const timeOut = () => {
    setShowQuestion(false);
    setOptionsList(null);
    setShowQuestion(false);
    setTimeOver(true);
    if (props.music) {
      audioExplode.play();
    }
  }

  const correctAnswerSelected = () => {
    setCorrectAnswer(true);
    if (props.music) {
      audioRight.play();
    }
    props.correctAnswer();
  }

  const answerSelected = (ans) => {
    setTimeout(() => {
      setShowQuestion(false);
      setOptionsList(null);
      // Verify the answer
      if (ans.isCorrect === 1) {
        correctAnswerSelected();
      } else {
        wrongAnswerSelected();
      }
    }, 3000);
  }



  const playLevelAgain = () => {
    if (props.currentQuestion === 1) {
      resetGame();
    } else {
      props.playAgain();
    }
  }

  const renderView = () => {
    return (
      <div className="window-blinds-game-box">
        {/* <div className="window-blinds-game-background">
          <img src="https://www.cudents.com/prod/assets/images/games/chickeneggs/bg_chickeneggs.webp" alt="" />
        </div> */}
          <>
            {wrongAnswer ? <div className="answer-out"><img src="https://www.cudents.com/prod/assets/images/games/windowblinds/wa.webp" alt="" /><div><div className="game-button" onClick={playLevelAgain}>Play Again</div></div></div> : null}
            {correctAnswer ?
            <div className="answer-out">
              <img src="https://www.cudents.com/prod/assets/images/games/windowblinds/ca.webp" alt="" />
              {props.nextLevelButton ? <div><div className="game-button" onClick={props.playNextLevel}>Play Level {props.currentLevel + 1}</div></div> : null}
              {props.serverMessage ? <div className="bonus-message">{props.serverMessage}</div> : null}
            </div> : null}
            {timeOver ? <div className="answer-out"><img src="https://www.cudents.com/prod/assets/images/games/windowblinds/timeover.webp" alt="" /><div><div className="game-button" onClick={playLevelAgain}>Play Again</div></div></div> : null}

            {showQuestion ?
              <div className="game-level-number">Level - {props.currentLevel} ({props.currentQuestion}/{props.totalQuestions})</div>
              : null}

            {showQuestion ?
              <div className="window-blinds-container">
                {
                  optionsList.map((option, index) => (
                    <>
                      {isNotEmpty(option) && index < 4 ?
                        <>
                          <div className="window-blinds-option">
                            {option.isCorrect ?
                              <BlindsComponent option={option} answerSelected={answerSelected} blindPic={'painting'}></BlindsComponent>
                              :
                              <BlindsComponent option={option} answerSelected={answerSelected} blindPic={'ghost'}></BlindsComponent>
                            }
                          </div>
                        </>
                        : null}
                    </>
                  ))
                }
              </div>
              : null}
            {showQuestion ?
              <div className="question-alphabets">
                {showQuestion && !(wrongAnswer || correctAnswer || timeOver) ?
                  <>
                    <div className="game-timer-section">
                      <Timer timeOut={timeOut} initialMinute={3} initialSeconds={0}></Timer>
                    </div>
                    <div className="game-question-section">
                      <span className="game-question">{props.questionDetails.questionText}</span>
                    </div>
                  </> : null
                }
              </div> : null}

          </>
      </div>
    );
  }

  return (
    <>
      {renderView()}
    </>


  );
}

export default WindowBlindsComponent;