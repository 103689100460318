import { ALL, GRADE_LIST } from "../constants/constants";

export const MISSING_TREASURE = 'Missing Treasure';
export const SAVE_THE_EARTH = 'Save The Earth';
export const CHICKEN_EGGS = 'Chicken Eggs';
export const DRACULA = 'Dracula';
export const PAIR_UP = 'Pair Up';
export const LOST_AND_FOUND = 'Lost and Found';
export const CORRECT_SEQUENCE = 'Correct Sequence';
export const ASCENDING_ORDER = 'Ascending Order';
export const DESCENDING_ORDER = 'Descending Order';
export const COUNTRY_FLAG = 'Country Flag';
export const MONUMENTS = 'Tourist Spots';
export const WINDOW_BLINDS = 'Missing Painting';
export const MISSING_TREASURE_PATH = 'multiplication';
export const SAVE_THE_EARTH_PATH = 'savetheearth';
export const CHICKEN_EGGS_PATH = 'chickeneggs';
export const DRACULA_PATH = 'dracula';
export const PAIR_UP_PATH = 'pairup';
export const LOST_AND_FOUND_PATH = 'lostandfound';
export const COUNTRY_FLAG_PATH = 'countryflag';
export const MONUMENTS_PATH = 'monuments';
export const WINDOW_BLINDS_PATH = 'windowblinds';
export const GAME_ALL_CLEARED_MSG = 'Congratulations! You have cleared all the levels.';
export const GAME_UNLOCK_MSG = 'Complete previous level to unlock this level.';
export const OTHER = 'Other'
export const GRADES = [{name: 'NONE', value: 0},...GRADE_LIST];

export const GAME_TYPE_SELECT = 'SELECT';
export const GAME_TYPE_INFINITE = 'INFINITE';
export const GAME_TYPE_SEQUENCE = 'SEQUENCE';
export const GAME_TYPE_SEARCH = 'SEARCH';
export const GAME_TYPE_MATCH = 'MATCH';
export const GRADE_TYPE = ['ALL', 'NONE'];
export const INFINITE_GAME_TYPES = ['jump', 'catch', 'fire'];

export const CLASS_TYPE_PRESCHOOL = 'PRESCHOOL';
export const CLASS_TYPE_PRIMARY = 'PRIMARY';
export const CLASS_TYPES = [CLASS_TYPE_PRESCHOOL, CLASS_TYPE_PRIMARY, ALL];
export const GAME_TYPE = [GAME_TYPE_SELECT, GAME_TYPE_INFINITE, GAME_TYPE_SEQUENCE, GAME_TYPE_SEARCH, GAME_TYPE_MATCH];

