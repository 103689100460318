import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import './image-viewer.styles.scss';

export const ImageViewerDialog = (props) => {

  return (
    <Dialog {...props} fullWidth={false}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
      <div className="image-viewer">
        <img src={props.imagePath} />
      </div>
    </Dialog>
  );
}

export default ImageViewerDialog;