import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../app-context';
import { EMPTY_STRING } from "../../../components/constants/constants";
import { GAME_ALL_CLEARED_MSG, GAME_TYPE_SELECT, GAME_UNLOCK_MSG } from "../../../components/games/game-constants";
import Loader from "../../../components/loader/loader.component";
import YesNoDialog from "../../../components/message-dialog/yes-no-dialog.component";
import PortraitLandscape from "../../../components/portrait-to-landscape/portrait-to-landscape";
import { findGameById, getSelectGameLevels, saveGameAttempt } from "../../../services/game-service";
import GameInfo from "../game-info/game-info.component";
import GameStartScreen from "../game-start-screen/game-start-screen.component";
import getGameComponent from "./dynamic-game-selector";
import './game-engine.styles.scss';

function GameEngine(props) {

  const gameId = props.match.params.gameId;
  const grade = props.match.params.grade;
  const subject = props.match.params.subject;
  const topic = props.match.params.topic;

  const history = useHistory();
  const appContext = useContext(AppContext);
  const [showLoader, setShowLoader] = useState(true);
  const [showGameLoader, setShowGameLoader] = useState(true);
  const [startGame, setStartGame] = useState(false);
  const [game, setGame] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [levelData, setLevelData] = useState(null);
  const [musicFlag, setMusicFlag] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [serverMessage, setServerMessage] = useState(null);
  const [nextLevelButton, setNextLevelButton] = useState(false);
  const [audioRight, setAudioRight] = useState(null);
  const [audioAllLevels, setAudioAllLevels] = useState(null);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [showMsgDialog, setShowMsgDialog] = useState(false);

  useEffect(() => {
    if (game === null) {
      loadGame();
    }
  }, []);

  const loadGame = () => {
    findGameById(gameId).subscribe({
      next: (response) => {
        setGame(response.data);
        setAudioRight(new Audio(`https://www.cudents.com/prod/assets/images/games/${response.data.gameFolderName}/right_answer.mp3`));
        setAudioAllLevels(new Audio(`https://www.cudents.com/prod/assets/images/games/${response.data.gameFolderName}/all_levels.mp3`));
        setShowGameLoader(false);
      }, error: (error) => {
        history.goBack();
        appContext.setDialogMessage(error.message);
      }
    });
  }
  // Hide the header and footer to make game full screen
  useEffect(() => {
    appContext.setShowHeader(false);
    appContext.setShowFooter(false);

    // Fetch the data
    if (game !== null && levelData === null) {
      loadGameLevels();
    }
    return () => {
      appContext.setShowHeader(true);
      appContext.setShowFooter(true);
    }
  }, [game]);


  const updateQuestionPosition = (levelData) => {
    for (let i = 0; i < levelData.length; i++) {
      if (levelData[i].isLocked === 1) {
        setCurrentLevel(i);
        setCurrentQuestion(1);
        break;
      } else if (i === levelData.length - 1) {
        setCurrentLevel(i + 1);
        setCurrentQuestion(1);
        break;
      }
    }
    setShowLoader(false);
  }

  // Function to fetch the data for the game
  const loadGameLevels = () => {
    getSelectGameLevels(game.name, grade, subject, topic).subscribe({
      next: (response) => {
        setLevelData(response.data);
        updateQuestionPosition(response.data);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }


  // Function to switch on and off the music
  const toggleMusic = () => {
    setMusicFlag(!musicFlag);
  };


  // Function to start the game
  const gameStart = () => {
    setStartGame(true);
  };


  // When answer is correct check if the level is cleared, if level completed then send an update to server.
  const correctAnswer = () => {
    playMusic();
    if (currentQuestion === levelData[currentLevel - 1].questions.length) {
      if (levelData[currentLevel - 1].isAttempted === 0) {
        const attemptInputModel = {
          gameId: game.rowId, name: game.name, grade: grade, subject: subject, topic: topic, level: currentLevel
        }
        saveGameAttempt(attemptInputModel).subscribe({
          next: (response) => {
            setServerMessage(response.data);
            setShowLoader(false);
            if (GAME_TYPE_SELECT !== game.type) {
              appContext.setDialogMessage(response.data);
            }
            if (levelData.length !== currentLevel) {
              let temp = [...levelData];
              temp[currentLevel].isLocked = 0;
              temp[currentLevel - 1].isAttempted = 1;
              setLevelData(temp);
              setNextLevelButton(true);
            }
          }, error: (error) => {
            setShowLoader(false);
            console.log(error.message);
          }
        });
      } else {
        if (levelData.length === currentLevel) {
          setServerMessage(GAME_ALL_CLEARED_MSG);
          if (GAME_TYPE_SELECT !== game.type) {
            appContext.setDialogMessage(GAME_ALL_CLEARED_MSG);
          }
        } else {
          setNextLevelButton(true);
        }
      }
    } else {
      setTimeout(() => {
        setCurrentQuestion(currentQuestion + 1);
      }, 5000);
    }
  }


  // If user clicks play next level button
  const playNextLevel = () => {
    // const msg = validUserForGame(appContext.userData, currentLevel, levelData[currentLevel].isAttempted === 1);
    // if (msg !== EMPTY_STRING) {
    //   appContext.setDialogMessage(msg);
    //   setServerMessage(null);
    // } else {
    selectLevel(currentLevel);
    setServerMessage(null);
    // }
  }

  // If user manually selects a level from the game info page
  const onLevelSelect = (level, index) => {
    if (level.isLocked === 1) {
      appContext.setDialogMessage(GAME_UNLOCK_MSG);
    } else {
      // const msg = validUserForGame(appContext.userData, index, levelData[index].isAttempted === 1);
      // if (msg !== EMPTY_STRING) {
      //   appContext.setDialogMessage(msg);
      //   setStartGame(false);
      // } else {
      selectLevel(index);
      setStartGame(true);
      setShowInfo(false);
      // }
    }
  }

  // To set the current playing level
  const selectLevel = (level) => {
    setNextLevelButton(false);
    setCurrentLevel(level + 1);
    setCurrentQuestion(1);
  }

  const hideInfoBox = () => {
    setShowInfo(false);
  }

  // To start the game
  const beginGame = () => {
    // const msg = validUserForGame(appContext.userData, currentLevel - 1, levelData[currentLevel - 1].isAttempted === 1);
    // if (msg !== EMPTY_STRING) {
    //   setCurrentLevel(1);
    // }
    // if (SUBSCRIPTION_END_MSG === msg) {
    //   appContext.setDialogMessage(msg);
    // } else {
    setStartGame(true);
    // }
  }

  // Play music when right answer given
  const playMusic = () => {
    if (musicFlag) {
      if (levelData.length === currentLevel) {
        audioAllLevels.play();
      } else {
        audioRight.play();
      }
    }
  }

  // Reset the question back to 1 if level not cleared
  const playAgain = () => {
    let temp = 1;
    setCurrentQuestion(temp);
  }

  // Exit game
  const exitGame = () => {
    setMessage('Do you want to exit the Game?');
    setShowMsgDialog(true);
  }

  const onOkClick = () => {
    history.goBack();
    window.screen.orientation.lock('portrait');
  }


  const getImagePath = (type) => {
    let path = EMPTY_STRING;
    switch (type) {
      case 'bgImage':
        path = `https://www.cudents.com/prod/assets/images/games/${game.gameFolderName}/game_bg.webp`;
        break;
      case 'startImage':
        path = `https://www.cudents.com/prod/assets/images/games/${game.gameFolderName}/game_start.webp`;
        break;
      case 'infoImage':
        path = `https://www.cudents.com/prod/assets/images/games/${game.gameFolderName}/game_info.webp`;
        break;
      default:
        break;
    }
    return path;
  }
  const getProps = () => {
    return {
      name: game.name,
      type: game.type,
      questionDetails: levelData[currentLevel - 1].questions[currentQuestion - 1],
      correctAnswer: correctAnswer,
      gameStart: startGame,
      beginGame: beginGame,
      nextLevelButton: nextLevelButton,
      playNextLevel: playNextLevel,
      playAgain: playAgain,
      currentQuestion: currentQuestion,
      currentLevel: currentLevel,
      totalQuestions: levelData[currentLevel - 1].questions.length,
      music: musicFlag,
      toggleMusic: toggleMusic,
      serverMessage: serverMessage,
      gameFolderName: game.gameFolderName
    };
  }

  return (
    <>{showGameLoader ? <div className="showLoading"><Loader /></div> :

      <div className="game-engine">
        {/* Show the loader till data is loading */}
        {/* {showLoader ? <div className="game-engine-loader-container">
          <Lottie className="game-engine-loader" animationData={gameYogaLoader} loop={true} />
        </div> : <></>
        } */}

        {/* If data loaded but game not started, show game start screen else show play area */}
        {startGame ?
          <div>
            {currentQuestion ?
              <>
                {getGameComponent(getProps())}
                <div className="game-engine-game-info-button" onClick={() => { setShowInfo(true) }}><img src="https://www.cudents.com/prod/assets/images/games/game_info.webp" alt="" /></div>
              </>
              : null}
          </div> :
          <div><GameStartScreen gameBackgroundImage={getImagePath('bgImage')}
            gameStory={game.story}
            gameImage={getImagePath('startImage')}
            toggleMusic={toggleMusic} musicFlag={musicFlag} gameStart={gameStart}></GameStartScreen></div>
        }

        {showInfo ? <><GameInfo levelData={levelData} onLevelSelect={onLevelSelect} hideInfoBox={hideInfoBox} exitGame={exitGame}></GameInfo></> : null}
      </div>
    }
      {/* Ask user to switch to landscape mode in mobile phone to play the game */}
      <div className="game-engine-switch-to-landscape">
        <PortraitLandscape></PortraitLandscape>
        <YesNoDialog open={showMsgDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} isHorizontal={true} />
      </div>
    </>
  );
}

export default GameEngine;