
import { Pagination } from "@material-ui/lab";
import Lottie from "lottie-react";
import moment from 'moment';
import { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../../app-context";
import Loader from "../../../components/loader/loader.component";
import { findPreviousGK } from "../../../services/gk-service";
import brainbulb from "../animations/brainbulb.json";
import './daily-gk-listing.styles.scss';
function DailyGkListing(props) {

  const history = useHistory();
  const grade = props.match.params.grade;
  const [previousGKList, setPreviousGKList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const appContext = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
      loadGK();
  }, [page]);

  const loadGK = () => {
    findPreviousGK(page-1).subscribe({
      next: (response) => {
        setPreviousGKList(response.data.content);
        setPageCount(response.data.totalPages);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
        history.goBack();
      }});
  }
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const navigateTo = (dailygk) => {
    // navigate to dailygk page with dailygk id
    history.push(`/dailygk/${grade}/${dailygk.gkDate}`);
  }

  return (<>{showLoader ? <div className="showLoading"><Loader /></div>
    :
    <div className="dailygk-listing-page">
      <div className="dailygk-history-button">
          <span onClick={() => { history.push(`/dailygk`); }}>Go to Daily GK</span>
        </div>

      <div className="top-animation">
        <Lottie className="dailygk-page-top-animation" animationData={brainbulb} loop={true} />
      </div>
      <div className="dailygk-listing">
        {
          previousGKList.map((dailygk, idx) => (
            <div key={idx} className="dailygk-card" onClick={() => navigateTo(dailygk)}>
            <div className="dailygk-header">
            {moment(dailygk.gkDate).format('MMM yyyy')}
            </div>
              <div className="dailygk-body">
              {moment(dailygk.gkDate).format('DD')}
              </div>
              <div className="dailygk-footer">
              {dailygk.topic}
              </div>
            </div>
          ))
        }
      </div> 
      <div className="gk-listing-pagination"><Pagination count={pageCount} page={page} onChange={handlePageChange} variant="outlined" color="secondary" /></div>
    </div>
  } </>
  );
}

export default DailyGkListing;