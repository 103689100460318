import Lottie from "lottie-react";
import React, { useContext, useEffect, useState } from "react";
import portraitToLandscape from "./animations/portrait_to_landscape.json";
import './portrait-to-landscape.scss';

function PortraitLandscape() {
    return (
        <>
            <Lottie className="portrait-to-landscape-animation" animationData={portraitToLandscape} loop={true} />
            <div>Kindly switch to landscape mode to play the game.</div>
        </>
    );
}

export default PortraitLandscape;