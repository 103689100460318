import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import './guideline-dialog.styles.scss';

export const GuidelineDialog = (props) => {

  const getButtons = () => {
    if (props.level.isAttempted === 1) {
      return (
        <div className="guidelineButton">
          <Button variant="contained" onClick={props.gotoscorecard} color="primary">Scorecard</Button>
          <Button variant="contained" onClick={props.gotoquiz} color="secondary">Retake</Button>
        </div>
      );
    } else {
      return (
        <div className="guidelineButton">
          <Button variant="contained" onClick={props.gotoquiz} color="primary">Start</Button>
        </div>
      );
    }
  }


  const getGuidelines = () => {
    if (props.level.isAttempted === 1) {
      return (
        <div className="guidelineMessage">
          <ol>
            <li>The quiz contains 15 questions and there is no time limit for the same.</li>
            <li>Each question carries 1 mark and there is no negative marking.</li>
            <li>No points will be added to your account for retaking the quiz.</li>
            <li>Scores of the retake attempts will not be stored or considered for the leaderboard.</li>
          </ol>
        </div>
      );
    } else {
      return (
        <div className="guidelineMessage">
          <ol>
            <li>The quiz contains 15 questions and there is no time limit for the same.</li>
            <li>Each question carries 1 mark and there is no negative marking.</li>
            <li>Once the quiz is submitted, the points will be added to your account.</li>
            <li>You will earn 1 point if score is less than 60%, 2 points if score is 60% or above and 5 points if score is 100%</li>
            <li>You can attempt the quiz multiple times, but the score of the first attempt will be considered for the leaderboard and points calculation.</li>
          </ol>
        </div>
      );
    }
  }

  return (
    <Dialog {...props}>
      <div className="guidelineDialogContainer">
        <img src="/assets/images/quiz/guideline.png" alt="" />
        {getGuidelines()}
        {getButtons()}
      </div>
    </Dialog>
  );
}

export default GuidelineDialog;