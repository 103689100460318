import React from 'react'
import { useState, useEffect } from 'react';
import './blinds.styles.scss';

const BlindsComponent = (props) => {
  const [showBlinds, setShowBlinds] = useState('private');
  const [showImage, setShowImage] = useState(false);

  const optionClicked = (option) => {
    setShowImage(true);
    setShowBlinds('public');
    props.answerSelected(option);
  }


  return (
    <div class={"illustration-pro " + showBlinds}>
      <div class="blinds">
        <div class="blinds-top"></div>
        <ul class="blinds-rows">
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
          <li class="blinds-row"></li>
        </ul>
      </div>
      <div class="code">
        {showImage ?
          <>
            {props.blindPic === 'ghost' ?
              <img src="https://www.cudents.com/prod/assets/images/games/windowblinds/ghost.webp" alt="" />
              :
              <img src="https://www.cudents.com/prod/assets/images/games/windowblinds/painting.webp" alt="" />
            } </>
          : null}
      </div>
      <span class="toggle" onClick={() => { optionClicked(props.option) }}>
        {props.option.text}
      </span>
    </div>
  )
}

export default BlindsComponent;