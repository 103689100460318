import React, { useState } from 'react';
import "./class-selection.styles.scss";
function ClassSelection() {


  const [activeItem, setActiveItem] = useState('home');

  const itemSelected = (selItem) => {
    setActiveItem(selItem);
  }

  return (
    <div className="class-selection-container">
      <div className="class-selection-title">
        Select Class
      </div>
      <div className="class-selection-list">
        <div className={activeItem == "nursery" ? "class-selection-item class-selection-item-active" : "class-selection-item"} onClick={() => { itemSelected('nursery') }}>
          Nursery
        </div>
        <div className={activeItem == "kg1" ? "class-selection-item class-selection-item-active" : "class-selection-item"} onClick={() => { itemSelected('kg1') }}>
          KG-1
        </div>
        <div className={activeItem == "kg2" ? "class-selection-item class-selection-item-active" : "class-selection-item"} onClick={() => { itemSelected('kg2') }}>
          KG-2
        </div>
        <div className={activeItem == "class1" ? "class-selection-item class-selection-item-active" : "class-selection-item"} onClick={() => { itemSelected('class1') }}>
          Class 1
        </div>
        <div className={activeItem == "class2" ? "class-selection-item class-selection-item-active" : "class-selection-item"} onClick={() => { itemSelected('class2') }}>
          Class 2
        </div>
        <div className={activeItem == "class3" ? "class-selection-item class-selection-item-active" : "class-selection-item"} onClick={() => { itemSelected('class3') }}>
          Class 3
        </div>
        <div className={activeItem == "class4" ? "class-selection-item class-selection-item-active" : "class-selection-item"} onClick={() => { itemSelected('class4') }}>
          Class 4
        </div>
        <div className={activeItem == "class5" ? "class-selection-item class-selection-item-active" : "class-selection-item"} onClick={() => { itemSelected('class5') }}>
          Class 5
        </div>
      </div>
      <div className="class-selection-save-button">
        <span>Save</span>
      </div>
    </div>
  );
}

export default ClassSelection;